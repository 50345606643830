export const GTMPageView = (url: string) => {
    interface PageEventProps {
        event: string;
        page: string;
    }
    type WindowWithDataLayer = Window & {
        dataLayer: Array<Record<string, unknown>>;
    };

    const pageEvent: PageEventProps = {
        event: 'pageview',
        page: url,
    };

    (window as unknown as WindowWithDataLayer).dataLayer.push(pageEvent as unknown as Record<string, unknown>);

    return pageEvent;
};
