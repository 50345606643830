/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { type Router } from 'next/router';
import { useEffect, useState } from 'react';

type Previous = {
    previousRoute: Router['asPath'] | null;
    previousQuery: Router['query'] | null;
    previousPathname: Router['pathname'] | null;
};
export type RouteHistoryType = Previous & {
    history: string[];
    canGoBack: boolean;
};
const useRouteHistory = (router: Router) => {
    const [previous, setPrevious] = useState<Previous>({
        previousRoute: null,
        previousQuery: null,
        previousPathname: null,
    });

    const [history, setHistory] = useState<string[]>([]);

    useEffect(() => {
        const handleBeforeHistoryChange = (url: string, { shallow }: { shallow?: boolean }) => {
            const [nextUrl] = url.split('?');
            if (!shallow && url.length && nextUrl !== router.asPath) {
                setPrevious({
                    previousRoute: router.asPath,
                    previousQuery: router.query,
                    previousPathname: router.pathname,
                });

                setHistory((prevState) => [...prevState, url]);
            }
        };
        if (process.env.NODE_ENV !== 'test') {
            // because router is undefined in tests
            router.beforePopState(() => {
                setHistory((prevState) => prevState.slice(0, -2));
                return true;
            });
        }

        router.events?.on('beforeHistoryChange', handleBeforeHistoryChange);
        return () => {
            router.events?.off('beforeHistoryChange', handleBeforeHistoryChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events]);

    return {
        ...previous,
        history,
        canGoBack: history.length >= 1,
    };
};

export default useRouteHistory;
