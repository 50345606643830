import type { PropsWithChildren } from 'react';
import React, { createContext, useState, useEffect, useMemo } from 'react';

import { newGeoServiceKey, showPagesJaunesOwnershipSectionKey, woopenRemoteConfig } from 'src/utils/firebase';

export interface IRemoteConfigContext {
    hasFetchedAndActivated: boolean;
    useNewGeoService: boolean;
    showPagesJaunesOwnershipSection: boolean;
}
export const RemoteConfigContext = createContext<IRemoteConfigContext>({} as IRemoteConfigContext);

const RemoteConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [hasFetchedAndActivated, setHasFetchedAndActivated] = useState(false);

    useEffect(() => {
        woopenRemoteConfig
            .fetchAndActivate()
            .then((result) => {
                setHasFetchedAndActivated(result);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const value = useMemo(
        () => ({
            hasFetchedAndActivated,
            useNewGeoService: woopenRemoteConfig.getBoolean(newGeoServiceKey),
            showPagesJaunesOwnershipSection: woopenRemoteConfig.getBoolean(showPagesJaunesOwnershipSectionKey),
        }),
        [hasFetchedAndActivated],
    );

    return <RemoteConfigContext.Provider value={value}>{children}</RemoteConfigContext.Provider>;
};

export default RemoteConfigProvider;
