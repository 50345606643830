/* eslint-disable camelcase */
import type { CommunityOrderByKey } from 'src/hooks/useCommunityFilters';
import type { Company, PagesJaunesPro, User } from 'src/types/__generated__/graphql';

export enum AnalyticsEvent {
    RemoveFromFavorites = 'remove_from_favorites',
    CreatePost = 'create_post',
    AcceptRelation = 'accept_relation',
    RefuseRelation = 'refuse_relation',
    AddRelation = 'add_relation',
    ClickService = 'click_service',
    SignUpConfirmed = 'sign_up_confirmed',
    ClickNews = 'click_news',
    ClickEstateSearch = 'click_estate_search',
    NewAdConfirmation = 'new_ad_confirmation',
    ClickCommunity = 'click_community',
    ClickBookmark = 'click_bookmark',
    ClickNotification = 'click_notification',
    ClickSignUp = 'click_sign_up',
    SignedUp = 'signed_up',
    CommunityShowEmail = 'communaute_voir_courriel',
    ShareViaCopyLink = 'partager_via_copier_lien',
    ShareViaEmail = 'partager_via_email',
    ShareViaWhatsApp = 'partager_via_whatsapp',
    ShareViaLinkedIn = 'partager_via_linkedin',
    ShareViaPrivateMessage = 'partager_via_message_prive',
    ShareViaPrivateMessageConfirmation = 'partager_via_message_prive_confirmation',
    DisplayList = 'afficher_liste',
    DisplayMap = 'afficher_carte',
    DetailsFromMap = 'carte_afficher_details',
    SaveAdSearch = 'enregistrer_recherche',
    SaveAdSearchConfirmation = 'enregistrer_recherche_confirmation',
    DisplaySavedSearches = 'voir_recherche_enregistree',
    SavedSearchSelected = 'recherche_enregistree_selectionnee',
    MoreFilters = 'plus_filtres',
    SortRecent = 'trier_plus_recent',
    SortPriceAscending = 'trier_prix_croissant',
    SortPriceDescending = 'trier_prix_decroissant',
    SeePicture = 'voir_photo',
    SeePictures = 'regarder_photos',
    LoanSimulation = 'calculette_emprunt',
    Recommend = 'recommander',
    SeeRecommendations = 'voir_recommandations',
    Follow = 'abonner',
    Unfollow = 'desabonner',
    Report = 'signaler',
    RequestAdminAccess = 'devenir_administrateur',
    LeaveTeam = 'quitter_equipe',
    ClickChat = 'discuter',
    Team = 'equipe',
    Info = 'infos',
    Home = 'accueil',
    Showcase = 'vitrine',
    Activity = 'activites',
    Jobs = 'offres_emploi',
    SeeEmail = 'voir_courriel',
    ActivityGalleries = 'activite_galleries',
    ActivityPublications = 'activite_publications',
    Rating = 'avis',
    Block = 'bloquer',
    NewSearch = 'nouvelle_recherche',
    SortName = 'trier_nom',
    SortRelation = 'trier_relation',
    SortRating = 'trier_note',
    SortDistance = 'trier_distance',
    SortRecommendation = 'trier_recommendation',
    NextPage = 'page_suivant',
    FilterDistance = 'filtre_distance',
    FilterProfile = 'filtre_profil',
    See = 'regarder',
    Subscribe = 'abonner',
    Unsubscribe = 'desabonner',
    ViewRecommendations = 'voir_recommendations',
    RecommendConfirmation = 'recommander_confirmation',
    ViewRelations = 'voir_relations',
    Invite = 'inviter',
    ActivityAds = 'activite_annonces',
    ShareVia = 'partager_via',
    SeePhone = 'voir_telephone',
    SendMessage = 'envoyer_message',
    SearchAds = 'rechercher_un_bien',
    SearchPros = 'trouver_des_pros',
    EstimateAd = 'estimer_un_bien',
    CreateAd = 'publier_une_annonce',
    GoogleStore = 'google_store',
    AppleStore = 'apple_store',
    ChangeLanguage = 'changer_langue',
    ContactUs = 'contactez_nous',
    DownloadApp = 'telecharger_application',
    GeneralConditions = 'conditions_generales',
    CookiesPolicy = 'politiques_cookies',
    PrivacyPolicy = 'politiques_confidentialite',
    LegalNotice = 'mentions_legales',
    Create = 'creer',
    CreateConfirmation = 'creer_confirmation',
    AddToFavorites = 'ajouter_favoris',
    AddToFavoritesConfirmation = 'ajouter_favoris_confirmation',
    Like = 'like',
    Unlike = 'unlike',
    AddComment = 'ajouter_commentaire',
    AboutDescription = 'a_propos_description',
    AboutCompany = 'a_propos_entreprise',
    HelpClickPhone = 'aide_cliquer_telephone',
    HelpClickEmail = 'aide_cliquer_courriel',
    SeeAd = 'voir_annonce',
    EditAd = 'modifier_annonce',
    WasRented = 'bien_a_ete_loue',
    WasSold = 'bien_a_ete_vendu',
    SeeProject = 'voir_projet',
    CreateProject = 'creer_nouveau_projet',
    SaveProject = 'enregistrer_nouveau_projet',
    ProfileSubscribePopularInterest = 'abonner_centre_dinterets_populaire',
    ProfileUnsubscribePopularInterest = 'desabonner_centre_dinterets_populaire',
    ProfileSubscribeInterest = 'abonner_centre_dinterets',
    ProfileUnsubscribeInterest = 'desabonner_centre_dinterets',
    ProfileSubmitInterests = 'soumettre_centre_dinterets',
    Recommendations = 'recommandations',
    AskRecommendation = 'demander_recommandation',
    AskRecommendationConfirmation = 'demander_recommandation_confirmation',
    RatingSeeUser = 'avis_voir_utilisateur',
    EstimateStep1 = 'etape_1_envoi',
    EstimateStep2 = 'etape_2_envoi',
    EstimateStep3 = 'etape_3_envoi',
    EstimateStep4 = 'etape_4_envoi',
    EstimateStep5 = 'etape_5_envoi',
    EstimateStep6 = 'etape_6_envoi',
    EstimateStep7 = 'etape_7_voir_mon_estimation',
    VisitorEstimateStep1 = 'visiteur_etape_1_envoi',
    VisitorEstimateStep2 = 'visiteur_etape_2_envoi',
    VisitorEstimateStep3 = 'visiteur_etape_3_envoi',
    VisitorEstimateStep4 = 'visiteur_etape_4_envoi',
    VisitorEstimateStep5 = 'visiteur_etape_5_envoi',
    VisitorEstimateStep6 = 'visiteur_etape_6_envoi',
    VisitorEstimateStep7 = 'visiteur_etape_7_envoi',
    CreateAdViaEstimate = 'creer_annonce_via_bien',
    SeeUser = 'voir_utilisateur',
    AddAdmin = 'ajouter_administrateur',
    AddAdminConfirmation = 'ajouter_administrateur_confirmation',
    AddDirector = 'ajouter_directeur',
    RemoveMember = 'retirer_collaborateur',
    RemoveDirector = 'retirer_directeur',
    SeeAllDirectors = 'directeur_voir_tout',
    SeeAllMembers = 'collaborator_voir_tout',
    SeeAllAdmins = 'administrateur_voir_tout',
    SeeAllInvitations = 'invitations_voir_tout',
    SeeAllRequests = 'demandes_voir_tout',
    RejectRequest = 'rejeter_requete',
    RemoveInvitation = 'retirer_invitation',
    EditContact = 'modifier_contact',
    EditContactConfirmation = 'modifier_contact_confirmation',
    Parameters = 'parametres',
    ParametersConfirmation = 'parametres_confirmation',
    Available = 'disponibles',
    Ended = 'terminees',
    Archived = 'archivees',
    EditCoverPicture = 'modifier_photo_couverture',
    EditProfilePicture = 'modifier_photo_profil',
    AddPicture = 'ajouter_photo',
    DeletePicture = 'supprimer_photo',
    AddPresentationVideo = 'ajouter_video_presentation',
    ImportLegalDocument = 'importer_document_legal',
    EditSiret = 'modifier_siret',
    EditSiretConfirmation = 'modifier_siret_confirmation',
    Update = 'mettre_a_jour',
    DeleteJobOffer = 'supprimer_offre_emploi',
    DeleteJobOfferConfirmation = 'supprimer_offre_emploi_confirmation',
    HideJobOffer = 'masquer_offre_emploi',
    UnhideJobOffer = 'demasquer_offre_emploi',
    UpdatePassword = 'changer_mot_de_passe',
    DeactivateAccount = 'desactiver_mon_compte',
    Password = 'mot_de_passe',
    SecurityNotifications = 'notifications',
    Emails = 'adresses_couriel',
    AddEmail = 'ajouter_courriel',
    ResendMailConfirmation = 'renvoyer_courriel_confirmation',
    DeleteEmail = 'supprimer_courriel',
    ChoosePartner = 'choisir_partenaire',
    SendRequest = 'envoyer_demande',
    ActivateAdSearchEmailNotifications = 'notifications_recherches_courriel_activer',
    DeactivateAdSearchEmailNotifications = 'notifications_recherches_courriel_desactiver',
    DeleteSynchro = 'supprimer_synchronisation',
    SkipCrmSynchro = 'passer_crm_synchro',
    Search = 'rechercher',
    Collaborators = 'collaborateurs',
    Directors = 'directeurs',
    RequestEvaluation = 'demander_evaluation',
    ClickOnIlekPartnerLink = 'click_simuler_offre_ilek',
    SeeCategory = 'voir_categorie',
    SearchActivity = 'rechercher_activite',
    SearchCategory = 'rechercher_categorie',
}

export enum TypeOfContent {
    Ad = 'ad',
    Post = 'post',
    Comment = 'comment',
}

export enum EventParamKeys {
    ScreenName = 'screen_name',
    PageTitle = 'page_title',
    Label = 'label',
    ItemId = 'item_id',
    ItemName = 'item_name',
    Method = 'method',
    Section = 'section',
    PropertyType = 'property_type',
    ProjectType = 'project_type',
    UserType = 'user_type',
}

export enum ScreenNames {
    Ad = 'Ad',
    AdId = 'Adid',
    Home = 'Home',
    News = 'News',
    NewsPost = 'News-post',
    Community_Category = 'Community_Category',
    Contacts = 'Contacts',
    ProfileLegal = 'Profile_Legal',
    ProfilePosts = 'Profile_Posts',
    ProfileHelp = 'Profile_Help',
    ProfileAds = 'Profile_Ads',
    ProfileSimulations = 'Profile_Simulations',
    ProfileStatistics = 'Profile_Statistics',
    ProfileProjects = 'Profile_Projects',
    ProfileInterests = 'Profile_Interests',
    ProfileRatings = 'Profile_Ratings',
    ProfileEstimate = 'Profile_Estimate',
    ProfileSecurity = 'Profile_Security',
    ProfileCompanyMembers = 'Profile_Company_Members',
    ProfileCompanyMembersAdmins = 'Profile_Company_Members_Admins',
    ProfileCompanyMembersTeam = 'Profile_Company_Members_Team',
    ProfileCompanyMembersSentInvitations = 'Profile_Company_Members_Sent_Invitations',
    ProfileCompanyMembersReceivedInvitations = 'Profile_Company_Members_Received_Invitations',
    ProfileCompanyFavorites = 'Profile_Company_Favorites',
    ProfileCompanyShowcase = 'Profile_Company_Showcase',
    ProfileCompanyInfo = 'Profile_Company_Infos',
    ProfileCompanyJobs = 'Profile_Company_Jobs',
    ProfileCompanyCrmSync = 'Profile_Company_CRM_Sync',
    PartialEstimate = 'Partial_Estimate',
}

export enum PageTitles {
    Ads = 'Trouver un bien',
    Ad = 'Annonce immobilière',
    Community = 'Trouver des professionnels',
    Woopen = 'Woopen',
    News = 'Actualités',
    NewsPost = 'Article',
    Profile = 'Profil de',
    Contacts = 'Messagerie',
    TermsAndMentions = 'Règles & Mentions',
    ProfilePostsAndStories = 'Publications & Stories',
    ProfileHelp = "Besoin d'aide",
    ProfileAds = 'Annonces immobilières',
    ProfileSimulations = 'Simulations',
    ProfileStatistics = 'Statistiques',
    ProfileProjects = 'Projets réalisés',
    ProfileInterests = "Centres d'intérêt",
    ProfileRatings = 'Avis & Recommandations',
    PartialEstimate1 = 'Estimer un bien - Étape 1',
    PartialEstimate2 = 'Estimer un bien - Étape 2',
    PartialEstimate3 = 'Estimer un bien - Étape 3',
    PartialEstimate4 = 'Estimer un bien - Étape 4',
    PartialEstimate5 = 'Estimer un bien - Étape 5',
    PartialEstimate6 = 'Estimer un bien - Étape 6',
    PartialEstimate7 = 'Estimer un bien - Étape 7',
    ProfileEstimate = 'Estimer un bien - Résultat',
    ProfileEstimate1 = 'Estimer un bien - Étape 1',
    ProfileEstimate2 = 'Estimer un bien - Étape 2',
    ProfileEstimate3 = 'Estimer un bien - Étape 3',
    ProfileEstimate4 = 'Estimer un bien - Étape 4',
    ProfileEstimate5 = 'Estimer un bien - Étape 5',
    ProfileEstimate6 = 'Estimer un bien - Étape 6',
    ProfileEstimate7 = 'Estimer un bien - Étape 7',
    ProfileSecurity = 'Sécurité & Notifications',
    ProfileCompanyMembers = "Membres de l'entreprise",
    ProfileCompanyFavorites = 'Mes favoris',
    ProfileCompanyShowcase = 'Vitrine',
    ProfileCompanyInfo = 'Gestion info entreprise',
    ProfileCompanyJobs = "Modifier l'offre d'emploi",
    ProfileCompanyCrmSync = 'CRM Sync',
}

export enum Sections {
    Ad = 'Immobilier',
    Community = 'Community',
    Actuality = 'Actuality',
    Contacts = 'Contacts',
    Profile = 'Profile',
    Estimate = 'Estimate',
}

export enum Methods {
    Email = 'email',
    WhatsApp = 'whatsapp',
    LinkedIn = 'linkedin',
    Message = 'message',
}

export const enum ItemNames {
    Google = 'Google',
    Apple = 'Apple',
}

export interface LogEventDynamicParamKeys {
    [EventParamKeys.ItemId]?: string;
    [EventParamKeys.ItemName]?: string;
    [EventParamKeys.PropertyType]?: string;
    [EventParamKeys.ProjectType]?: string;
    [EventParamKeys.Method]?: string;
    [EventParamKeys.Label]?: string;
    eventNamePrefix?: string;
}

export const communityOrderByKeysMappedToAnalyticsEvents: Record<CommunityOrderByKey, AnalyticsEvent> = {
    mutualContacts: AnalyticsEvent.SortRelation,
    name: AnalyticsEvent.SortName,
    averageRating: AnalyticsEvent.SortRating,
    recommendation: AnalyticsEvent.SortRecommendation,
    distanceCity: AnalyticsEvent.SortDistance,
};

export const ContextFreeEventPrefixEnums = {
    POST: 'post',
    POST_COMMENT: 'post_commentaire',
    STORIE: 'storie',
    SUGGESTION: 'suggestion',
};

const pathnameAnalyticsMapping: Record<string, Partial<Record<EventParamKeys, string>> & { eventNamePrefix?: string }> =
    {
        '/home': {
            [EventParamKeys.ScreenName]: ScreenNames.Home,
            [EventParamKeys.PageTitle]: PageTitles.Woopen,
            eventNamePrefix: 'accueil',
        },
        '/user/[userId]': {
            [EventParamKeys.ScreenName]: 'User',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_profil',
        },
        '/company/[companyId]': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company/[companyId]/info': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company/[companyId]/team': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company/[companyId]/showcase': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company/[companyId]/activity': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company/[companyId]/jobs': {
            [EventParamKeys.ScreenName]: 'Company',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprise',
        },
        '/company-pagesjaunes/[companyPagesJaunesId]': {
            [EventParamKeys.ScreenName]: 'Company-pagesjaunes',
            [EventParamKeys.PageTitle]: 'Profil de',
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute_entreprisepj',
        },
        '/ad': {
            [EventParamKeys.ScreenName]: ScreenNames.Ad,
            [EventParamKeys.PageTitle]: PageTitles.Ads,
            [EventParamKeys.Section]: Sections.Ad,
            eventNamePrefix: 'immobilier',
        },
        '/ad/[adId]': {
            [EventParamKeys.ScreenName]: ScreenNames.AdId,
            [EventParamKeys.PageTitle]: PageTitles.Ad,
            [EventParamKeys.Section]: Sections.Ad,
            eventNamePrefix: 'immobilier_details',
        },
        '/community/search': {
            [EventParamKeys.ScreenName]: 'Community_Search',
            [EventParamKeys.PageTitle]: PageTitles.Community,
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute',
        },
        '/community/category/[categoryId]': {
            [EventParamKeys.ScreenName]: ScreenNames.Community_Category,
            [EventParamKeys.PageTitle]: PageTitles.Community,
            [EventParamKeys.Section]: Sections.Community,
            eventNamePrefix: 'communaute',
        },
        '/news': {
            [EventParamKeys.ScreenName]: ScreenNames.News,
            [EventParamKeys.PageTitle]: PageTitles.News,
            [EventParamKeys.Section]: Sections.Actuality,
            eventNamePrefix: 'article',
        },
        '/news-post/[newsPostId]': {
            [EventParamKeys.ScreenName]: ScreenNames.NewsPost,
            [EventParamKeys.PageTitle]: PageTitles.NewsPost,
            [EventParamKeys.Section]: Sections.Actuality,
            eventNamePrefix: 'article',
        },
        '/contacts': {
            [EventParamKeys.ScreenName]: ScreenNames.Contacts,
            [EventParamKeys.PageTitle]: PageTitles.Contacts,
            [EventParamKeys.Section]: Sections.Contacts,
            eventNamePrefix: 'contacts',
        },
        '/profile/estimate/': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileLegal,
            [EventParamKeys.PageTitle]: PageTitles.TermsAndMentions,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'profile_estime',
        },
        '/profile/legal': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileLegal,
            [EventParamKeys.PageTitle]: PageTitles.TermsAndMentions,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'profil_regles_mentions',
        },
        '/profile/posts': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfilePosts,
            [EventParamKeys.PageTitle]: PageTitles.ProfilePostsAndStories,
            [EventParamKeys.Section]: Sections.Profile,
        },
        '/profile/help': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileHelp,
            [EventParamKeys.PageTitle]: PageTitles.ProfileHelp,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/ads': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileAds,
            [EventParamKeys.PageTitle]: PageTitles.ProfileAds,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/simulations': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileSimulations,
            [EventParamKeys.PageTitle]: PageTitles.ProfileSimulations,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/statistics': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileStatistics,
            [EventParamKeys.PageTitle]: PageTitles.ProfileStatistics,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/projects': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileProjects,
            [EventParamKeys.PageTitle]: PageTitles.ProfileProjects,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/interests': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileInterests,
            [EventParamKeys.PageTitle]: PageTitles.ProfileInterests,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/profile/ratings': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileRatings,
            [EventParamKeys.PageTitle]: PageTitles.ProfileRatings,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil',
        },
        '/estimate/step-1': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate1,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-2': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate2,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-3': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate3,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-4': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate4,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-5': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate5,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-6': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate6,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/estimate/step-7': {
            [EventParamKeys.ScreenName]: ScreenNames.PartialEstimate,
            [EventParamKeys.PageTitle]: PageTitles.PartialEstimate7,
            [EventParamKeys.Section]: Sections.Estimate,
            eventNamePrefix: 'estimation_partielle',
        },
        '/profile/estimate/step-1': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate1,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-2': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate2,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-3': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate3,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-4': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate4,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-5': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate5,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-6': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate6,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/step-7': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate7,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/results/[estimationId]': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileEstimate,
            [EventParamKeys.PageTitle]: PageTitles.ProfileEstimate,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/estimate/security': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileSecurity,
            [EventParamKeys.PageTitle]: PageTitles.ProfileSecurity,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_estimer',
        },
        '/profile/company/members': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyMembers,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyMembers,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_membres',
        },
        '/profile/company/members/admin-rights': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyMembersAdmins,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_membres_administrateurs',
        },
        '/profile/company/members/team': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyMembersTeam,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_membres_equipe',
        },
        '/profile/company/members/sent-pending-requests': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyMembersSentInvitations,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_membres_envoye',
        },
        '/profile/company/members/received-pending-requests': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyMembersReceivedInvitations,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_membres_recu',
        },
        '/profile/company/favorites/[galleryId]': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyFavorites,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyFavorites,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_gallerie',
        },
        '/profile/company/showcase': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyShowcase,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyShowcase,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_vitrine',
        },
        '/profile/company/info': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyInfo,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyInfo,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_info',
        },
        '/profile/company/jobs': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyJobs,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyJobs,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_emplois',
        },
        '/profile/security': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileSecurity,
            [EventParamKeys.PageTitle]: PageTitles.ProfileSecurity,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_securite',
        },
        '/profile/company/crm-sync': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyCrmSync,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyCrmSync,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_crm_sync',
        },
        '/profile/company/info/crm-sync': {
            [EventParamKeys.ScreenName]: ScreenNames.ProfileCompanyCrmSync,
            [EventParamKeys.PageTitle]: PageTitles.ProfileCompanyCrmSync,
            [EventParamKeys.Section]: Sections.Profile,
            eventNamePrefix: 'gestion_profil_entreprise_crm_sync',
        },
    };

export const eventsToLabelsMapping = {
    accueil_rechercher_un_bien: 'Rechercher un bien',
    accueil_trouver_des_pros: 'Trouver des pros',
    accueil_estimer_un_bien: 'Estimer un bien',
    accueil_publier_une_annonce: 'Publier une annonce',
    accueil_google_store: 'Accéder app dans Google Store',
    accueil_apple_store: 'Accéder app dans Apple Store',
    accueil_changer_langue: 'Changer de langue',
    accueil_contactez_nous: 'Accéder contectez-nous',
    accueil_telecharger_application: 'Accéder télécharger application',
    accueil_conditions_generales: 'Accéder conditions générales',
    accueil_politiques_cookies: 'Accéder politiques cookies',
    accueil_politique_confidentialite: 'Accéder politique confidentialite',
    accueil_mentions_legales: 'Accéder mentions légales',

    storie_regarder: 'Regarder une storie',
    storie_bloquer: 'Bloquer via une storie',
    storie_signaler: 'Signaler une storie',
    storie_envoyer_message: 'Envoyer un message',
    storie_creer: 'Créer une storie',
    storie_creer_confirmation: 'Créer une storie confirmation',
    post_regarder: 'Regarder un post',
    post_ajouter_favoris: 'Ajouter aux favoris',
    post_ajouter_favoris_confirmation: 'Ajouter aux favoris confirmation',
    post_like: 'Like',
    post_unlike: 'Unlike',
    post_commentaire_like: "Like d'un commentaire",
    post_commentaire_unlike: "Unlike d'un commentaire",
    post_ajouter_commentaire: 'Ajouter un commentaire',
    post_commentaire_signaler: 'Signaler un commentaire',
    post_partager_via: 'Demande de partage',
    post_partager_via_copier_lien: 'Partager un lien',
    post_partager_via_email: 'Partager par email',
    post_partager_via_whatsapp: 'Partager par WhatsApp',
    post_partager_via_linkedin: 'Partager via Linkedin',
    post_partager_via_message_prive: 'Partager message privé',
    post_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    post_signaler: 'Signaler un post',
    post_bloquer: 'Bloquer via un post',
    post_abonner: "S'abonner à un utilisateur",
    post_desabonner: "Se désabonner d'un utilisateur",
    suggestion_abonner: "S'abonner à une suggestion",
    suggestion_desabonner: "Se désabonner d'une suggestion",
    suggestion_discuter: 'Suggestion discuter',
    article_ajouter_favoris: 'Ajouter aux favoris',
    article_ajouter_favoris_confirmation: 'Ajouter aux favoris confirmation',
    article_like: 'Like',
    article_unlike: 'Unlike',
    article_partager_via: 'Demande de partage',
    article_partager_via_copier_lien: 'Partager un lien',
    article_partager_via_email: 'Partager par email',
    article_partager_via_whatsapp: 'Partager par WhatsApp',
    article_partager_via_linkedin: 'Partager via Linkedin',
    article_partager_via_message_prive: 'Partager message privé',
    article_partager_via_message_prive_confirmation: 'Partager message privé confirmation',

    immobilier_afficher_liste: 'Afficher par liste',
    immobilier_afficher_carte: 'Afficher par carte',
    immobilier_carte_afficher_details: "Afficher le détail d'une annonce par la carte",
    immobilier_enregistrer_recherche: 'Enregistrer recherche',
    immobilier_enregistrer_recherche_confirmation: 'Enregistrer recherche confirmation',
    immobilier_voir_recherche_enregistree: 'Voir recherches enregistrées',
    immobilier_recherche_enregistree_selectionnee: 'Utiliser une recherche sauvegardée',
    immobilier_plus_filtres: 'Afficher plus de filtres',
    immobilier_trier_plus_recent: 'Trier par plus récent',
    immobilier_trier_prix_croissant: 'Trié par prix croissant',
    immobilier_trier_prix_decroissant: 'Trié par prix décroissant',
    immobilier_ajouter_favoris: 'Ajouter aux favoris',
    immobilier_ajouter_favoris_confirmation: 'Ajouter aux favoris confirmation',
    immobilier_voir_photo: 'Voir une photo',
    immobilier_details_regarder_photos: 'Voir les photos',
    immobilier_details_calculette_emprunt: 'Utiliser calculette emprunt',
    immobilier_details_voir_telephone: 'Voir téléphone',
    immobilier_details_envoyer_message: 'Envoyer un message',
    immobilier_details_signaler: 'Signaler une annonce',
    immobilier_details_ajouter_favoris: 'Ajouter aux favoris',
    immobilier_details_ajouter_favoris_confirmation: 'Ajouter aux favoris confirmation',
    immobilier_details_partager_via: 'Demande de partage',
    immobilier_details_partager_via_copier_lien: 'Partager un lien',
    immobilier_details_partager_via_email: 'Partager par email',
    immobilier_details_partager_via_whatsapp: 'Partager par WhatsApp',
    immobilier_details_partager_via_linkedin: 'Partager via Linkedin',
    immobilier_details_partager_via_message_prive: 'Partager message privé',
    immobilier_details_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    immobilier_details_click_simuler_offre_ilek: "Simuler une offre d'énergie avec Ilek",

    communaute_nouvelle_recherche: 'Nouvelle recherche',
    communaute_page_suivant: 'Récupérer la page suivante',
    communaute_filtre_distance: 'Filtrer par distance',
    communaute_filtre_profil: 'Filtrer par profil',
    communaute_trier_nom: 'Trier par nom',
    communaute_trier_recommendation: 'Trier par recommendation',
    communaute_trier_relation: 'Trier par relation',
    communaute_trier_note: 'Trier par note',
    communaute_trier_distance: 'Trier par distance',
    communaute_abonner: "S'abonner",
    communaute_desabonner: 'Se désabonner',
    communaute_discuter: 'Discuter',
    communaute_profil_voir_relations: 'Voir relations',
    communaute_profil_voir_recommandations: 'Voir recommendation',
    communaute_profil_recommander: 'Recommander',
    communaute_profil_recommander_confirmation: 'Recommander confirmation',
    communaute_profil_inviter: 'Inviter',
    communaute_profil_voir_telephone: 'Voir téléphone',
    communaute_profil_abonner: "S'abonner à un profil",
    communaute_profil_desabonner: "Se désabonner d'un profil",
    communaute_profil_signaler: 'Signaler un profil',
    communaute_profil_bloquer: 'Bloquer un profil',
    communaute_profil_partager_via: 'Demande de partage',
    communaute_profil_partager_via_copier_lien: 'Partager un lien',
    communaute_profil_partager_via_email: 'Partager par email',
    communaute_profil_partager_via_whatsapp: 'Partager par WhatsApp',
    communaute_profil_partager_via_linkedin: 'Partager via Linkedin',
    communaute_profil_partager_via_message_prive: 'Partager message privé',
    communaute_profil_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    communaute_profil_avis: 'Profil section avis',
    communaute_profil_a_propos_description: 'Profil section description',
    communaute_profil_a_propos_entreprise: 'Profil section entreprise',
    communaute_profil_activite_publications: 'Profil section publications',
    communaute_profil_activite_galleries: 'Profil section galleries',
    communaute_entreprise_voir_relations: 'Voir relations',
    communaute_entreprise_voir_recommandations: 'Voir recommendation',
    communaute_entreprise_voir_courriel: 'Voir courriel',
    communaute_entreprise_voir_telephone: 'Voir téléphone',
    communaute_entreprise_abonner: "S'abonner à une entreprise",
    communaute_entreprise_desabonner: "Se désabonner d'une entreprise",
    communaute_entreprise_quitter_equipe: 'Entreprise quitter équipe',
    communaute_entreprise_partager_via: 'Demande de partage',
    communaute_entreprise_partager_via_copier_lien: 'Partager un lien',
    communaute_entreprise_partager_via_email: 'Partager par email',
    communaute_entreprise_partager_via_whatsapp: 'Partager par WhatsApp',
    communaute_entreprise_partager_via_linkedin: 'Partager via Linkedin',
    communaute_entreprise_partager_via_message_prive: 'Partager message privé',
    communaute_entreprise_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    communaute_entreprise_accueil: 'Entreprise section accueil',
    communaute_entreprise_infos: 'Entreprise section infos',
    communaute_entreprise_equipe: 'Entreprise section équipe',
    communaute_entreprise_vitrine: 'Entreprise section vitrine',
    communaute_entreprise_activites: 'Entreprise section activités',
    communaute_entreprise_offres_emploi: "Entreprise section offres d'emplois",
    communaute_entreprisepj_voir_telephone: 'Voir téléphone',
    communaute_entreprisepj_devenir_administrateur: 'Devenir administrateur',
    communaute_entreprisepj_partager_via: 'Demande de partage',
    communaute_entreprisepj_partager_via_copier_lien: 'Partager un lien',
    communaute_entreprisepj_partager_via_email: 'Partager par email',
    communaute_entreprisepj_partager_via_whatsapp: 'Partager par WhatsApp',
    communaute_entreprisepj_partager_via_linkedin: 'Partager via Linkedin',
    communaute_entreprisepj_partager_via_message_prive: 'Partager message privé',
    communaute_entreprisepj_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    communaute_voir_telephone: 'Voir téléphone',
    communaute_entreprisepj_voir_courriel: 'Voir courriel',
    communaute_voir_categorie: 'Voir catégorie',
    communaute_rechercher_activite: 'Rechercher une activité',
    communaute_rechercher_categorie: 'Rechercher une catégorie',

    profil_regles_mentions_mentions_legales: 'Mentions légales',
    profil_regles_mentions_conditions_generales: 'Conditions générales',
    profil_regles_mentions_politiques_confidentialite: 'Politique de confidentialité',
    profil_regles_mentions_politiques_cookies: 'Politique de cookies',
    profil_regles_mentions_licences: 'Licences',
    estimation_partielle_visiteur_etape_1_envoi: 'Estimer étape 1',
    estimation_partielle_visiteur_etape_2_envoi: 'Estimer étape 2',
    estimation_partielle_visiteur_etape_3_envoi: 'Estimer étape 3',
    estimation_partielle_visiteur_etape_4_envoi: 'Estimer étape 4',
    estimation_partielle_visiteur_etape_5_envoi: 'Estimer étape 5',
    estimation_partielle_visiteur_etape_6_envoi: 'Estimer étape 6',
    estimation_partielle_visiteur_etape_7_envoi: 'Voir mon estimation',
    gestion_profil_aide_cliquer_telephone: 'Cliquer téléphone support Woopen',
    gestion_profil_aide_cliquer_courriel: 'Cliquer courriel support Woopen',
    gestion_profil_voir_annonce: 'Voir annonce',
    gestion_profil_modifier_annonce: 'Modifier annonce',
    gestion_profil_bien_a_ete_loue: 'Bien a été loué',
    gestion_profil_bien_a_ete_vendu: 'Bien a été vendu',
    gestion_profil_calculette_emprunt: 'Utiliser calculette emprunt',
    gestion_profil_voir_projet: 'Voir projet',
    gestion_profil_creer_nouveau_projet: 'Nouveau projet',
    gestion_profil_enregistrer_nouveau_projet: 'Enregistrer nouveau projet',
    gestion_profil_abonner_centre_dinterets_populaire: "S'abonner à un centre d'intérêt populaire",
    gestion_profil_desabonner_centre_dinterets_populaire: "Se désabonner d'un centre d'intérêt populaire",
    gestion_profil_abonner_centre_dinterets: "S'abonner à un centre d'intérêt",
    gestion_profil_desabonner_centre_dinterets: "Se désabonner d'un centre d'intérêt",
    gestion_profil_soumettre_centre_dinterets: "Soumettre centre d'intérêt",
    gestion_profil_soumettre_centre_dinterets_confirmation: "Soumettre centre d'intérêt confirmation",
    gestion_profil_avis: 'Avis',
    gestion_profil_recommandations: 'Recommendations',
    gestion_profil_demander_recommandation: 'Demander une recommendation',
    gestion_profil_demander_recommandation_confirmation: 'Demande une recommendation confirmation',
    gestion_profil_avis_voir_utilisateur: 'Voir utilisateur',
    gestion_profil_estimer_etape_1_envoi: 'Estimer étape 1',
    gestion_profil_estimer_etape_2_envoi: 'Estimer étape 2',
    gestion_profil_estimer_etape_3_envoi: 'Estimer étape 3',
    gestion_profil_estimer_etape_4_envoi: 'Estimer étape 4',
    gestion_profil_estimer_etape_5_envoi: 'Estimer étape 5',
    gestion_profil_estimer_etape_6_envoi: 'Estimer étape 6',
    gestion_profil_estimer_etape_7_envoi: 'Estimer étape 7',
    gestion_profil_estimer_partager_via: 'Demande de partage',
    gestion_profil_estimer_partager_via_copier_lien: 'Partager un lien',
    gestion_profil_estimer_partager_via_email: 'Partager par email',
    gestion_profil_estimer_partager_via_whatsapp: 'Partager par WhatsApp',
    gestion_profil_estimer_partager_via_linkedin: 'Partager via Linkedin',
    gestion_profil_estimer_partager_via_message_prive: 'Partager message privé',
    gestion_profil_estimer_partager_via_message_prive_confirmation: 'Partager message privé confirmation',
    gestion_profil_estimer_creer_annonce_via_bien: 'Créer annonce via estimé',
    gestion_profil_entreprise_membres_voir_utilisateur: 'Voir utilisateur',
    gestion_profil_entreprise_membres_inviter: 'Inviter membre',
    gestion_profil_entreprise_membres_ajouter_administrateur: 'Ajouter administrateur',
    gestion_profil_entreprise_membres_ajouter_administrateur_confirmation: 'Ajouter administrateur confirmation',
    gestion_profil_entreprise_membres_ajouter_directeur: 'Ajouter directeur',
    gestion_profil_entreprise_membres_retirer_collaborateur: 'Retirer collaborateur',
    gestion_profil_entreprise_membres_retirer_directeur: 'Retirer directeur',
    gestion_profil_entreprise_membres_rejeter_requete: 'Rejeter requete',
    gestion_profil_entreprise_membres_retirer_invitation: 'Retirer invitation',
    gestion_profil_entreprise_membres_modifier_contact: 'Modifier contact',
    gestion_profil_entreprise_membres_modifier_contact_confirmation: 'Modifier contact confirmation',
    gestion_profil_entreprise_membres_directeur_voir_tout: 'Tout voir direction',
    gestion_profil_entreprise_membres_collaborator_voir_tout: 'Tout voir collaborateurs',
    gestion_profil_entreprise_membres_administrateur_voir_tout: 'Tout voir administrateurs',
    gestion_profil_entreprise_membres_invitations_voir_tout: 'Tout voir invitations',
    gestion_profil_entreprise_membres_demandes_voir_tout: 'Tout voir demandes',
    gestion_profil_entreprise_membres_administrateurs_ajouter_administrateur: 'Ajouter administrateur',
    gestion_profil_entreprise_membres_administrateurs_ajouter_administrateur_confirmation:
        'Ajouter administrateur confirmation',
    gestion_profil_entreprise_membres_equipe_ajouter_directeur: 'Ajouter directeur',
    gestion_profil_entreprise_membres_equipe_retirer_collaborateur: 'Retirer collaborateur',
    gestion_profil_entreprise_membres_equipe_retirer_directeur: 'Retirer directeur',
    gestion_profil_entreprise_membres_administrateurs_modifier_contact: 'Modifier contact administrateur',
    gestion_profil_entreprise_membres_equipe_inviter: 'Inviter dans équipe',
    gestion_profil_entreprise_membres_envoye_retirer_invitation: 'Retirer invitation',
    gestion_profil_entreprise_membres_recu_rejeter_requete: 'Rejeter requete',
    gestion_profil_entreprise_gallerie_parametres: 'Modifier paramètres gallerie',
    gestion_profil_entreprise_gallerie_parametres_confirmation: 'Modifier paramètres gallerie confirmation',
    gestion_profil_entreprise_vitrine_disponibles: 'Biens actifs',
    gestion_profil_entreprise_vitrine_terminees: 'Biens vendus/loués',
    gestion_profil_entreprise_vitrine_archivees: 'Biens archivés',
    gestion_profil_entreprise_info_modifier_photo_couverture: 'Modifier photo couverture',
    gestion_profil_entreprise_info_modifier_photo_profil: 'Modifier photo profil',
    gestion_profil_entreprise_info_ajouter_photo: 'Ajouter photo',
    gestion_profil_entreprise_info_supprimer_photo: 'Supprimer photo',
    gestion_profil_entreprise_info_ajouter_video_presentation: 'Ajouter vidéo présentation',
    gestion_profil_entreprise_info_importer_document_legal: 'Importer document légal',
    gestion_profil_entreprise_info_modifier_siret: 'Modifier siret',
    gestion_profil_entreprise_info_modifier_siret_confirmation: 'Modifier siret confirmation',
    gestion_profil_entreprise_info_mettre_a_jour: 'Mettre à jour entreprise info',
    gestion_profil_entreprise_emplois_supprimer_offre_emploi: "Supprimer une offre d'emploi",
    gestion_profil_entreprise_emplois_supprimer_offre_emploi_confirmation: "Supprimer une offre d'emploi confirmation",
    gestion_profil_entreprise_emplois_masquer_offre_emploi: "Masquer une offre d'emploi",
    gestion_profil_entreprise_emplois_demasquer_offre_emploi: "Demasquer une offre d'emploi",
    gestion_profil_securite_desactiver_mon_compte: 'Désactiver mon compte',
    gestion_profil_securite_changer_mon_mot_de_passe: 'Changer mon mot de passe',
    gestion_profil_securite_adresses_couriel: 'Adresses couriel',
    gestion_profil_securite_ajouter_courriel: 'Ajouter courriel',
    gestion_profil_securite_renvoyer_courriel_confirmation: 'Renvoyer un courriel de confirmation',
    gestion_profil_securite_supprimer_courriel: 'Supprimer courriel',
    gestion_profil_securite_notifications_recherches_courriel_activer:
        'Activer les notifications courriel pour les recherches sauvegardées',
    gestion_profil_securite_notifications_recherches_courriel_desactiver:
        'Desactiver les notifications courriel pour les recherches sauvegardées',
    gestion_profil_entreprise_crm_sync_choisir_partenaire: 'Choisir partenaire',
    gestion_profil_entreprise_crm_sync_envoyer_demande: 'Envoyer demande',
    gestion_profil_entreprise_crm_sync_supprimer_synchronisation: 'Supprimer synchronisation',
    gestion_profil_entreprise_crm_sync_passer_crm_synchro: 'Passer CRM synchro',
    gestion_profil_entreprise_membres_equipe_rechercher: 'Rechercher',
    gestion_profil_entreprise_membres_equipe_collaborateurs: 'Collaborateurs',
    gestion_profil_entreprise_membres_equipe_directeurs: 'Direction',
};

export const getUserOrCompanyOrPagesJaunesProLogParams = ({
    user,
    company,
    pj,
}: {
    user?: Pick<User, 'id' | 'fullName'>;
    company?: Pick<Company, 'id' | 'name'>;
    pj?: Pick<PagesJaunesPro, 'proId' | 'name'>;
}) => {
    const recommendeeDisplayName = user?.fullName ?? company?.name ?? pj?.name;
    return {
        [EventParamKeys.ItemId]: user?.id ?? company?.id ?? pj?.proId,
        [EventParamKeys.ItemName]: recommendeeDisplayName,
    };
};

export const getAnalyticsParams = (
    eventNameRoot: string,
    pathname: string,
    eventNamePrefixFromContext?: string | undefined,
) => {
    if (!(pathname in pathnameAnalyticsMapping)) {
        return {
            eventName: [eventNamePrefixFromContext, eventNameRoot].filter(Boolean).join('_') as AnalyticsEvent,
        };
    }
    const { eventNamePrefix, ...params } = pathnameAnalyticsMapping[pathname];
    const fullEventName = [eventNamePrefixFromContext ?? eventNamePrefix, eventNameRoot].filter(Boolean).join('_');

    const labelFromFullEventName =
        (eventsToLabelsMapping[fullEventName as keyof typeof eventsToLabelsMapping] as string | undefined) ?? undefined;

    params[EventParamKeys.Label] = params[EventParamKeys.Label] ?? labelFromFullEventName;

    return {
        eventName: fullEventName,
        params,
    };
};
