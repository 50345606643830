import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { useDebounce } from 'react-use';

import AudioCard from 'src/components/other/AudioCard';
import { useAddContentEventMutation } from 'src/hooks/__generated__/queries';
import audioReducer, { ActionType as AudioActionType } from 'src/reducers/audioReducer';
import type { Medium } from 'src/types/__generated__/graphql';
import getMediaInfo from 'src/utils/getMediaInfo';
import { sleep } from 'src/utils/sleep';
import stopPropagation from 'src/utils/stopPropagation';
import { styledTag } from 'src/utils/styled';

const HiddenAudio = styledTag('audio')`
    display: none;
`;

export interface GlobalAudioContent {
    type: 'newsPost';
    id: string;
}

export interface ChangeAudioProps {
    medium: Medium;
    title: string;
    content?: GlobalAudioContent;
}

interface IAudioContext {
    changeAudioAndPlay: (props: ChangeAudioProps) => unknown;
    medium?: Medium;
    openIfMediumPlayed: (mediumUrl: string) => unknown;
    close: (shouldPause: boolean) => unknown;
    play: () => Promise<unknown>;
    pause: () => unknown;
    isPlaying: boolean;
    timeSliderValue: number;
    setTimeSliderValue: (value: number) => unknown;
    muted: boolean;
    mute: () => unknown;
    unmute: () => unknown;
    volume: number;
    setVolume: (volume: number) => unknown;
    updateCurrentTime: (value: number) => unknown;
    currentTime: number;
}

export const timeSliderSteps = 1000;
export const volumeSliderSteps = 10;

export const AudioContext = createContext({} as IAudioContext);

const AudioProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [addContentEventMutation] = useAddContentEventMutation();
    const [isMovingTime, setIsMovingTime] = useState(false);
    const [deboucedIsMovingTime, setDeboucedIsMovingTime] = useState(false);

    useDebounce(
        () => {
            setDeboucedIsMovingTime(isMovingTime);
        },
        250,
        [isMovingTime],
    );

    const [audioStore, audioDispatcher] = useReducer(audioReducer, {
        isOpen: false,
        isPlaying: false,
        isMuted: false,
        timeSliderValue: 0,
        currentTime: 0,
        volume: 10,
        medium: undefined,
    });

    const mediaInfo = audioStore.medium ? getMediaInfo(audioStore.medium.url) : undefined;

    const setTimeSliderValue = (value: number) => {
        audioDispatcher({
            type: AudioActionType.SET_TIME_SLIDER_VALUE,
            payload: value,
        });
    };

    useEffect(() => {
        if (audioStore.medium && !deboucedIsMovingTime) {
            setTimeSliderValue((audioStore.currentTime / audioStore.medium.duration) * timeSliderSteps);
        }
    }, [audioStore.currentTime, audioStore.medium, deboucedIsMovingTime]);

    useEffect(() => {
        if (audioRef.current) {
            audioDispatcher({
                type: !audioRef.current.paused ? AudioActionType.PLAY : AudioActionType.PAUSE,
            });
        }
    }, [audioRef.current?.paused]);

    const updateCurrentTime = useCallback(
        (value: number): void => {
            if (audioRef.current?.currentTime && audioStore.medium) {
                setIsMovingTime(false);
                audioRef.current.currentTime = (value * audioStore.medium.duration) / timeSliderSteps;
            }
        },
        [audioStore.medium],
    );

    const onTimeUpdateHandle = (e: React.SyntheticEvent<HTMLMediaElement | HTMLAudioElement>) => {
        const { currentTime } = e.target as HTMLMediaElement;
        audioDispatcher({
            type: AudioActionType.SET_CURRENT_TIME,
            payload: currentTime,
        });
    };

    const close = useCallback((shouldPause = false) => {
        audioDispatcher({
            type: AudioActionType.CLOSE,
        });
        if (shouldPause) {
            pause();
        }
    }, []);

    const handleAudioCardClose = useCallback(() => {
        close(true);
        if (audioStore.content?.type === 'newsPost') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            addContentEventMutation({
                variables: {
                    newsPostId: audioStore.content.id,
                    eventType: 'closed_floating_audio_player',
                },
                fetchPolicy: 'no-cache',
            });
        }
    }, [addContentEventMutation, audioStore.content, close]);

    const handleTimeSliderChange = useCallback((value: number) => {
        setIsMovingTime(true);
        setDeboucedIsMovingTime(true); // We don't want to wait the debounce to set it to true
        setTimeSliderValue(value);
    }, []);

    const play = async () => {
        await audioRef.current?.play();
    };

    const pause = () => {
        audioRef.current?.pause();
    };

    const afterOpenIfMediumPlayed = useCallback(
        (open: boolean, content?: GlobalAudioContent) => {
            if (open) {
                if (content?.type === 'newsPost') {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    addContentEventMutation({
                        variables: {
                            newsPostId: content.id,
                            eventType: 'opened_floating_audio_player',
                        },
                        fetchPolicy: 'no-cache',
                    });
                }
            }
        },
        [addContentEventMutation],
    );

    const openIfMediumPlayed = useCallback(
        (mediumUrl: string) => {
            audioDispatcher({
                type: AudioActionType.OPEN_IF_MEDIUM_PLAYED,
                payload: mediumUrl,
                callback: afterOpenIfMediumPlayed,
            });
        },
        [afterOpenIfMediumPlayed],
    );

    const mute = () => {
        if (audioRef.current) {
            audioRef.current.muted = true;
            audioDispatcher({
                type: AudioActionType.MUTE,
            });
        }
    };

    const unmute = () => {
        if (audioRef.current) {
            audioRef.current.muted = false;
            audioDispatcher({
                type: AudioActionType.UNMUTE,
            });
        }
    };

    const setVolume = (value: number) => {
        if (audioRef.current) {
            audioRef.current.muted = false;
            audioRef.current.volume = value / volumeSliderSteps;
            audioDispatcher({
                type: AudioActionType.SET_VOLUME,
                payload: value,
            });
        }
    };

    const changeAudioAndPlay = async (props: ChangeAudioProps) => {
        audioDispatcher({
            type: AudioActionType.CHANGE_AUDIO,
            payload: props,
        });
        if (audioRef.current) {
            await sleep(250); // Wait to <audio> have time to load the new src
            // eslint-disable-next-line require-atomic-updates -- not important
            audioRef.current.muted = false;
            await audioRef.current.play();
        }
    };

    const value: IAudioContext = useMemo(
        () => ({
            openIfMediumPlayed,
            close,
            play,
            pause,
            isPlaying: audioStore.isPlaying,
            medium: audioStore.medium,
            changeAudioAndPlay,
            timeSliderValue: audioStore.timeSliderValue,
            setTimeSliderValue: handleTimeSliderChange,
            volume: audioStore.volume,
            setVolume,
            muted: audioStore.isMuted,
            mute,
            unmute,
            updateCurrentTime,
            currentTime: audioStore.currentTime,
        }),
        [
            audioStore.currentTime,
            audioStore.isMuted,
            audioStore.isPlaying,
            audioStore.medium,
            audioStore.timeSliderValue,
            audioStore.volume,
            close,
            handleTimeSliderChange,
            openIfMediumPlayed,
            updateCurrentTime,
        ],
    );

    const audioCardMemo = useMemo(
        () =>
            audioStore.isOpen ? (
                <AudioCard
                    title={audioStore.title ?? 'Audio'}
                    isPaused={Boolean(audioRef.current?.paused)}
                    onPlay={play}
                    onPause={pause}
                    onClose={handleAudioCardClose}
                    timeSliderValue={audioStore.timeSliderValue}
                    onTimeSliderChange={handleTimeSliderChange}
                    onTimeSliderChangeCommited={updateCurrentTime}
                    currentTime={audioStore.currentTime}
                />
            ) : undefined,
        [
            audioStore.currentTime,
            audioStore.isOpen,
            audioStore.timeSliderValue,
            audioStore.title,
            handleAudioCardClose,
            updateCurrentTime,
            handleTimeSliderChange,
        ],
    );

    return (
        <AudioContext.Provider value={value}>
            {audioCardMemo}
            <HiddenAudio
                ref={audioRef}
                onClick={stopPropagation}
                onTimeUpdate={onTimeUpdateHandle}
                controls={false}
                src={audioStore.medium?.url}
                preload="auto"
            />
            {children}
        </AudioContext.Provider>
    );
};

export default AudioProvider;
