import React from 'react';

import type { DividerProps } from '@mui/material/Divider';
import MuiDivider from '@mui/material/Divider';

import theme from 'src/utils/theme';
import { colours } from 'src/constants/colours';
import { styledComponent } from 'src/utils/styled';

interface StyledDividerProps extends DividerProps {
    $label?: string;
}

const StyledDivider = styledComponent(MuiDivider)`
    position: relative;
    overflow: visible;
    ${(props) =>
        props.color &&
        `
        background-color: ${props.color};
    `};
    ${(props: StyledDividerProps) =>
        props.$label &&
        `
        &::after {
            content: "${props.$label}";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: ${colours.black.O60};
            background-color: ${theme.palette.background.paper};
            padding: 0 0.5rem;
            text-transform: uppercase;
        }
    `}
`;

interface Props extends DividerProps {
    label?: string;
}

const Divider: React.FC<Props> = (props) => {
    const { label, ...rest } = props;
    return <StyledDivider $label={label} {...(rest as DividerProps)} />;
};

export default Divider;
