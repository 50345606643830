import React from 'react';

import type { LinkProps } from 'src/components/ui/Link';
import Link from 'src/components/ui/Link';
import theme from 'src/utils/theme';
import { styledComponent } from 'src/utils/styled';

const BlueLink = styledComponent((props: LinkProps) => <Link {...props} />)`
  color: ${theme.palette.secondary.main};
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
`;

export default BlueLink;
