import parse from 'html-react-parser';

import { mentionRegEx } from 'src/constants/regexes';
import { routePathCompany, routePathUser } from 'src/constants/router';
import { sanitizeHTMLContent, parseUnclosedLtChars } from 'src/utils/sanitizeHtml';

/**
 * Formats a message with mentions. It replaces all the mentions by a link to mentioned entity.
 * @param message Unformated message with mentions.
 * @returns Returns a formated message with links instead of unformated mentions.
 */
export const getFormattedMessageWithMentions = (message: string, shouldParse = true): React.ReactNode[] | string => {
    let formattedMessage = message;

    const matches = [...message.matchAll(mentionRegEx)];
    matches.forEach(([toReplace, id, nameDisplay], index) => {
        const isCompany = id.startsWith('comp:');
        const formatedId = isCompany ? id.substring('comp:'.length) : id;
        const route = `${isCompany ? routePathCompany : routePathUser}/${formatedId}`;

        formattedMessage = formattedMessage.replace(
            toReplace,
            `<a key="${index}-${
                isCompany ? 'company' : 'user'
            }-${formatedId}" href="${route}" target="_blank" rel="noopener noreferrer" > ${nameDisplay} </a>`,
        );
    });

    return shouldParse
        ? (parse(sanitizeHTMLContent(parseUnclosedLtChars(formattedMessage))) as React.ReactNode[])
        : sanitizeHTMLContent(parseUnclosedLtChars(formattedMessage));
};

/**
 * Checks if the length of a message with mentions is valid.
 * @param message The message with mentions.
 * @returns Returns if the message with mention has a valid length.
 */
export const isMessageWithMentionsLengthValid = (message: string | undefined | null, maxLength: number) => {
    if (message && (message as string).length > 0) {
        let transformedMessage = message as string;
        const matches = [...(message as string).matchAll(mentionRegEx)];
        if (matches.length > 0) {
            matches.forEach(([toReplace, id, nameDisplay]) => {
                transformedMessage = transformedMessage.replace(toReplace, nameDisplay);
            });
        }
        return Boolean(transformedMessage.length <= maxLength);
    }
    return true;
};
