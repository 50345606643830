import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import React from 'react';

import useAppRouter from 'src/hooks/useAppRouter';

const baseUrl = process.env.NEXT_PUBLIC_SITE_URL;
const canonicalBaseUrl = 'https://www.woopen.com';

const BaseSEO: React.FC<SEOProps> = ({
    pageTitleTranslation,
    metaTitleTranslation,
    metaDescriptionTranslation,
    twitterTitleTranslation,
    twitterDescriptionTranslation,
    imageUrl,
    noFollow,
}) => {
    const { t } = useTranslation(['common']);
    const router = useAppRouter();

    const constructHeadParam = (translation?: TranslationProps | null, defaultValue?: string) =>
        translation?.key ? t(translation.key, translation.params ?? {}) : translation?.value ?? defaultValue;

    const pageTitleBase = constructHeadParam(pageTitleTranslation);
    const defaultPageTitle = t('common:seo.title');
    const pageTitle = pageTitleBase ?? defaultPageTitle;

    // if we have specific values for meta title, we put them, else we use the title (specific or default one)
    const metaTitleBase = constructHeadParam(metaTitleTranslation);
    const metaTitle = metaTitleBase ?? pageTitle;

    // if we have specific values for meta descripton, we put them, else we use default description
    const metaDescription = constructHeadParam(metaDescriptionTranslation, t('common:seo.description'));

    // if we have specific values for twitter title, we put them, else we use default "meta" values,
    const twitterTitleBase = constructHeadParam(twitterTitleTranslation);
    const twitterTitle = twitterTitleBase ?? metaTitle;

    // if we have specific values for twitter descripton, we put them, else we use default "meta" values,
    const twitterDescription = constructHeadParam(twitterDescriptionTranslation, metaDescription);

    const currentUrl = `${baseUrl}${router.asPath}`;
    const canonicalUrl = `${canonicalBaseUrl}${router.asPath}`;

    const englishCurrentUrl = `${baseUrl}/en${router.asPath}`;

    return (
        <Head>
            <title key="title" data-target-id="meta-title">
                {pageTitle}
            </title>

            <link rel="alternate" hrefLang="fr-FR" href={currentUrl} />
            <link rel="alternate" hrefLang="en" href={englishCurrentUrl} />
            <link rel="alternate" hrefLang="x-default" href={currentUrl} />

            <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
            <meta
                key="description"
                data-target-id="meta-description"
                name="description"
                content={metaDescription} // if no meta from props it's the default one
            />

            <link
                // 48×48
                rel="icon"
                href="/favicon.png"
                type="image/png"
            />
            <link rel="home" href={baseUrl} />
            <link rel="manifest" href="/manifest.json" />

            {/* Prevent punishing duplicate SEO URLs, improve SEO */}
            <link rel="canonical" href={canonicalUrl} />

            {/* Prevent search engine to index non-production */}
            {process.env.NEXT_PUBLIC_ENV !== 'prod' && (
                <meta data-target-id="meta-robots" name="robots" content="noindex,nofollow" />
            )}
            {noFollow && <meta data-target-id="meta-robots" name="robots" content="noindex,nofollow" />}

            {/* Open graph */}
            <meta key="og:type" data-target-id="og:type" property="og:type" content="website" />
            <meta key="og:locale" data-target-id="og:locale" property="og:locale" content={router.locale} />
            <meta key="og:title" data-target-id="og:title" property="og:title" content={metaTitle} />
            <meta
                key="og:description"
                data-target-id="og:description"
                property="og:description"
                content={metaDescription}
            />
            <meta
                key="og:site_name"
                data-target-id="og:site_name"
                property="og:site_name"
                content={t('common:seo.title')}
            />
            <meta key="og:url" data-target-id="og:url" property="og:url" content={currentUrl} />

            <meta
                key="og:image"
                property="og:image"
                data-target-id="og:image"
                content={imageUrl ?? `${process.env.NEXT_PUBLIC_SITE_URL}/logo512.png`}
            />
            <meta key="og:image:width" property="og:image:width" data-target-id="og:image:width" content="512" />
            <meta key="og:image:height" property="og:image:height" data-target-id="og:image:height" content="512" />
            <meta key="og:image:alt" property="og:image:alt" content={metaTitle} />

            {/* Twitter */}
            <meta key="twitter:card" name="twitter:card" data-target-id="twitter:card" content="summary" />
            <meta key="twitter:url" name="twitter:url" data-target-id="twitter:url" content={currentUrl} />
            <meta key="twitter:title" name="twitter:title" data-target-id="twitter:title" content={twitterTitle} />
            <meta
                key="twitter:description"
                name="twitter:description"
                data-target-id="twitter:description"
                content={twitterDescription}
            />
        </Head>
    );
};

export default BaseSEO;
