export const PagesJaunesProNotFoundError = 'Pages Jaunes Pro not found';
export const PagesJaunesProOtherError = 'Pages Jaunes Pro other error';
export const pagesJaunesErrorMessages = [PagesJaunesProNotFoundError, PagesJaunesProOtherError];
export const isPagesJaunesError = (errorMessage: string | undefined) =>
    errorMessage && pagesJaunesErrorMessages.find((x) => x === errorMessage);

const pagesJaunesUrlPrefix = `${
    process.env.NEXT_PUBLIC_ENV === 'local' ? process.env.NEXT_PUBLIC_WEB_SOCKET_URL : process.env.NEXT_PUBLIC_SITE_URL
}/image?imagePath=`;

export const getPagesJaunesImageUrl = (imagePath: string): string => `${pagesJaunesUrlPrefix}${imagePath}`;

export const isPagesJaunesImageUrl = (url: string) => url.includes(pagesJaunesUrlPrefix);
