import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';

import { AppRouterContext } from 'src/components/other/App';

/**
 * @deprecated Use useManagedRouter instead
 */
const useAppRouter = () => {
    const router = useContext(AppRouterContext);
    return router;
};

export const useManagedRouter = () => {
    const router = useRouter();

    /**
     * Add a query parameter to the cuurent query and returns the new query
     * @param param Name of the query parameter
     * @param value Value of the query parameter
     */
    const addQueryParam = useCallback(
        (param: string, value: string) => {
            if (!Object.keys(router.query).includes(param)) {
                return {
                    ...router.query,
                    [param]: value,
                };
            }
            return router.query;
        },
        [router],
    );

    /**
     * Remove a query parameter from the current query and returns the new query
     * @param param Name of the query parameter
     */
    const removeQueryParam = useCallback(
        (param: string) => {
            if (Object.keys(router.query).includes(param)) {
                const { [param]: deletedParam, ...newQuery } = router.query;
                return newQuery;
            }
            return router.query;
        },
        [router],
    );

    /**
     * Remove a list of query parametera from the current query and returns the new query
     * @param param Name of the query parameter
     */
    const removeQueryParams = useCallback(
        (params: string[]) => {
            const newQuery = { ...router.query };

            params.forEach((param) => {
                if (Object.keys(newQuery).includes(param)) {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete newQuery[param];
                }
            });
            return newQuery;
        },
        [router],
    );

    /**
     * Replace a query parameter in the current query and returns the new query
     * @param param Name of the query parameter
     * @param value New value of the query parameter
     * @param options Options for the replacement
     * @param options.addIfNotExists Add the query parameter if it doesn't already exist
     */
    const replaceQueryParam = useCallback(
        (param: string, value: string, options?: { addIfNotExists?: boolean }) => {
            if (Object.keys(router.query).includes(param)) {
                return {
                    ...router.query,
                    [param]: value,
                };
            } else if (options?.addIfNotExists) {
                return addQueryParam(param, value);
            }
            return router.query;
        },
        [addQueryParam, router],
    );

    return {
        router,
        addQueryParam,
        removeQueryParam,
        removeQueryParams,
        replaceQueryParam,
    };
};

export default useAppRouter;
