import React from 'react';

import { useField } from 'formik';

import type { CheckboxProps } from '@mui/material/Checkbox';
import MuiCheckbox from '@mui/material/Checkbox';

import Typography from 'src/components/ui/Typography';
import { Error } from 'src/components/ui/TextField';
import { colours } from 'src/constants/colours';
import { styledComponent, styledTag } from 'src/utils/styled';

const Container = styledTag('label')`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

interface StyledCheckboxProps {
    $showError: boolean;
}

const StyledCheckbox = styledComponent(MuiCheckbox)`
    color: ${colours.primary.O60};
    margin-left: -0.625rem;
    ${(props: StyledCheckboxProps) =>
        props.$showError &&
        `
        color: ${colours.error.main};
    `}
`;

const StyledTypography = styledComponent(Typography)<{ component?: string; $showError?: boolean }>`
    ${({ $showError }) => $showError && `color: ${colours.error.main}`}
`;

const CheckBoxSection = styledTag('div')`
    width: 100%;
`;

interface Props extends CheckboxProps {
    name: string;
    label?: string | React.ReactNode; // be aware to not pass something else than a span
    checked?: boolean;
    showErrorOnLabel?: boolean;
}

const Checkbox: React.FC<Props> = ({ ...props }) => {
    const { name, checked, onChange, label, showErrorOnLabel, ...rest } = props;

    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
        setValue(_checked);
        if (onChange) {
            onChange(event, _checked);
        }
    };

    const showError = Boolean(meta.touched && meta.error);

    return (
        <>
            <Container htmlFor={name}>
                <StyledCheckbox
                    id={name}
                    onChange={handleChange}
                    checked={checked ?? (field.value as boolean)}
                    $showError={showError}
                    {...rest}
                />
                {label && (
                    <StyledTypography component="span" $showError={showErrorOnLabel && showError}>
                        {label}
                    </StyledTypography>
                )}
            </Container>
            <CheckBoxSection>{showError && <Error>{meta.error}</Error>}</CheckBoxSection>
        </>
    );
};

export default Checkbox;
