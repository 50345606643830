import React from 'react';
import type { DialogTitleProps } from '@mui/material/DialogTitle';
import MuiDialogTitle from '@mui/material/DialogTitle';

import type { TypographyProps } from '@mui/material';
import { Typography, IconButton } from '@mui/material';

import Icon from 'src/components/ui/Icon';
import { styledComponent } from 'src/utils/styled';

const StyledDialogTitle = styledComponent(MuiDialogTitle)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    .MuiIconButton-root {
        padding: 0;
    }
`;

export interface Props extends DialogTitleProps {
    children: React.ReactNode;
    onClose?: () => void;
    variant?: TypographyProps['variant'];
}

const DialogTitle = (props: Props) => {
    const { children, onClose, variant, ...other } = props;
    return (
        <StyledDialogTitle {...other}>
            {variant ? <Typography variant={variant}>{children}</Typography> : children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose}>
                    <Icon name="Close" />
                </IconButton>
            ) : null}
        </StyledDialogTitle>
    );
};

export default DialogTitle;
