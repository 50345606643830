export const colours = {
    // Osedea originals
    primaryOsedea: '#3a529c',
    secondaryOsedea: '#1ca9f0',
    offWhite: '#f4f5f9',
    navy: '#495576',
    // Woopen custom
    snackBarGray: '#B30A0A0A',
    linkGrey: 'rgba(0, 0, 0, 0.6)',
    lightGray: '#E5E5E5',
    backgroundGray: '#BDBDBD',
    lightBackgroundGray: '#FAFAFA',
    subtitleGrey: '#4b4b4b',
    greenCheck: '#61B774',
    orange: '#F09C1D',
    orangePill: '#F2994A',
    redIcon: '#E04C38',
    redX: '#C51520',
    redError: '#D80000',
    redErrorBackground: '#E800000D',
    greenSuccess: '#00845C',
    redPill: '#EB5757',
    pink: '#EA5CA0',
    yellow: '#F0DB1D',
    lightCyan: '#4DBECC',
    orchid: '#CD67DE',
    purpleLabel: '#CBB2FF',
    purpleBackground: '#BB60DB',
    lightBlue: '#73D7FF',
    paleBlue: '#CCE3F8',
    paleLightBlue: '#ECF5FD',
    paletteSecondaryLight: '#F5F5F5',
    paletteSecondaryMain: '#D0D0D0',
    paletteSecondaryDark: '#939393',
    paletteSuccessLight: '#52be7f',
    paletteSuccessMain: '#27AE60',
    paletteSuccessDark: '#1b7943',
    lightColor: '#939393',
    subtleText: '#636363',
    dividerColor: '#F5F5F5',
    placeholderColor: '#464646',
    iconButtonBorder: '#D0D0D0',
    paletteRustMain: '#BF4D00',
    addressOnAgencyCard: 'rgba(19, 46, 72, 0.38)',
    // Ad page
    statsGrey: '#222222',
    statsDividerGrey: '#BDBDBD',
    veryGoodPriceGreen: '#46C968',
    goodPriceGreen: '#9bbf35',
    expensivePriceRed: '#ff522d',
    borderGray: '#E3E3E3',
    lightDividerColor: '#f4f4f4',
    mainShadowColor: 'rgba(231, 231, 231, 0.25)',
    lightShadowColor: 'rgba(148, 148, 148, 0.25)',
    shareButtonBorder: 'rgba(19, 46, 72, 0.72)',

    // Community page
    lightBorderColor: '#F2F2F2',

    // Design system

    // Steel blue
    primary: {
        dark: '#000321',
        main: '#132E48',
        light: '#405774',
        O87: '#324960',
        O72: '#55687b',
        O60: '#718291',
        O38: '#a5afb9',
        O24: '#c7cdd3',
        O16: '#d9dde2',
        O12: '#e2e6e9',
        O08: '#eceff1',
        O04: '#f6f7f8',
    },
    // Note => we need sometimes color like primary with opacity on a white background => use primary:{} colors
    // => other time we really need opacity => use primaryOpacity:{} colors
    primaryOpacity: {
        dark: '#000321',
        main: '#132E48',
        light: '#405774',
        O87: 'rgba(19, 46, 72, 0.87)',
        O72: 'rgba(19, 46, 72, 0.72)',
        O60: 'rgba(19, 46, 72, 0.60)',
        O38: 'rgba(19, 46, 72, 0.38)',
        O24: 'rgba(19, 46, 72, 0.24)',
        O16: 'rgba(19, 46, 72, 0.16)',
        O12: 'rgba(19, 46, 72, 0.12)',
        O08: 'rgba(19, 46, 72, 0.08)',
        O04: 'rgba(19, 46, 72, 0.04)',
    },

    // Signal blue
    secondary: {
        dark: '#0048A8',
        main: '#0072DA',
        light: '#60A0FF',
        O87: 'rgba(0, 114, 218, 0.87)',
        O72: 'rgba(0, 114, 218, 0.72)',
        O60: 'rgba(0, 114, 218, 0.60)',
        O38: 'rgba(0, 114, 218, 0.38)',
        O24: 'rgba(0, 114, 218, 0.24)',
        O16: 'rgba(0, 114, 218, 0.16)',
        O12: 'rgba(0, 114, 218, 0.12)',
        O08: 'rgba(0, 114, 218, 0.08)',
        O04: 'rgba(0, 114, 218, 0.04)',
        O01: 'rgba(0, 114, 218, 1)',
    },
    secondaryOpaque: {
        O87: 'rgb(33, 132, 223)',
        O72: 'rgb(71, 153, 228)',
        O60: 'rgb(102, 170, 233)',
        O38: 'rgb(158, 201, 241)',
        O24: 'rgb(194, 221, 246)',
        O16: 'rgb(214, 232, 249)',
        O12: 'rgb(224, 238, 251)',
        O08: 'rgb(235, 244, 252)',
        O04: 'rgb(245, 249, 254)',
        O01: 'rgb(252, 254, 255)',
    },
    // Rust
    tertiary: {
        main: '#BF4D00',
        O87: 'rgba(191, 77, 0, 0.87)',
        O72: 'rgba(191, 77, 0, 0.72)',
        O60: 'rgba(191, 77, 0, 0.60)',
        O38: 'rgba(191, 77, 0, 0.38)',
        O24: 'rgba(191, 77, 0, 0.24)',
        O16: 'rgba(191, 77, 0, 0.16)',
        O12: 'rgba(191, 77, 0, 0.12)',
        O08: 'rgba(191, 77, 0, 0.08)',
        O04: 'rgba(191, 77, 0, 0.04)',
    },

    tertiaryOpaque: {
        O87: 'rgb(199, 100, 33)',
        O72: 'rgb(209, 127, 71)',
        O60: 'rgb(217, 148, 102)',
        O38: 'rgb(231, 187, 158)',
        O24: 'rgb(240, 212, 194)',
        O16: 'rgb(245, 227, 214)',
        O12: 'rgb(247, 234, 224)',
        O08: 'rgb(250, 241, 235)',
        O04: 'rgb(252, 248, 245)',
        O01: 'rgb(254, 253, 252)',
    },

    // Beige
    quaternary: {
        main: '#F5F1EA',
        O87: 'rgba(245, 241, 234, 0.87)',
        O72: 'rgba(245, 241, 234, 0.72)',
        O60: 'rgba(245, 241, 234, 0.60)',
        O38: 'rgba(245, 241, 234, 0.38)',
        O24: 'rgba(245, 241, 234, 0.24)',
        O16: 'rgba(245, 241, 234, 0.16)',
        O12: 'rgba(245, 241, 234, 0.12)',
        O08: 'rgba(245, 241, 234, 0.08)',
        O04: 'rgba(245, 241, 234, 0.04)',
    },

    success: {
        main: '#00845C',
        O87: 'rgba(0, 132, 92, 0.87)',
        O72: 'rgba(0, 132, 92, 0.72)',
        O60: 'rgba(0, 132, 92, 0.60)',
        O38: 'rgba(0, 132, 92, 0.38)',
        O24: 'rgba(0, 132, 92, 0.24)',
        O16: 'rgba(0, 132, 92, 0.16)',
        O12: 'rgba(0, 132, 92, 0.12)',
        O08: 'rgba(0, 132, 92, 0.08)',
        O04: 'rgba(0, 132, 92, 0.04)',
    },
    successOpaque: {
        O87: 'rgb(33, 148, 113)',
        O72: 'rgb(71, 166, 138)',
        O60: 'rgb(102, 181, 157)',
        O38: 'rgb(158, 208, 193)',
        O24: 'rgb(194, 225, 216)',
        O16: 'rgb(214, 235, 229)',
        O12: 'rgb(224, 240, 235)',
        O08: 'rgb(235, 245, 242)',
        O04: 'rgb(245, 250, 248)',
        O01: 'rgb(252, 254, 253)',
    },
    error: {
        main: '#D80000',
        O87: 'rgba(216, 0, 0, 0.87)',
        O72: 'rgba(216, 0, 0, 0.72)',
        O60: 'rgba(216, 0, 0, 0.60)',
        O38: 'rgba(216, 0, 0, 0.38)',
        O24: 'rgba(216, 0, 0, 0.24)',
        O16: 'rgba(216, 0, 0, 0.16)',
        O12: 'rgba(216, 0, 0, 0.12)',
        O08: 'rgba(216, 0, 0, 0.08)',
        O04: 'rgba(216, 0, 0, 0.04)',
    },
    black: {
        main: '#000000',
        O87: '#212121',
        O72: '#474747',
        O60: '#666666',
        O38: '#9e9e9e',
        O24: '#c2c2c2',
        O16: '#d6d6d6',
        O12: '#e0e0e0',
        O08: '#ebebeb',
        O04: '#f5f5f5',
    },
    blackOpacity: {
        main: '#000000',
        O87: 'rgba(0, 0, 0, 0.87)',
        O72: 'rgba(0, 0, 0, 0.72)',
        O60: 'rgba(0, 0, 0, 0.60)',
        O54: 'rgba(0, 0, 0, 0.54)',
        O38: 'rgba(0, 0, 0, 0.38)',
        O24: 'rgba(0, 0, 0, 0.24)',
        O16: 'rgba(0, 0, 0, 0.16)',
        O12: 'rgba(0, 0, 0, 0.12)',
        O08: 'rgba(0, 0, 0, 0.08)',
        O04: 'rgba(0, 0, 0, 0.04)',
    },
    greyOpacity: {
        O50: 'rgba(193, 193, 193, 0.5)',
        O40: 'rgba(193, 193, 193, 0.4)',
        O10: 'rgba(193, 193, 193, 0.1)',
    },
    white: {
        main: '#FFFFFF',
        O87: 'rgba(255, 255, 255, 0.87)',
        O72: 'rgba(255, 255, 255, 0.72)',
        O60: 'rgba(255, 255, 255, 0.60)',
        O50: 'rgba(255, 255, 255, 0.50)',
        O38: 'rgba(255, 255, 255, 0.38)',
        O24: 'rgba(255, 255, 255, 0.24)',
        O16: 'rgba(255, 255, 255, 0.16)',
        O12: 'rgba(255, 255, 255, 0.12)',
        O08: 'rgba(255, 255, 255, 0.08)',
        O04: 'rgba(255, 255, 255, 0.04)',
    },
    grey: {
        main: '#000000',
        G900: '#1A1A1A',
        G800: '#313131',
        G700: '#464646',
        G600: '#727272',
        G500: '#999999',
        G400: '#BDBDBD',
        G300: '#D0D0D0',
        G200: '#E5E5E5',
        G100: '#F5F5F5',
        G50: '#FFFFFF',
    },
};

export const gradients = {
    linearEstimate: `linear-gradient(90deg, #A3E2CF 0%, #F7DD9C 100%);`,
    linearBlue: `linear-gradient(90deg, ${colours.primary.main} 0%, ${colours.secondary.main} 100%)`,
    linearBlueAngled: `linear-gradient(104.83deg, ${colours.primary.main} -10.97%, ${colours.secondary.main} 83.61%)`,
    linearReversedBlueAngled: `linear-gradient(104.83deg, ${colours.secondary.main} -10.97%, ${colours.primary.main} 83.61%)`,
    radialBlue: `radial-gradient(81.01% 81.01% at 100% 0%, ${colours.secondary.main} 0%, ${colours.primary.main} 100%)`,
    storyCard: `linear-gradient(164.91deg,#132e48 1.53%, #0072da 55%, #AD3726 -13.88%, #B03D2B -6.84%, #B74F39 1.38%, #C46C50 13.12%, #D69571 23.69%, #ECC89A 36.61%, #F6DFAC 41.3%, #E3B48A 49.52%, #D38E6B 56.57%, #C56E52 64.79%, #BA563F 74.18%, #B34531 82.4%, #AE3A29 91.79%, #AD3726 103.54%)`,
};
