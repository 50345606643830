import type { ChangeAudioProps, GlobalAudioContent } from 'src/contexts/AudioProvider';
import type { Medium } from 'src/types/__generated__/graphql';

export enum ActionType {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    OPEN_IF_MEDIUM_PLAYED = 'OPEN_IF_MEDIUM_PLAYED',
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    MUTE = 'MUTE',
    UNMUTE = 'UNMUTE',
    SET_TIME_SLIDER_VALUE = 'SET_TIME_SLIDER_VALUE',
    SET_CURRENT_TIME = 'SET_CURRENT_TIME',
    SET_VOLUME = 'SET_VOLUME',
    CHANGE_AUDIO = 'CHANGE_AUDIO',
    SET_TITLE = 'SET_TITLE',
}

type ActionTypeWithNoPayload =
    | ActionType.OPEN
    | ActionType.CLOSE
    | ActionType.PLAY
    | ActionType.PAUSE
    | ActionType.MUTE
    | ActionType.UNMUTE;

type ActionTypeWithPayloadChangeAudioProps = ActionType.CHANGE_AUDIO;

type ActionTypeWithPayloadNumber =
    | ActionType.SET_TIME_SLIDER_VALUE
    | ActionType.SET_CURRENT_TIME
    | ActionType.SET_VOLUME;

type ActionTypeWithPayloadStringAndCallback = ActionType.OPEN_IF_MEDIUM_PLAYED;

type ActionTypeWithPayloadStringOrUndefined = ActionType.SET_TITLE;

export type Action =
    | { type: ActionTypeWithPayloadNumber; payload: number }
    | { type: ActionTypeWithPayloadChangeAudioProps; payload: ChangeAudioProps }
    | {
          type: ActionTypeWithPayloadStringAndCallback;
          payload: string;
          callback: (value: boolean, content?: GlobalAudioContent) => unknown;
      }
    | { type: ActionTypeWithPayloadStringOrUndefined; payload: string | undefined }
    | { type: ActionTypeWithNoPayload };

export interface AudioStoreInterface {
    isOpen: boolean;
    isPlaying: boolean;
    isMuted: boolean;
    timeSliderValue: number;
    currentTime: number;
    volume: number;
    medium?: Medium;
    title?: string;
    content?: GlobalAudioContent;
}

export default function reducer(state: AudioStoreInterface, action: Action): AudioStoreInterface {
    switch (action.type) {
        case ActionType.OPEN: {
            return {
                ...state,
                isOpen: true,
            };
        }
        case ActionType.CLOSE: {
            return {
                ...state,
                isOpen: false,
            };
        }
        case ActionType.PLAY: {
            return {
                ...state,
                isPlaying: true,
            };
        }
        case ActionType.PAUSE: {
            return {
                ...state,
                isPlaying: false,
            };
        }
        case ActionType.MUTE: {
            return {
                ...state,
                isMuted: true,
            };
        }
        case ActionType.UNMUTE: {
            return {
                ...state,
                isMuted: false,
            };
        }
        case ActionType.SET_TIME_SLIDER_VALUE: {
            return {
                ...state,
                timeSliderValue: action.payload,
            };
        }
        case ActionType.SET_CURRENT_TIME: {
            return {
                ...state,
                currentTime: action.payload,
            };
        }
        case ActionType.SET_VOLUME: {
            return {
                ...state,
                volume: action.payload,
                isMuted: false,
            };
        }
        case ActionType.OPEN_IF_MEDIUM_PLAYED: {
            if (!state.isOpen && state.medium?.url === action.payload) {
                action.callback(true, state.content);
                return {
                    ...state,
                    isOpen: true,
                };
            }
            action.callback(false);
            return state;
        }
        case ActionType.CHANGE_AUDIO: {
            return {
                ...state,
                medium: action.payload.medium,
                title: action.payload.title,
                content: action.payload.content,
                timeSliderValue: 0,
                currentTime: 0,
                isMuted: false,
            };
        }
        case ActionType.SET_TITLE: {
            return {
                ...state,
                title: action.payload,
            };
        }
        default:
            return state;
    }
}
