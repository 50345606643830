import { CustomEvent } from '@piwikpro/react-piwik-pro';

type LogCustomEventParam = {
    category: string;
    action: string;
    name?: string;
    value?: number;
};
export const logCustomEvent = ({ category, action, name, value }: LogCustomEventParam) => {
    if (typeof window !== 'undefined' && process.env.JEST_WORKER_ID === undefined) {
        CustomEvent.trackEvent(category, action, name, value);
    }
};
