/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from 'react';
import type { NextComponentType, NextPageContext } from 'next';
import type { AppProps as NextAppProps, AppContext, AppInitialProps } from 'next/app';
import NextApp from 'next/app';
import type { NormalizedCacheObject } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import type { EmotionCache } from '@emotion/react';

import { appWithTranslation } from 'next-i18next';

import App from 'src/components/other/App';
import getApolloClient from 'src/utils/getApolloClient';
import { getAuthCookie } from 'src/utils/authCookie';
import { CurrentUser } from 'src/types/__generated__/graphql';
import useGTMTrackViews from 'src/hooks/useGTMTrackViews';
import createEmotionCache from 'src/utils/createEmotionCache';
import { routePathLogin } from 'src/constants/router';
import 'src/styles/global.css'; // inject global style, (like for google map InfoWindow css)

if (process.env.NEXT_PUBLIC_ENV && process.env.NEXT_PUBLIC_ENV !== 'local') {
    Sentry.init({
        dsn: 'https://a4de09b5fe0d444ebf38d687ed364b1f@o460188.ingest.sentry.io/5535181',
        environment: process.env.NEXT_PUBLIC_ENV ?? 'badly-configured',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

interface RootProps extends NextAppProps {
    Component: NextComponentType<NextPageContext, unknown, unknown>;
    initialApolloState: NormalizedCacheObject;
    pageProps: PageProps;
    emotionCache?: EmotionCache;
}
const Root = (props: RootProps) => {
    const { Component, pageProps, initialApolloState, router, emotionCache } = props;

    useGTMTrackViews(router);

    return (
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <App
                router={router}
                pageProps={pageProps}
                Component={Component}
                initialApolloState={initialApolloState}
                emotionCache={emotionCache}
            />
        </Sentry.ErrorBoundary>
    );
};

/**
 * Initial data population logic for all pages
 * https://nextjs.org/docs/api-reference/data-fetching/getInitialProps
 *
 * This runs on every page navigation
 */
Root.getInitialProps = async (appCtx: AppContext) => {
    const { Component, router, ctx } = appCtx;

    const auth = getAuthCookie(ctx);
    // we use the custom cookie to set a duration to the session (short/long)
    // we use the auth in cookie directly in getApplloClient,
    //  if the user is not authenticated we will get an error in the "CurrentUser" query above and we remove the cookie and the auth header in apollo
    const apolloClient = getApolloClient(null, auth);
    let appProps = {} as AppInitialProps;
    let initialApolloState;
    let session;

    // If there's a auth "idToken" saved
    if (apolloClient) {
        // we use the token get in cookie to try to authenticate in SSR to know if the user is authenticated before render
        // https://dev.to/theranbrig/server-side-authentication-with-nextjs-and-firebase-354m
        try {
            // We load user directly, this way we will receive an error if token is invalid as the CurrentUser is under authentication
            // the fact we get the user direclty here, each part of the code where we use useUser(), the user data is already in cache and we don't need to wait until we have response from backend
            const { data } = await apolloClient.query<CurrentUserQuery>({
                query: CurrentUser,
            });

            if (data.me) {
                // Set the session variable.
                // This is the "source of truth" for the current user
                // and whether or not he's logged in
                session = {
                    auth,
                    user: data.me,
                };
            }
        } catch (error: unknown) {
            // Wipe session
            session = undefined;
        }
    }

    if (session?.user.deletedAt && router.asPath !== routePathLogin) {
        if (appCtx.ctx.res) {
            appCtx.ctx.res.writeHead(302, { Location: routePathLogin });
            appCtx.ctx.res.end();
        } else {
            await router.push(routePathLogin);
        }
        return { ...appProps, pageProps: { namespacesRequired: [] }, initialApolloState };
    }

    // If the page has a "getInitialProps" defined
    if (NextApp.getInitialProps) {
        appProps = await NextApp.getInitialProps({
            ...appCtx,
            ctx: {
                ...appCtx.ctx,

                // Inject the session object in the getInitialProps of that page
                // To be used for redirections based on session data in getInitialProps
                // @ts-expect-error
                session,
            },
        });
    }

    const initialPageLoad = !appCtx.ctx.req?.url?.startsWith('/_next');

    // Populate cache apollo cache by running all GraphQL queries in component tree for this page
    // to provide SEO for initial page load only.
    if (initialPageLoad) {
        try {
            const cache = createEmotionCache();
            await getDataFromTree(
                <App
                    router={router}
                    pageProps={appProps.pageProps}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Component={Component as any}
                    // initialApolloState is empty server-side
                    ssrApolloClient={apolloClient} // Pass a client to be used for fetching data
                    emotionCache={cache}
                />,
            );
        } catch (error: unknown) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            // eslint-disable-next-line no-console
            console.error('Error while running `getDataFromTree`', error);
            Sentry.captureException(error);
        }

        // Send cache to client
        initialApolloState = apolloClient?.extract();
    }

    // Inject data in all page's pageProps
    appProps.pageProps = {
        ...appProps.pageProps,
        namespacesRequired: appProps.pageProps.namespacesRequired
            ? ['common'].concat(appProps.pageProps.namespacesRequired as string)
            : ['common'], // i18n namespace to pre-load on the client, used by next-i18next
    };

    return {
        ...appProps,
        initialApolloState,
    };
};

/* eslint-disable-next-line -- Disable eslint because we need initialApolloState */
export default appWithTranslation(Root as unknown as any);
