export const maximumImageSize = 10 * 1024 * 1024; // 10MB

export const imageMimes = ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'];

export const maximumVideoSize = 150 * 1024 * 1024; // 150MB

// Common video mime types
export const videoMimes = [
    'video/quicktime',
    'video/mp4',
    'video/webm',
    'video/mpeg',
    'video/avi',
    'video/ogv',
    'audio/ogg',
];

export const messageFileMimes = ['application/pdf'];

// Audio extensions that can be uploaded
export const audioExts = ['mp3', 'm4a'];
export const maximumFeeScaleSize = 5 * 1024 * 1024; // 5MB
export const resumeFileMaxFileSize = 5 * 1024 * 1024; // 5MB

export const maximumFilesCount = 10;

// "Fee scale" allowed mime types
export const feeScaleMimes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

// "Resume" allowed mime types
export const resumeMimes = ['image/jpeg', 'image/png', 'application/pdf'];

// Parse an string array of mimes to an object for react-dropzone
// ['image/jpg', 'image/png'] becomes { "image/jpg": [], "image/png": [] }
export const mimeListToDropzoneObject = (mimes: string[]) => mimes.reduce((a, v) => ({ ...a, [v]: [] }), {});
