import { createTheme } from '@mui/material/styles';

import { breakpoints } from 'src/constants/breakpoints';
import { colours } from 'src/constants/colours';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: breakpoints.mobile,
            sm: breakpoints.tabletPortrait,
            md: breakpoints.tabletLandscape,
            lg: breakpoints.desktopSmall,
            xl: breakpoints.desktopLarge,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '16px',
                },
                body: {
                    fontSize: '1rem',
                    backgroundColor: colours.black.O04,
                    color: colours.black.O72,
                },
                em: {
                    fontStyle: 'normal',
                    color: colours.secondary.main,
                },
                a: {
                    color: 'inherit',
                },
                // For heavier images that have a srcset, show a blue background while it loads. `next/image` adds the srcsets attribute
                'img[srcset]': {
                    backgroundColor: colours.primary.main,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colours.primary.main,
                },
                arrow: {
                    color: colours.primary.main,
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl',
            },
        },
    },
    palette: {
        background: {
            default: colours.lightBackgroundGray,
            paper: colours.white.main,
        },
        action: {
            selected: colours.blackOpacity.O08,
        },
        primary: {
            light: colours.primary.light,
            main: colours.primary.main,
            dark: colours.primary.dark,
            contrastText: colours.grey.G50,
        },
        secondary: {
            light: colours.secondary.light,
            main: colours.secondary.main,
            dark: colours.secondary.dark,
            contrastText: colours.grey.G50,
        },
        tertiary: {
            light: colours.tertiary.main,
            main: colours.tertiary.main,
            dark: colours.tertiary.main,
            contrastText: colours.grey.G50,
        },
        success: {
            light: colours.paletteSuccessLight,
            main: colours.paletteSuccessMain,
            dark: colours.paletteSuccessDark,
            contrastText: colours.grey.G50,
        },
        error: {
            main: colours.error.main,
            contrastText: colours.grey.G50,
        },
        text: {
            primary: colours.black.O72,
            secondary: colours.grey.G700,
        },
        divider: colours.grey.G200,
    },
    typography: {
        fontFamily: "'Lato', sans-serif",
        h1: {
            fontSize: '2rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        h5: {
            fontSize: '1rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        h6: {
            fontSize: '0.875rem',
            fontWeight: 700,
            color: colours.primary.main,
        },
        subtitle1: {
            color: colours.grey.G600,
            lineHeight: '1.5',
        },
        subtitle2: {
            color: colours.grey.G600,
        },
    },
});

export default theme;
