import { useCurrentUserQuery } from 'src/hooks/__generated__/queries';
import useAuth from 'src/hooks/useAuth';
import useAppRouter from 'src/hooks/useAppRouter';
import useLoginModal from 'src/hooks/useLoginModal';
import { routePathSignUpProStep1, routePathSignUpStep1 } from 'src/constants/router';

const useUser = () => {
    const { auth } = useAuth();
    const router = useAppRouter();
    const { loginModalOpened } = useLoginModal();
    // We skip the query if we are on the login modal, the quick signup modal or if we are on a signup page
    // to not have unauthenticated error and get redirected to login page
    // it's important to use 'router.pathname' and not 'router.asPath' to ignore the url params
    const { data } = useCurrentUserQuery({
        skip:
            process.env.NODE_ENV !== 'test' &&
            (!auth ||
                [routePathSignUpStep1, routePathSignUpProStep1].includes(router.pathname) ||
                loginModalOpened ||
                router.query.siv === 'true'),
    });
    if (data?.me) {
        return data.me;
    }
    return undefined;
};

export default useUser;
