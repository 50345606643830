import React from 'react';

import Box from '@mui/material/Box';

import { useTranslation } from 'next-i18next';

import type { icons } from 'src/components/ui/Icon';
import Icon from 'src/components/ui/Icon';
import type { Props as ButtonProps } from 'src/components/ui/Button';
import Button from 'src/components/ui/Button';
import Typography from 'src/components/ui/Typography';

import type { AuthProvider } from 'src/hooks/useAuthActions';
import { styledComponent } from 'src/utils/styled';

export const LoginButtonsContainer = styledComponent(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-row-gap: 1rem;
    grid-column-gap: 1.25rem;
`;

interface LoginButtonProps extends ButtonProps {
    provider: AuthProvider;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ provider, ...rest }) => {
    const { t } = useTranslation(['common']);
    const upperCased = provider[0].toUpperCase() + provider.slice(1);
    return (
        <Button
            startIcon={<Icon name={upperCased as keyof typeof icons} />}
            data-target={`signup-${provider}`}
            data-target-id={`signup-${provider}`}
            variant="outlined"
            {...rest}
        >
            <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
                {t(`common:signIn.continue${upperCased}`)}
            </Typography>
        </Button>
    );
};
