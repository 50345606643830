import type React from 'react';
import type { StyledOptions } from '@emotion/styled';
import emotionStyled from '@emotion/styled';

type ComponentStyledOptions<C extends React.ComponentType<React.ComponentProps<C>>> = Omit<
    StyledOptions<React.ComponentProps<C>>,
    'shouldForwardProp'
>;
type TagStyledOptions<Tag extends keyof JSX.IntrinsicElements> = Omit<
    StyledOptions<JSX.IntrinsicElements[Tag]>,
    'shouldForwardProp'
>;

export function styledComponent<C extends React.ComponentType<React.ComponentProps<C>>>(
    component: C,
    options?: ComponentStyledOptions<C>,
) {
    return emotionStyled(component, {
        ...options,
        shouldForwardProp: (prop) => !prop.startsWith('$'),
    });
}

export function styledTag<Tag extends keyof JSX.IntrinsicElements>(tag: Tag, options?: TagStyledOptions<Tag>) {
    return emotionStyled(tag, {
        ...options,
        shouldForwardProp: (prop) => !prop.startsWith('$'),
    });
}
