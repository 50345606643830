import type { PropsWithChildren } from 'react';
import React from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import type { SxProps } from '@mui/system';

import type { LinkProps } from 'src/components/ui/Link';
import Link from 'src/components/ui/Link';
import theme from 'src/utils/theme';

import Button from 'src/components/ui/Button';
import Icon from 'src/components/ui/Icon';
import { styledComponent } from 'src/utils/styled';

const linkStyles = css`
    text-decoration: none;
    color: ${theme.palette.secondary.main};
    display: inline-flex;
    align-items: center;
`;

const StyledLink = styledComponent((props: LinkProps) => <Link {...props} />)`
    ${linkStyles}
`;

const LinkButton: SxProps = {
    fontWeight: '400!important',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    letterSpacing: 'inherit',
    padding: 0,
    cursor: 'pointer',
    '&.MuiButton-containedPrimary': {
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
};

export interface Props {
    href?: string;
    onClick?: (event: React.MouseEvent) => void;
}

const ArrowLink: React.FC<PropsWithChildren<Props>> = (props) => {
    const { children, href, onClick, ...rest } = props;

    if (href) {
        return (
            <StyledLink href={href} {...rest}>
                {children}
                <Box ml={0.5}>
                    <Icon size="1.25rem" name="ArrowRight" />
                </Box>
            </StyledLink>
        );
    }

    return (
        <Button sx={LinkButton} onClick={onClick} {...rest}>
            {children}
            <Box ml={0.5}>
                <Icon size="1.25rem" name="ArrowRight" />
            </Box>
        </Button>
    );
};

export default ArrowLink;
