import React from 'react';

import type { IconButtonProps } from '@mui/material/IconButton';
import MuiIconButton from '@mui/material/IconButton';

import type { Props as IconProps } from 'src/components/ui/Icon';
import Icon from 'src/components/ui/Icon';

import { colours } from 'src/constants/colours';
import theme from 'src/utils/theme';
import { styledComponent } from 'src/utils/styled';

interface StyledIconProps extends Omit<IconButtonProps, 'children'> {
    $white?: boolean;
}

const StyledIconButton = styledComponent(MuiIconButton)`
    /* color="primary" */
    &.MuiIconButton-colorPrimary {
        color: ${theme.palette.primary.contrastText};
        background-color: ${theme.palette.primary.main};
        &:hover {
            background-color: ${theme.palette.primary.light};
        }
        &:focus {
            background-color: ${theme.palette.primary.dark};
        }
    }

    /* color="secondary" */
    &.MuiIconButton-colorSecondary {
        color: ${theme.palette.secondary.contrastText};
        background-color: ${theme.palette.secondary.main};
        &:hover {
            background-color: ${theme.palette.secondary.light};
        }
        &:focus {
            background-color: ${theme.palette.secondary.dark};
        }
    }

    /* color="white" */
    ${(props: StyledIconProps) =>
        props.$white &&
        `
        color: ${theme.palette.primary.main};
        background-color: ${colours.grey.G50};
        &:hover {
            background-color: ${colours.grey.G100};
        }
        &:focus {
            background-color: ${colours.grey.G200};
        }
    `}

    /* default color (not white, not primary, not secondary) */
    ${(props: StyledIconProps) =>
        !props.$white &&
        `
        &:not(.MuiIconButton-colorPrimary):not(.MuiIconButton-colorSecondary) {
            color: ${theme.palette.primary.main};
            border: 0.0625rem solid ${theme.palette.primary.main};
        }
    `}
`;

export interface Props extends Omit<IconButtonProps, 'children' | 'color'> {
    iconProps: IconProps;
    color?: IconButtonProps['color'] | 'white';
    href?: string;
    target?: string;
}

const IconButton: React.FC<Props> = (props) => {
    const { iconProps, color, ...rest } = props;
    return (
        <StyledIconButton
            $white={color === 'white'}
            color={color !== 'white' ? color : 'default'}
            {...(rest as IconButtonProps)}
        >
            <Icon {...iconProps} />
        </StyledIconButton>
    );
};

export default IconButton;
