export const breakpoints = {
    desktop2310: 2310,
    desktopExtraLarge: 1980,
    desktopLarge: 1440,
    desktopSmall: 1024,
    tabletLandscape: 992,
    tabletPortrait: 768,
    mobile: 360,
    breakPoint2000: 2000,
    breakPoint1500: 1500,
    breakPoint1230: 1230,
    breakPoint1100: 1100,
    breakPoint881: 881,
    breakPoint800: 800,
    breakPoint500: 500,
    breakPoint450: 450,
};
