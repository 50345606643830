import React from 'react';

import type { TypographyProps } from '@mui/material/Typography';
import MuiTypography from '@mui/material/Typography';

import { colours } from 'src/constants/colours';
import { styledComponent } from 'src/utils/styled';

type WeightTypes = 'normal' | 'medium' | 'bold';

const weights: Record<WeightTypes, number> = {
    normal: 400,
    medium: 500,
    bold: 700,
};
interface StyledTypographyProps extends TypographyProps {
    $serif?: boolean;
    $weight?: WeightTypes;
    $dark?: boolean;
    $white?: boolean;
}

const StyledTypography = styledComponent(MuiTypography)`
    ${(props: StyledTypographyProps) =>
        props.$serif &&
        `
        font-family: 'Roboto Slab', serif;
    `}
    ${(props: StyledTypographyProps) =>
        props.$weight &&
        `
        font-weight: ${weights[props.$weight]};
    `}
    ${(props: StyledTypographyProps) =>
        props.$dark &&
        `
        color: ${colours.black.main};
    `}
    ${(props: StyledTypographyProps) =>
        props.$white &&
        `
        color: ${colours.white.main};
    `}
`;

export interface Props extends Omit<TypographyProps, 'color'> {
    serif?: StyledTypographyProps['$serif'];
    weight?: StyledTypographyProps['$weight'];
    color?: StyledTypographyProps['color'] | 'white' | 'dark';
}

const Typography: React.FC<Props> = (props) => {
    const { children, serif, weight, color, ...rest } = props;
    return (
        <StyledTypography
            $serif={serif}
            $weight={weight}
            color={!['white', 'dark'].includes(String(color)) ? (color as TypographyProps['color']) : 'primary'}
            $white={color === 'white'}
            $dark={color === 'dark'}
            {...rest}
        >
            {children}
        </StyledTypography>
    );
};

export default Typography;
