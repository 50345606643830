import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import type { IAuthContext } from 'src/contexts/AuthProvider';
import createApolloClient from 'src/utils/createApolloClient';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const getApolloClient = (
    initialState: NormalizedCacheObject | null = null,
    auth: IAuthContext['auth'] = undefined, // auth is may be defined only in getInitialProps in _app
) => {
    // If server-side or in JEST
    if (typeof window === 'undefined' || process.env.JEST_WORKER_ID !== undefined) {
        // Always create a new apollo client
        return createApolloClient(initialState, auth);
    }

    // Create a new apollo client if necessary
    if (!apolloClient) {
        apolloClient = createApolloClient(initialState);
    }

    return apolloClient;
};

export default getApolloClient;
