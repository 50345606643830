export const adsLimitForMap = 1000;

export const francePosition: { point: google.maps.LatLngLiteral; zoom: number } = {
    point: {
        lat: 46.5,
        lng: 3,
    },
    zoom: 6,
};
export const defaultZoom = 16;
export const inaccurateRadius = 5000;
export const inaccurateZoom = 12;
export const approximateZoom = 15;
export const isHiddenRadius = 500;

export const googleDefaultLibraries: Array<
    | 'core'
    | 'maps'
    | 'places'
    | 'geocoding'
    | 'routes'
    | 'marker'
    | 'geometry'
    | 'elevation'
    | 'streetView'
    | 'journeySharing'
    | 'drawing'
    | 'visualization'
> = ['places', 'maps', 'drawing', 'geometry'];
export const googleApiLoaderParams = {
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.NEXT_PUBLIC_GOOGLE_API_KEY),
    libraries: googleDefaultLibraries,
    version: '3',
    region: 'fr',
    language: 'fr',
};

export const frAndDomTomCountries = ['fr', 'gp', 'mq', 'gf', 're', 'pm', 'yt', 'nc', 'pf', 'mf', 'tf'];

export const circleOptions = {
    strokeColor: '#407cf2',
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: '#407CF2',
    fillOpacity: 0.2,
};
