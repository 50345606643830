/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { useFormikContext, setNestedObjectValues } from 'formik';

import type { ButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import type { CircularProgressProps } from '@mui/material/CircularProgress';

import { colours } from 'src/constants/colours';
import theme from 'src/utils/theme';
import useAppRouter from 'src/hooks/useAppRouter';
import Loader from 'src/components/ui/Loader';
import { styledComponent } from 'src/utils/styled';

interface StyledButtonProps extends Omit<ButtonProps, 'color'> {
    $white?: boolean;
    $error?: boolean;
    $shrink?: boolean;
    $loading?: boolean;
}

const StyledButton = styledComponent(MuiButton)<StyledButtonProps>`
    font-size: 0.9375rem;
    font-weight: 700;

    /* Remove MUI default uppercase transform */
    text-transform: none;

    /* Default color */
    &.MuiButton-contained:not(.MuiButton-containedPrimary):not(.MuiButton-containedSecondary) {
        color: ${theme.palette.primary.main};
    }

    /* color="primary" */
    &.MuiButton-containedPrimary {
        /* Invert hover and focus styles */
        &:hover {
            background-color: ${theme.palette.primary.light};
        }
        &:focus {
            background-color: ${theme.palette.primary.dark};
        }
        &:disabled {
            color: ${({ $loading }) => ($loading ? 'transparent' : colours.linkGrey)}
        }
    }

    /* color="secondary" */
    &.MuiButton-containedSecondary {
        /* Invert hover and focus styles */
        &:hover {
            background-color: ${theme.palette.secondary.light};
        }
        &:focus {
            background-color: ${theme.palette.secondary.dark};
        }
        &:disabled {
            color: ${({ $loading }) => ($loading ? 'transparent' : colours.linkGrey)}
        }
    }

    ${({ $white }) =>
        $white &&
        `
        color: ${theme.palette.primary.main};
        background-color: ${colours.grey.G50};
        &:hover {
            background-color: ${colours.grey.G100};
        }
    `}
    
    ${({ $error }) =>
        $error &&
        `
        color: ${theme.palette.error.main};
        background-color: ${colours.grey.G50};
        &:hover {
            background-color: ${colours.grey.G100};
        }
    `}

    /* Custom variant="outlined" styling */
    &.MuiButton-outlined {
        ${({ $error }) =>
            $error
                ? `border: 0.0625rem solid ${theme.palette.error.main};`
                : `border: 0.0625rem solid ${theme.palette.primary.main};`}
        &:disabled {
            color: ${({ $loading }) => ($loading ? 'transparent' : colours.grey.G400)};
            border-color: ${colours.grey.G400};
        }
        ${({ $shrink }) => $shrink && `padding: 0.313rem;`}
    ${({ $shrink }) =>
        $shrink
            ? `
        &.MuiButton-root {
            min-width: 0;
        }
        `
            : ''}
`;

StyledButton.defaultProps = {
    color: 'primary',
    disableElevation: true,
    variant: 'contained',
};

export interface Props extends Omit<ButtonProps, 'color' | 'variant'> {
    color?: ButtonProps['color'] | 'white' | 'error';
    target?: string;
    fakeDisabled?: boolean;
    shrink?: boolean;
    variant?: ButtonProps['variant'] | 'link';
    loading?: boolean;
    loaderColor?: CircularProgressProps['color'];
}

const ButtonWithFakeDisable = React.forwardRef((props: PropsWithChildren<Props>, ref: React.Ref<HTMLButtonElement>) => {
    const { children, color, loading, loaderColor, ...rest } = props;
    const formikContext = useFormikContext<Record<string, boolean>>();

    const handleFormikValidateAndTouch = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const errors = await formikContext?.validateForm();
        formikContext?.setTouched(setNestedObjectValues(errors, true));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        rest.onClick?.(event as any);
    };
    useEffect(() => {
        const validateForm = async () => {
            await formikContext?.validateForm();
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box onClick={handleFormikValidateAndTouch} width={rest.fullWidth ? '100%' : 'fit-content'}>
            <StyledButton
                {...(rest as ButtonProps)}
                ref={ref}
                color={!['white', 'error'].includes(String(color)) ? (color as ButtonProps['color']) : 'inherit'}
                $white={color === 'white'}
                $error={color === 'error'}
                $loading={loading}
            >
                {loading && <Loader color={loaderColor} padding="0" size={20} absolute />}
                {children}
            </StyledButton>
        </Box>
    );
});

const Button = React.forwardRef((props: PropsWithChildren<Props>, ref: React.Ref<HTMLButtonElement>) => {
    const { children, fakeDisabled, color, shrink, loading, ...rest } = props;
    const router = useAppRouter();
    // If button is a link
    if (rest.href?.startsWith('/')) {
        rest.onClick = async (ev: React.MouseEvent) => {
            ev.preventDefault();
            await router.push(String(rest.href));
        };
    }

    if (fakeDisabled) {
        // added another component to avoid call useFormikContext outside of a form
        return (
            <ButtonWithFakeDisable {...(rest as Props)} ref={ref} loading={loading} color={color}>
                {children}
            </ButtonWithFakeDisable>
        );
    }

    const linkStyle = {
        textDecoration: 'underline',
        padding: 0,
        fontWeight: 'normal',
        fontSize: '1rem',
        background: 'none',
    };
    return (
        <StyledButton
            {...(rest as ButtonProps)}
            ref={ref}
            color={!['white', 'error'].includes(String(color)) ? (color as ButtonProps['color']) : 'inherit'}
            $loading={loading}
            $white={color === 'white'}
            $error={color === 'error'}
            $shrink={shrink}
            style={rest?.variant === 'link' ? linkStyle : rest.style}
        >
            {loading && <Loader color={rest.loaderColor} padding="0 0.5rem 0 0" size={20} absolute />}
            {children}
        </StyledButton>
    );
});

export default Button;
