import type { FormikHelpers, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import useAlerts from 'src/hooks/useAlerts';
import useLoginModal from 'src/hooks/useLoginModal';
import useUser from 'src/hooks/useUser';

import { AnalyticsEvent } from 'src/utils/analytics';
import { logEvent } from 'src/utils/firebase';
import { isMessageWithMentionsLengthValid } from 'src/utils/mentionsUtils';

import { commonMultilineMaxLength } from 'src/constants/misc';
import { notBlankSpaceRegEx } from 'src/constants/regexes';

import { useCreatePostMutation } from 'src/hooks/__generated__/queries';
import type { Ad, HybridMedium, Medium, NewsPost, Project } from 'src/types/__generated__/graphql';

const ChooseOwnerModal = dynamic(() => import('src/components/news/ChooseOwnerModal'), { ssr: false });
const CreatePostModal = dynamic(() => import('src/components/news/posts/CreatePostModal'), { ssr: false });

type CustomMedium = Medium & { file?: File; src?: string };

export type FormValues = {
    message?: string;
    media: CustomMedium[];
    userId?: string;
    companyId?: string;
};

export interface OpenOptions {
    mode?: 'image' | 'video';
    adId?: Ad['id'];
    newsPostId?: NewsPost['id'];
    projectId?: Project['id'];
}

interface CreatePostModalContextValue {
    isOpen: boolean;
    close: () => void;
    open: (options?: OpenOptions) => void;
}

export const CreatePostModalContext = createContext({} as CreatePostModalContextValue);

const CreatePostModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation(['common', 'news']);
    const currentUser = useUser();
    const { addAlert } = useAlerts();
    const { createAuthenticatedHandler } = useLoginModal();

    const [isOpen, setIsOpen] = useState(false);
    const [mediaWindowToOpenAfterOwnerChosed, setMediaWindowToOpenAfterOwnerChosed] = useState<OpenOptions['mode']>();

    const [openOwnerModal, setOpenOwnerModal] = useState(false);
    const [openCreatePostModal, setOpenCreatePostModal] = useState(false);

    const [selectedAdId, setSelectedAdId] = useState<Ad['id']>();
    const [selectedNewsPostId, setSelectedNewsPostId] = useState<NewsPost['id']>();
    const [selectedProjectId, setSelectedProjectId] = useState<Project['id']>();

    const imageButtonRef = useRef<HTMLButtonElement>(null);
    const videoButtonRef = useRef<HTMLButtonElement>(null);

    const [createPostMutation] = useCreatePostMutation();

    const openMediaWindow = (mode: OpenOptions['mode']) => {
        setTimeout(() => {
            if (mode === 'image') {
                imageButtonRef.current?.click();
            }
            if (mode === 'video') {
                videoButtonRef.current?.click();
            }
        });
    };

    const open = useCallback(
        (options?: OpenOptions) => {
            if (!isOpen) {
                setIsOpen(true);
                setSelectedAdId(options?.adId);
                setSelectedNewsPostId(options?.newsPostId);
                setSelectedProjectId(options?.projectId);

                if (currentUser?.affiliatedCompany && currentUser.isAdminOfAffiliatedCompany) {
                    setOpenOwnerModal(true);
                    setMediaWindowToOpenAfterOwnerChosed(options?.mode);
                } else {
                    setOpenCreatePostModal(true);
                    if (options?.mode) {
                        openMediaWindow(options.mode);
                    }
                }
            }
        },
        [currentUser?.affiliatedCompany, currentUser?.isAdminOfAffiliatedCompany, isOpen],
    );

    const resetLinkedEntities = useCallback(() => {
        setSelectedAdId(undefined);
        setSelectedNewsPostId(undefined);
        setSelectedProjectId(undefined);
    }, []);

    const setLinkedEntity = useCallback((entity: 'ad' | 'newsPost' | 'project', id: string) => {
        switch (entity) {
            case 'ad':
                setSelectedAdId(id);
                break;
            case 'newsPost':
                setSelectedNewsPostId(id);
                break;
            case 'project':
                setSelectedProjectId(id);
                break;
            default:
                break;
        }
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
        setOpenOwnerModal(false);
        setOpenCreatePostModal(false);
        resetLinkedEntities();
        setMediaWindowToOpenAfterOwnerChosed(undefined);
    }, [resetLinkedEntities]);

    const schema = Yup.object().shape(
        {
            companyId: Yup.string().nullable(),
            media: Yup.array()
                .when('message', {
                    is: (message: string | undefined | null) => message === '',
                    then: () => Yup.array().required(t('common:errors:atLeastOneProperty')),
                })
                .compact((image: HybridMedium) => !image.file && !image.url),
            message: Yup.string()
                .matches(notBlankSpaceRegEx, t('common:errors:atLeastOneProperty'))
                .nullable()
                .when('media', {
                    is: (media: CustomMedium[]) => (media as unknown[]).length === 0,
                    then: () => Yup.string().required(t('common:errors:atLeastOneProperty')),
                })
                .test({
                    name: 'maxLength',
                    message: t('common:errors.tooLong', { number: commonMultilineMaxLength }),
                    test: (message) => isMessageWithMentionsLengthValid(message, commonMultilineMaxLength),
                }),
            userId: Yup.string().nullable(),
        },
        [['media', 'message']],
    );

    const handleForm = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
            await createPostMutation({
                variables: {
                    message: values.message ?? '',
                    media: values.media.map(({ file }) => file),
                    userId: values.userId ?? undefined,
                    companyId: values.companyId ?? undefined,
                    newsPostId: selectedNewsPostId as string,
                    adId: selectedAdId as string,
                    projectId: selectedProjectId as Project['id'],
                },
                refetchQueries: ['Posts', 'NewsFeed'],
                awaitRefetchQueries: true,
            });
            resetForm();
            close();
            logEvent(AnalyticsEvent.CreatePost, {
                POST_LENGTH: values.message?.length ?? 0,
                NUMBER_OF_PHOTOS: values.media.filter(({ file }) => file?.type.includes('image')).length,
                NUMBER_OF_VIDEOS: values.media.filter(({ file }) => file?.type.includes('video')).length,
            });
            addAlert({
                type: 'success',
                message: t('news:postSent'),
            });
        } catch (error: unknown) {
            addAlert({
                type: 'error',
                message: t('news:postError'),
            });
        }
    };

    const value: CreatePostModalContextValue = useMemo(
        () => ({
            isOpen,
            close,
            open,
        }),
        [isOpen, close, open],
    );

    return (
        <CreatePostModalContext.Provider value={value}>
            {children}
            {isOpen && (
                <Formik
                    onSubmit={(props, helpers) => createAuthenticatedHandler(() => handleForm(props, helpers))}
                    validationSchema={schema}
                    initialValues={{
                        message: '',
                        media: [] as CustomMedium[],
                        userId: currentUser?.id,
                    }}
                >
                    {(formikProps: FormikProps<FormValues>) => (
                        <Form>
                            {openOwnerModal && (
                                <ChooseOwnerModal
                                    contentType="post"
                                    openOwnerModal={openOwnerModal}
                                    onClose={(confirm) => {
                                        setOpenOwnerModal(false);
                                        if (!confirm) {
                                            close();
                                        }
                                    }}
                                    onOwnerChosen={() => {
                                        setOpenCreatePostModal(true);
                                        if (mediaWindowToOpenAfterOwnerChosed) {
                                            openMediaWindow(mediaWindowToOpenAfterOwnerChosed);
                                        }
                                    }}
                                />
                            )}
                            <CreatePostModal
                                open={openCreatePostModal}
                                onClose={() => {
                                    formikProps.resetForm();
                                    close();
                                }}
                                formikProps={formikProps}
                                imageButtonRef={imageButtonRef}
                                videoButtonRef={videoButtonRef}
                                selectedAdId={selectedAdId}
                                selectedNewsPostId={selectedNewsPostId}
                                selectedProjectId={selectedProjectId}
                                setLinkedEntity={setLinkedEntity}
                                resetLinkedEntities={resetLinkedEntities}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </CreatePostModalContext.Provider>
    );
};

export default CreatePostModalProvider;
