import type { PropsWithChildren } from 'react';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';

import type { SnackbarCloseReason } from '@mui/material/Snackbar';

import AlertSnackBar from 'src/components/ui/AlertSnackbar';

export const autoHideDuration = 6000;

export const AlertsContext = createContext({} as IAlertsContext);

/**
 * Trigger alerts from outside react
 * Sometimes, the `useAlert` hook is not available. We can use this instead.
 */

export const dispatchAlert = (newAlert: Omit<IAlert, 'uid'>) => {
    if (typeof window !== 'undefined') {
        const event = new CustomEvent('alertmessage', { detail: newAlert });
        window.dispatchEvent(event);
    }
};

const AlertsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [alerts, setAlerts] = useState<IAlert[]>([]);

    const removeAlert = (uid: string) => {
        setAlerts((previousAlerts) =>
            previousAlerts.map((alert) =>
                alert.uid === uid
                    ? {
                          ...alert,
                          hidden: true,
                      }
                    : alert,
            ),
        );
        setTimeout(() => {
            setAlerts((previousAlerts) => previousAlerts.filter((alert) => !alert.hidden));
        }, 500);
    };
    const addAlert = (newAlert: Omit<IAlert, 'uid'>) => {
        const uid = nanoid();
        setAlerts((previousAlerts) =>
            previousAlerts
                .map((alert) => ({
                    ...alert,
                    hidden: true,
                }))
                .concat({
                    ...newAlert,
                    uid,
                    hidden: false,
                }),
        );
    };

    const handleCloseAlert = (uid: string) => (event: unknown, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        removeAlert(uid);
    };

    // Handle external alerts from dispatchAlert
    useEffect(() => {
        const handleDispatchedAlert = (ev: CustomEvent<Omit<IAlert, 'uid'>>) => {
            addAlert(ev.detail);
        };
        window.addEventListener('alertmessage', handleDispatchedAlert as EventListener);
        return () => {
            window.removeEventListener('alertmessage', handleDispatchedAlert as EventListener);
        };
    }, []);

    const alertProviderValue = useMemo(
        () => ({
            alerts,
            addAlert,
        }),
        [alerts],
    );
    return (
        <AlertsContext.Provider value={alertProviderValue}>
            {children}
            {alerts.map((alert) => (
                <AlertSnackBar
                    key={alert.uid}
                    uid={alert.uid}
                    onClose={handleCloseAlert}
                    autoHideDuration={autoHideDuration}
                    open={!alert.hidden}
                    iconName={alert.iconName}
                    iconSize={alert.iconSize}
                    severity={alert.type}
                    title={alert.title}
                    message={alert.message}
                />
            ))}
        </AlertsContext.Provider>
    );
};

export default AlertsProvider;
