import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PauseArrowIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import TruncateMarkup from 'react-truncate-markup';

import { colours } from 'src/constants/colours';
import { timeSliderSteps } from 'src/contexts/AudioProvider';

import Icon from 'src/components/ui/Icon';
import { styledComponent } from 'src/utils/styled';

const CloseIconButton = styledComponent(IconButton)<{ $isMinified: boolean }>`
    position: absolute;
    transition: right 0.2s ease-out, top 0.2s ease-out;
    padding: 0.2rem;
    background-color: ${colours.white.main};
    ${({ $isMinified }) =>
        $isMinified
            ? `
        right: -0.5rem;
        top: -0.5rem;
    `
            : `
        right: 0.3rem;
        top: 0.3rem;
    `}
`;

const StyledCard = styledComponent(Card)<{ $isMinified: boolean }>`
    display: flex;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;
    align-items: center;
    border-radius: 0.7rem;
    overflow: visible;
    transition: padding 0.2s ease-out;
    ${({ $isMinified }) => `padding: ${$isMinified ? '0.7rem 0.7rem 0.6rem 0.2rem' : '1rem'};`}
`;

const ContentContainer = styledComponent(Box)<{ $isMinified: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: margin 0.2s ease-out;
    ${({ $isMinified }) => `margin: ${$isMinified ? '0rem' : '0.2rem 0rem'};`}
`;

const ContentLineContainer = styledComponent(Box)<{ $isMinified: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    transition: margin-top 0.2s ease-out;
    ${({ $isMinified }) => `margin-top: ${$isMinified ? '0rem' : '0.6rem'};`}
`;

const PlayPauseIconButton = styledComponent(IconButton)`
    padding: 0.1rem;
`;

const StyledTimelineContainer = styledComponent(Box)`
    display: flex;
    min-width: 13rem;
    margin: 0 0.5rem;

    .MuiSlider-root {
        padding-top: 0.8rem;
        padding-bottom: 0.5rem;

        .MuiSlider-rail,
        .MuiSlider-track {
            height: 0.125rem;
        }

        .MuiSlider-thumb {
            width: 0.75rem;
            height: 0.75rem;

            &.Mui-focusVisible,
            &:hover {
                box-shadow: 0 0 0 0.25rem rgb(19 46 72 / 16%);
            }

            &.Mui-active {
                box-shadow: 0 0 0 0.5rem rgb(19 46 72 / 16%);
            }
        }
    }
`;

const TitleContainer = styledComponent(Box)<{ $isMinified: boolean }>`
    overflow: hidden;
    transition: max-height 0.2s ease-out, width 0.2s ease-out;
    ${({ $isMinified }) =>
        $isMinified
            ? `
        max-height: 0rem;
        width: 0rem;
    `
            : `
        max-height: 3rem;
        width: 17rem;
    `}
`;

const TimeContainer = styledComponent(Box)<{ $isMinified: boolean }>`
    display: flex;
    overflow: hidden;
    transition: width 0.2s ease-out;
    ${({ $isMinified }) => `width: ${$isMinified ? '0rem' : '17rem'};`}
`;

const formatSeconds = (secondsToFormat: number): string => {
    if (secondsToFormat > 3600) {
        return new Date(secondsToFormat * 1000).toISOString().slice(11, 19);
    } else {
        return new Date(secondsToFormat * 1000).toISOString().slice(14, 19);
    }
};

interface Props {
    title: string;
    isPaused: boolean;
    onPlay: () => unknown;
    onPause: () => unknown;
    onClose: () => unknown;
    timeSliderValue: number;
    onTimeSliderChange: (value: number) => unknown;
    onTimeSliderChangeCommited: (value: number) => unknown;
    currentTime?: number;
}

const AudioCard: React.FC<Props> = ({
    title,
    isPaused,
    onPlay,
    onPause,
    onClose,
    timeSliderValue,
    onTimeSliderChange,
    onTimeSliderChangeCommited,
    currentTime,
}) => {
    const [isMinified, setIsMinified] = useState(false);

    return (
        <StyledCard $isMinified={isMinified}>
            <CloseIconButton $isMinified={isMinified} onClick={onClose}>
                <CloseIcon sx={{ height: 20, width: 20 }} />
            </CloseIconButton>
            <Box marginX="0.1rem">
                <IconButton onClick={() => setIsMinified(!isMinified)}>
                    <Icon
                        name={isMinified ? 'ChevronLeft' : 'ChevronRight'}
                        size="0.65rem"
                        color="primary"
                        fontWeight="bold"
                    />
                </IconButton>
            </Box>
            <ContentContainer $isMinified={isMinified}>
                <TitleContainer $isMinified={isMinified}>
                    <TruncateMarkup lines={2}>
                        <Typography marginLeft="0.4rem" variant="h5">
                            {title}
                        </Typography>
                    </TruncateMarkup>
                </TitleContainer>
                <ContentLineContainer $isMinified={isMinified}>
                    <PlayPauseIconButton
                        aria-label={isPaused ? 'play' : 'pause'}
                        onClick={isPaused ? onPlay : onPause}
                        color="primary"
                    >
                        {isPaused ? <PlayArrowIcon fontSize="medium" /> : <PauseArrowIcon fontSize="medium" />}
                    </PlayPauseIconButton>
                    <TimeContainer $isMinified={isMinified}>
                        <StyledTimelineContainer>
                            <Slider
                                onChangeCommitted={(_, value) => onTimeSliderChangeCommited(value as number)}
                                onChange={(_, value) => onTimeSliderChange(value as number)}
                                value={timeSliderValue}
                                max={timeSliderSteps}
                            />
                        </StyledTimelineContainer>
                        <Typography variant="body2" fontSize="0.85rem" marginLeft="0.5rem">
                            {currentTime === undefined ? '00:00' : formatSeconds(Math.round(currentTime))}
                        </Typography>
                    </TimeContainer>
                </ContentLineContainer>
            </ContentContainer>
        </StyledCard>
    );
};

export default AudioCard;
