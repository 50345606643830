import { useTranslation } from 'next-i18next';

// The list of all the firebase authentication errors: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
const errorsToMute = ['auth/popup-closed-by-user', 'auth/cancelled-popup-request'];

const errorsWithCustomMessage = {
    'auth/email-already-in-use': 'firebaseErrors.emailAlreadyInUse',
    'auth/wrong-password': 'firebaseErrors.incorrectUsernameOrPassword',
    'auth/user-not-found': 'firebaseErrors.incorrectUsernameOrPassword',
};

const useFormatFirebaseError = () => {
    const { t } = useTranslation(['common']);

    /**
     * Format a firebase error. Returns a formated error or undefined if the error has to be muted.
     * @param error The firebase error message.
     */
    const formatFirebaseError = (error: string): string | undefined => {
        if (errorsToMute.some((e) => error.includes(e))) {
            return undefined;
        }

        let formatedError = error;
        if (Object.keys(errorsWithCustomMessage).some((e) => error.includes(e))) {
            Object.entries(errorsWithCustomMessage).forEach(([key, value]) => {
                if (error.includes(key)) {
                    formatedError = t(`common:${value}`);
                }
            });
        }

        return formatedError;
    };

    return {
        formatFirebaseError,
    };
};

export default useFormatFirebaseError;
