import type { PropsWithChildren } from 'react';
import React, { createContext, useRef, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import type { FetchResult, MutationFunctionOptions } from '@apollo/client';

import { useAddEventsMutation } from 'src/hooks/__generated__/queries';
import type { AddEventsMutation, AddEventsMutationVariables, EventData } from 'src/types/__generated__/graphql';

export interface IEventsContext {
    trackEvent: (event: EventData) => void;
}
export const EventsContext = createContext<IEventsContext>({} as IEventsContext);

const isEventDataEqual = (eventData1: EventData, eventData2: EventData) => {
    if (eventData1.eventType !== eventData2.eventType) {
        return false;
    }

    const notNullAndEqual = (a: string | undefined | null, b: string | undefined | null) => Boolean(a) && a === b;

    return (
        notNullAndEqual(eventData1.quickSignupUserId, eventData2.quickSignupUserId) ||
        notNullAndEqual(eventData1.companyId, eventData2.companyId) ||
        notNullAndEqual(eventData1.quickSignupCompanyId, eventData2.quickSignupCompanyId) ||
        notNullAndEqual(eventData1.pagesJaunesMerchantId, eventData2.pagesJaunesMerchantId) ||
        notNullAndEqual(eventData1.viewedUserId, eventData2.viewedUserId)
    );
};

export type AddEventsMutationFunctionType = (
    options?: MutationFunctionOptions<AddEventsMutation, AddEventsMutationVariables>,
) => Promise<FetchResult<AddEventsMutation>>;

interface IProps {
    debounceDurationMillis?: number;
    addEventsMutationProp?: AddEventsMutationFunctionType;
}

const shouldLog = process.env.NODE_ENV !== 'production';
const defaultDebounceDurationMillis = 3000;

const EventsProvider: React.FC<PropsWithChildren<IProps>> = ({
    children,
    debounceDurationMillis = defaultDebounceDurationMillis,
    addEventsMutationProp,
}) => {
    const allEvents = useRef<EventData[]>([]);
    const eventsToSend = useRef<EventData[]>([]);
    const [addEventsMutationHook] = useAddEventsMutation();
    const addEventsMutation = addEventsMutationProp ?? addEventsMutationHook;

    const sendEvents = () => {
        if (eventsToSend.current.length === 0) {
            if (shouldLog) {
                console.info('EventsProvider: Skipping sendEvents as there are no events');
            }

            return;
        }

        const events = [...eventsToSend.current];
        eventsToSend.current = [];

        addEventsMutation({
            variables: { events },
        })
            .then((_response) => {
                if (shouldLog) {
                    console.info('EventsProvider: Success sending events', events);
                }
            })
            .catch((e) => {
                if (shouldLog) {
                    console.error('EventsProvider: Error sending events', e, events);
                }
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendEventsDebounced = useCallback(debounce(sendEvents, debounceDurationMillis), []);

    const trackEvent = useCallback(
        (event: EventData) => {
            if (!allEvents.current.some((e) => isEventDataEqual(e, event))) {
                if (shouldLog) {
                    console.info('EventsProvider: Added event to send', event);
                }
                allEvents.current.push(event);
                eventsToSend.current.push(event);
                sendEventsDebounced();
            } else if (shouldLog) {
                console.info('EventsProvider: Skipping adding event to send', event);
            }
        },
        [sendEventsDebounced],
    );

    const eventsProviderValue = useMemo(
        () => ({
            trackEvent,
        }),
        [trackEvent],
    );

    return <EventsContext.Provider value={eventsProviderValue}>{children}</EventsContext.Provider>;
};

export default EventsProvider;
