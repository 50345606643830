import type { PropsWithChildren } from 'react';
import React from 'react';

import AudioProvider from 'src/contexts/AudioProvider';
import CreatePostModalProvider from 'src/contexts/CreatePostModalProvider';
import LoginModalProvider from 'src/contexts/LoginModalProvider';

const GlobalModals: React.FC<PropsWithChildren> = ({ children }) => (
    <LoginModalProvider>
        <CreatePostModalProvider>
            <AudioProvider>{children}</AudioProvider>
        </CreatePostModalProvider>
    </LoginModalProvider>
);

export default GlobalModals;
