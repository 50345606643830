import { useEffect } from 'react';
import type { Router } from 'next/router';

import { GTMPageView } from 'src/utils/gtm';

const useGTMTrackViews = (router: Router) => {
    useEffect(() => {
        const handleRouteChange = (url: string) => GTMPageView(url);
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
};

export default useGTMTrackViews;
