import React from 'react';

import type { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialog from '@mui/material/Dialog';

import { styledComponent } from 'src/utils/styled';
import { breakpoints } from 'src/constants/breakpoints';

const StyledDialog = styledComponent(MuiDialog)<{ $fullscreenOnMobile?: boolean }>`
    .MuiDialog-paper {
        border-radius: 0.125rem;
        ${({ $fullscreenOnMobile }) =>
            $fullscreenOnMobile &&
            `
            @media screen and (max-width: ${breakpoints.tabletPortrait - 1}px) {
                width: 100% !important;
                height: 100% !important;
                margin: 0;
                max-height: none;
            }
        `}
    }
`;

StyledDialog.defaultProps = {
    PaperProps: {
        elevation: 0,
    },
};

interface Props extends MuiDialogProps {
    fullscreenOnMobile?: boolean;
}

const Dialog: React.FC<Props> = ({ fullscreenOnMobile, ...rest }) => (
    <StyledDialog {...rest} $fullscreenOnMobile={fullscreenOnMobile} />
);

export default Dialog;
