import type { DisallowedTagsModes, IOptions, Attributes, Tag } from 'sanitize-html';
import sanitizeHtml, { Transformer } from 'sanitize-html';

// Note: if we need other sanitize features that are not available in sanitize-html , we could use https://www.npmjs.com/package/dompurify

// Custom options: default options with a few changes
// see https://github.com/apostrophecms/sanitize-html#default-options
const customOptions: IOptions = {
    // only allow tags like 'h1', 'li', 'table', 'span', ...
    allowedTags: sanitizeHtml.defaults.allowedTags,
    // remove all the other tags
    disallowedTagsMode: 'escape' as DisallowedTagsModes,
    // only allow attributes of 'a' tag
    allowedAttributes: {
        a: [...sanitizeHtml.defaults.allowedAttributes.a, 'key', 'rel'],
    },
    // allow all self closing tags like 'br' or 'hr', but not 'img' or 'input'
    selfClosing: sanitizeHtml.defaults.selfClosing.filter((x) => x !== 'img' && x !== 'input'),
    // only allow schemes 'http' and 'https', not 'mailto' or 'tel' or 'ftp'
    allowedSchemes: sanitizeHtml.defaults.allowedSchemes.filter((x) => x === 'http' || x === 'https'),
};

// Options when we want to remove all the tags (and replace the 'br' tag with a space)
// see https://github.com/apostrophecms/sanitize-html#what-if-i-dont-want-to-allow-any-tags
// and https://github.com/apostrophecms/sanitize-html#transformations
const optionsToRemoveAllTagsExceptBr: IOptions = {
    allowedTags: ['br'],
    allowedAttributes: {},
    transformTags: {
        // to replace all 'br' tags with a space, first transform the 'br' tag to a 'br' tag (to normalize the tag, so that there's only one value to replace),
        // then after the sanitize call, do a replaceAll to replace the 'br' tag with a space character.
        br(tagName: string, attribs: Attributes) {
            return {
                tagName: 'br',
            } as Tag;
        },
    },
};

/**
 * Return the string without all the HTML tags.
 *
 * Replaces the `br` tag with a space character, and replaces the `&amp;` string with a `&` character.
 */
export const removeAllHTMLTags = (htmlContent: string | null | undefined): string => {
    if (!htmlContent) return '';

    return (
        sanitizeHtml(htmlContent, optionsToRemoveAllTagsExceptBr)
            // 'br' tag was normalized by the transform function, so we only have one value to replace
            .replaceAll('<br />', ' ')
            // '&amp;' values were not removed by the sanitize call
            .replaceAll('&amp;', '&')
    );
};

/**
 * Return the string without insecure HTML tags, but keep secure HTML tags (like `br` or `h1`)
 */
export const sanitizeHTMLContent = (htmlContent: string | null | undefined): string =>
    htmlContent ? sanitizeHtml(htmlContent, customOptions) : '';

/**
 * Replaces '<' characters inside tags with '&lt;' in the given text when a '<' character isn't followed by a corresponding closing tag
 */
export const parseUnclosedLtChars = (text: string): string => {
    const textAsArray: string[] = [...text];
    const ltCharIndexes: number[] = [];
    textAsArray.forEach((c: string, i: number) => {
        if (c === '<') {
            ltCharIndexes.push(i);
        } else if (c === '>') {
            ltCharIndexes.pop();
        }
    });
    let parsedText = '';
    textAsArray.forEach((c: string, i: number) => {
        if (ltCharIndexes.includes(i)) {
            parsedText += '&lt;';
        } else {
            parsedText += c;
        }
    });
    return parsedText;
};
