import type { PropsWithChildren } from 'react';
import React from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';

import useAuth from 'src/hooks/useAuth';
import getApolloClient from 'src/utils/getApolloClient';

interface UniversalProps {
    initialApolloState?: NormalizedCacheObject;
    ssrApolloClient?: ApolloClient<NormalizedCacheObject>;
    mock?: boolean;
}

/**
 * Provide Apollo client to app.
 * Will be used by generated useQuery hooks
 */
const UniversalApolloProvider: React.FC<PropsWithChildren<UniversalProps>> = ({
    initialApolloState,
    ssrApolloClient,
    children,
    mock,
}) => {
    if (mock) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
        return children as any;
    }

    // If specified, uses given apollo client to populate the state
    if (ssrApolloClient) {
        return <ApolloProvider client={ssrApolloClient}>{children}</ApolloProvider>;
    }

    // Use the given initialApolloState to create a pre-populated apollo client
    const apolloClient = getApolloClient(initialApolloState);
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default UniversalApolloProvider;
