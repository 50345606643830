import type { PropsWithChildren } from 'react';
import type React from 'react';
import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { useAddEventMutation } from 'src/hooks/__generated__/queries';

import { CurrentUser } from 'src/types/__generated__/graphql';

import useAuth from 'src/hooks/useAuth';

const ConnectionEvent: React.FC<PropsWithChildren> = ({ children }) => {
    const { auth } = useAuth();
    const client = useApolloClient();
    const [addEventMutation] = useAddEventMutation();

    useEffect(() => {
        if (auth?.token && process.env.NODE_ENV !== 'test') {
            const sendConnectionEvent = async () => {
                const { data } = await client.query<CurrentUserQuery>({
                    query: CurrentUser,
                });

                addEventMutation({
                    variables: {
                        userId: data.me.id,
                        eventType: 'connection',
                        platform: 'web',
                    },
                }).catch(console.error);
            };
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            sendConnectionEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children as React.ReactElement;
};

export default ConnectionEvent;
