export const totalCreateAdSteps = 10;
export const maxAdImagesCount = 30;
export const maxProjectImagesCount = 12;
export const maxShowcaseImagesCount = 25;

export const headerHeight = '4rem';
export const maxFooterHeight = '10rem';

export const commonMultilineMaxLength = 2000;
export const companyDescriptionMaxLength = 5000;
export const companyCustomActivityMaxLength = 100;
export const userCustomActivityMaxLength = 100;
export const companyMissionMaxLength = 140;

export const mobileViewportMaxWidth = 768;

export const minimumNumberOfNewsCategories = 3;

export const maxNumberOfStoriesInAStory = 100;

export const storyDefaultDuration = 5000; // sec

export const maxSecondaryEmail = 10;

export const sitemapPageSize = 500;

export const maxNotifications = 99;

export const cardHeightInRemForPosts = '30rem';

export const cardHeightInRemForGalleries = '16rem';

export const cardHeightInRemForProjects = '17rem';

export const cardHeightInRemForAds = '24rem';

export const cardHeightInRemForCommunityCard = '20rem';

export const cardHeightInRemForJobOffer = '15rem';

export const disablePiwikUrls = ['/melo-short-image', '/melo-long-image'];

export const ratioCover = 3;

export const paddingAroundCoverInPx = 3 * 16;

export const ratioProfilePicture = 1;

export const conversationNameCharactersLimit = 25;
