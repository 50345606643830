/* eslint-disable @typescript-eslint/no-floating-promises */
import type { NormalizedCacheObject, RequestHandler } from '@apollo/client';
import { ApolloClient, ApolloLink, InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import Router from 'next/router';

import { i18n } from 'next-i18next';

import isNil from 'lodash/isNil';

import { dispatchAlert } from 'src/contexts/AlertsProvider';
import { getAuthCookie } from 'src/utils/authCookie';
import { auth as firebaseAuth } from 'src/utils/firebase';
import type {
    AdminRequestsQuery,
    AdsSearch,
    AgentsQuery,
    CompanyMembersWithTotalQuery,
    GroupCompaniesQuery,
    JobOffersQuery,
    MembersAdsQuery,
    MyPendingCompanyMembershipInvitationsQuery,
    NewsFeedQuery,
    PostsQuery,
    RecommendingUsersQuery,
    UserAdsQuery,
    SearchCompaniesForInvitationsQuery,
    SearchAllUsersWithTotalQuery,
    SearchCommunityQuery,
    UserContactsQuery,
    FollowingQuery,
    FollowersQuery,
    SavedSearchesQuery,
    AdPublishersQuery,
    GalleryPage,
    UserGalleriesArgs,
    CompanyGalleriesArgs,
    GalleryContentItemPage,
    GalleryContentItemsArgs,
    GalleryCollaboratorPage,
    GalleryCollaboratorsArgs,
    GalleryUserPage,
    GallerySearchUsersArgs,
    GalleryInvitationPage,
    GalleryInvitationsArgs,
    QueryMyGalleryInvitationsArgs,
} from 'src/types/__generated__/graphql';
import packageJSON from 'package.json';
import { adsLimitForMap } from 'src/constants/googleMap';
import { pagesJaunesErrorMessages } from 'src/utils/pagesJaunesUtil';
import { routePath404, routePathLogin } from 'src/constants/router';
import type { IAuthContext } from 'src/contexts/AuthProvider';

const invalidTokenError = 'Unauthenticated!';
const userExistsError = 'AccountAlreadyExist';
const homadataError = `An error occured while fetching data from Homadata's api in ad creation`;
const SIRENEApiError = 'Error occurred when getting establishments from SIRENE api';

const restrectedRessource = 'Restrected ressource';

const errorsToMute = [
    userExistsError,
    invalidTokenError,
    SIRENEApiError,
    homadataError,
    restrectedRessource,
    ...pagesJaunesErrorMessages,
];
const errorsWithCustomMessage = {
    'message from back': 'translationkey to use in front',
};

// auth is may be defined only in getInitialProps in _app
export const createLink = (auth: IAuthContext['auth'] = undefined) => {
    const httpLink = createUploadLink({
        uri() {
            const ssr = typeof window === 'undefined';
            const url = ssr ? process.env.NEXT_PUBLIC_GRAPHQL_URL_SERVER : process.env.NEXT_PUBLIC_GRAPHQL_URL;

            if (!url) {
                throw new Error(
                    `No URL found for GraphQL endpoint. Did you define NEXT_PUBLIC_GRAPHQL_URL and NEXT_PUBLIC_GRAPHQL_URL_SERVER in .env.local?`,
                );
            }

            return url;
        },
        credentials: 'same-origin',
        headers: {
            version: packageJSON.version,
        },
    });

    const authMiddleware = setContext(async () => {
        // If session is ended and user connected we logout the user
        const authCookie = getAuthCookie();
        if (!authCookie && firebaseAuth.currentUser) {
            await firebaseAuth.signOut();
            return {
                headers: {
                    ...(auth?.token && { authorization: auth.token }),
                },
            };
        }
        // if user is authenticated, inject a valid token, in the AuthProvider we take care to the value in own cookie
        if (firebaseAuth.currentUser) {
            // firebaseAuth.currentUser.getIdToken()
            // Returns a JSON Web Token (JWT) used to identify the user to a Firebase service.
            // Returns the current token if it has not expired. Otherwise, this will refresh the token and return a new one.
            const idToken = await firebaseAuth.currentUser.getIdToken();
            return {
                headers: {
                    authorization: idToken,
                },
            };
        } else {
            return {
                headers: {
                    ...(auth?.token && { authorization: auth.token }),
                },
            };
        }
    });

    // Retry network errors exponentially with default config
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 5,
            // Do not retry aborted requests.
            retryIf: (error: Error) => !isNil(error) && error.name !== 'AbortError',
        },
    });

    /**
     * Apollo error handling
     */
    const errorLink = onError(({ graphQLErrors, networkError, forward, operation }: ErrorResponse) => {
        if (graphQLErrors) {
            // eslint-disable-next-line no-unreachable-loop
            for (const { message, path } of graphQLErrors) {
                if (process.env.NEXT_PUBLIC_ENV && process.env.NEXT_PUBLIC_ENV !== 'local') {
                    Sentry.captureException(new Error(`GraphQL Error : ${message} Path: ${path}`));
                }
                // On unauthenticated errors, automatically log the user out
                // because their session expired
                if (message.includes(invalidTokenError) && firebaseAuth.currentUser && typeof window !== 'undefined') {
                    firebaseAuth.signOut().then(() => Router.push(routePathLogin));
                }

                if (message.includes(restrectedRessource) && typeof window !== 'undefined') {
                    Router.push(routePath404);
                }

                // Do not show these errors to the user
                if (errorsToMute.find((pattern) => message.includes(pattern))) {
                    return;
                }
                if (Object.keys(errorsWithCustomMessage).find((pattern) => message.includes(pattern))) {
                    Object.entries(errorsWithCustomMessage).map(([key, value]) => {
                        if (message.includes(key)) {
                            dispatchAlert({
                                type: 'error',
                                message: i18n?.t(value) ?? '',
                            });
                        }
                    });
                    return;
                }

                dispatchAlert({
                    type: 'error',
                    title: 'GraphQL error',
                    message: `${message} Path: ${path}`,
                });

                // Retry GraphQL errors once.
                // eslint-disable-next-line consistent-return
                return forward(operation);
            }
        }
        if (networkError) {
            // Do not trigger alert for aborted requests.
            if (networkError.name === 'AbortError') {
                return;
            }

            dispatchAlert({
                type: 'error',
                title: 'Network error',
                message: networkError.message,
            });
        }
    });

    return [errorLink, retryLink, authMiddleware, httpLink];
};

export const createCache = () =>
    new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    ads: {
                        keyArgs: [
                            'orderBy',
                            // cache separate results based on ['limit'] fields => distinction between for map and for list
                            'limit',
                            'userId',
                            'companyId',
                            'statuses',
                            'places',
                            'minPrice',
                            'maxPrice',
                            'minLandArea',
                            'maxLandArea',
                            'minLivingArea',
                            'maxLivingArea',
                            'rooms',
                            'bedrooms',
                            'house',
                            'apartment',
                            'loft',
                            'parking',
                            'terrain',
                            'newProperty',
                            'oldProperty',
                            'interOffice',
                            'type',
                            'assets',
                            'isPrivateAdvertiser',
                            'realEstateTradesAdvertiser',
                        ],
                        merge(existing: AdsSearch | undefined, incoming: AdsSearch | undefined, { args }) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            const isAdsForMap = args?.limit === adsLimitForMap && !args.userId && !args.companyId;
                            const isInitialFetch = args?.offset === 0;
                            if (isAdsForMap || isInitialFetch) {
                                return incoming;
                            }
                            const mergedItems = [...(existing.items ?? []), ...(incoming.items ?? [])];
                            return {
                                items: mergedItems,
                                totalCount: incoming.totalCount,
                            };
                        },
                    },
                    userAds: {
                        keyArgs: ['statuses', 'userId'],
                        merge(
                            existing: UserAdsQuery['userAds'] | undefined,
                            incoming: UserAdsQuery['userAds'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return [...existing, ...incoming];
                        },
                    },
                    membersAds: {
                        keyArgs: ['statuses', 'companyId'],
                        merge(
                            existing: MembersAdsQuery['membersAds'] | undefined,
                            incoming: MembersAdsQuery['membersAds'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return [...existing, ...incoming];
                        },
                    },
                    posts: {
                        keyArgs: ['userId', 'companyId'],
                        merge(
                            existing: PostsQuery['posts'] | undefined,
                            incoming: PostsQuery['posts'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }
                            return [...existing, ...incoming];
                        },
                    },
                    agents: {
                        keyArgs: ['orderBy'],
                        merge(
                            existing: AgentsQuery['agents'] | undefined,
                            incoming: AgentsQuery['agents'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }

                            return {
                                total: incoming.total,
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                            };
                        },
                    },
                    newsFeed: {
                        keyArgs: false,
                        merge(
                            existing: NewsFeedQuery['newsFeed'] | undefined,
                            incoming: NewsFeedQuery['newsFeed'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time

                            const totalSkip =
                                Number(args?.skipPosts ?? 0) +
                                Number(args?.skipNewsPosts ?? 0) +
                                Number(args?.skipAds ?? 0);

                            if (totalSkip === 0) {
                                return incoming;
                            }

                            return {
                                ...incoming,
                                results: [...existing.results, ...incoming.results],
                            };
                        },
                    },
                    myPendingInvitations: {
                        keyArgs: ['side', 'type', 'take'],
                        merge(
                            existing: MyPendingCompanyMembershipInvitationsQuery['myPendingInvitations'] | undefined,
                            incoming: MyPendingCompanyMembershipInvitationsQuery['myPendingInvitations'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }
                            return [...existing, ...incoming];
                        },
                    },
                    adminRequests: {
                        keyArgs: ['companyId', 'status', 'take'],
                        merge(
                            existing: AdminRequestsQuery['adminRequests'] | undefined,
                            incoming: AdminRequestsQuery['adminRequests'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }
                            return {
                                items: [...existing.items, ...incoming.items],
                                total: incoming.total,
                            };
                        },
                    },
                    companyMembersWithTotal: {
                        keyArgs: ['query', 'isDirector', 'isAdmin', 'limit', 'orderBy', 'withRecursive', 'companyId'],
                        merge(
                            existing: CompanyMembersWithTotalQuery['companyMembersWithTotal'] | undefined,
                            incoming: CompanyMembersWithTotalQuery['companyMembersWithTotal'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                                total: incoming.total,
                            };
                        },
                    },
                    groupCompanies: {
                        keyArgs: ['groupId', 'limit', 'lat', 'lng', 'orderBy'],
                        merge(
                            existing: GroupCompaniesQuery['groupCompanies'] | undefined,
                            incoming: GroupCompaniesQuery['groupCompanies'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                results: [...existing.results, ...incoming.results],
                                total: incoming.total,
                            };
                        },
                    },
                    searchCompaniesForInvitations: {
                        keyArgs: ['search'],
                        merge(
                            existing: SearchCompaniesForInvitationsQuery['searchCompaniesForInvitations'] | undefined,
                            incoming: SearchCompaniesForInvitationsQuery['searchCompaniesForInvitations'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }

                            return {
                                total: incoming.total,
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                            };
                        },
                    },
                    jobOffers: {
                        keyArgs: false,
                        merge(
                            existing: JobOffersQuery['jobOffers'] | undefined,
                            incoming: JobOffersQuery['jobOffers'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }
                            return [...existing, ...incoming];
                        },
                    },
                    savedSearches: {
                        keyArgs: false,
                        merge(
                            existing: SavedSearchesQuery['savedSearches'] | undefined,
                            incoming: SavedSearchesQuery['savedSearches'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                results: [...existing.results, ...incoming.results],
                                total: incoming.total,
                            };
                        },
                    },
                    recommendingUsers: {
                        keyArgs: ['companyId', 'userId', 'userType'],
                        merge(
                            existing: RecommendingUsersQuery['recommendingUsers'] | undefined,
                            incoming: RecommendingUsersQuery['recommendingUsers'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                                total: incoming.total,
                            };
                        },
                    },
                    searchAllUsersWithTotal: {
                        keyArgs: ['search'],
                        merge(
                            existing: SearchAllUsersWithTotalQuery['searchAllUsersWithTotal'] | undefined,
                            incoming: SearchAllUsersWithTotalQuery['searchAllUsersWithTotal'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                                total: incoming.total,
                            };
                        },
                    },
                    searchCommunity: {
                        keyArgs: [
                            'search',
                            'limit',
                            'activityId',
                            'categoryId',
                            'cityId',
                            'cityRadiusKm',
                            'communityEntityTypes',
                            'orderBy',
                            [
                                'currentUserContact',
                                'mutualContacts',
                                'distanceCity',
                                'distance',
                                'recommendation',
                                'name',
                                'averageRating',
                            ],
                        ],
                        merge(
                            existing: SearchCommunityQuery['searchCommunity'] | undefined,
                            incoming: SearchCommunityQuery['searchCommunity'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                ...incoming,
                                total: incoming.total,
                                results: [...existing.results, ...incoming.results],
                            };
                        },
                    },
                    userContactsV2: {
                        keyArgs: ['userId', 'onlyMutual', 'query'],
                        merge(
                            existing: UserContactsQuery['userContactsV2'] | undefined,
                            incoming: UserContactsQuery['userContactsV2'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.skip || args.skip === 0) {
                                return incoming;
                            }
                            return {
                                total: incoming.total,
                                results: [...(existing.results ?? []), ...(incoming.results ?? [])],
                            };
                        },
                    },
                    following: {
                        keyArgs: ['userId', 'query'],
                        merge(
                            existing: FollowingQuery['following'] | undefined,
                            incoming: FollowingQuery['following'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                total: incoming.total,
                                results: [...existing.results, ...incoming.results],
                            };
                        },
                    },
                    followers: {
                        keyArgs: ['userId', 'companyId', 'query'],
                        merge(
                            existing: FollowersQuery['followers'] | undefined,
                            incoming: FollowersQuery['followers'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                total: incoming.total,
                                results: [...existing.results, ...incoming.results],
                            };
                        },
                    },
                    adPublishers: {
                        keyArgs: ['adId'],
                        merge(
                            existing: AdPublishersQuery['adPublishers'] | undefined,
                            incoming: AdPublishersQuery['adPublishers'] | undefined,
                            { args },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.offset || args.offset === 0) {
                                return incoming;
                            }
                            return {
                                total: incoming.total,
                                results: [...existing.results, ...incoming.results],
                            };
                        },
                    },
                    myGalleryInvitations: {
                        keyArgs: ['statuses', 'scopeResourceTypes', 'includeExpired', 'pageRequest', ['limit']],
                        merge(
                            existing: GalleryInvitationPage | undefined,
                            incoming: GalleryInvitationPage | undefined,
                            { args }: { args: QueryMyGalleryInvitationsArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                items: [...existing.items, ...incoming.items],
                                totalCount: incoming.totalCount,
                            };
                        },
                    },
                },
            },
            User: {
                fields: {
                    galleries: {
                        keyArgs: ['policy', 'pageRequest', ['limit']],
                        merge(
                            existing: GalleryPage | undefined,
                            incoming: GalleryPage | undefined,
                            { args }: { args: UserGalleriesArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                items: [...existing.items, ...incoming.items],
                                totalCount: incoming.totalCount,
                            };
                        },
                    },
                },
            },
            Company: {
                fields: {
                    galleries: {
                        keyArgs: ['policy', 'pageRequest', ['limit']],
                        merge(
                            existing: GalleryPage | undefined,
                            incoming: GalleryPage | undefined,
                            { args }: { args: CompanyGalleriesArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                items: [...existing.items, ...incoming.items],
                                totalCount: incoming.totalCount,
                            };
                        },
                    },
                },
            },
            Gallery: {
                fields: {
                    contentItems: {
                        keyArgs: ['types'],
                        merge(
                            existing: GalleryContentItemPage | undefined,
                            incoming: GalleryContentItemPage | undefined,
                            { args }: { args: GalleryContentItemsArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                items: [...existing.items, ...incoming.items],
                                totalCount: incoming.totalCount,
                            };
                        },
                    },
                    collaborators: {
                        keyArgs: ['pageRequest', ['limit']],
                        merge(
                            existing: GalleryCollaboratorPage | undefined,
                            incoming: GalleryCollaboratorPage | undefined,
                            { args }: { args: GalleryCollaboratorsArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                totalCount: incoming.totalCount,
                                items: [...existing.items, ...incoming.items],
                            };
                        },
                    },
                    invitations: {
                        keyArgs: ['pageRequest', ['limit']],
                        merge(
                            existing: GalleryInvitationPage | undefined,
                            incoming: GalleryInvitationPage | undefined,
                            { args }: { args: GalleryInvitationsArgs | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                totalCount: incoming.totalCount,
                                items: [...existing.items, ...incoming.items],
                            };
                        },
                    },
                    searchUsers: {
                        keyArgs: ['pageRequest', ['limit']],
                        merge(
                            existing: GalleryUserPage | undefined,
                            incoming: GalleryUserPage | undefined,
                            { args }: { args: Partial<GallerySearchUsersArgs> | null },
                        ) {
                            if (!incoming) return existing;
                            if (!existing) return incoming; // existing will be empty the first time
                            if (!args?.pageRequest?.offset || args.pageRequest.offset === 0) {
                                return incoming;
                            }
                            return {
                                totalCount: incoming.totalCount,
                                items: [...existing.items, ...incoming.items],
                            };
                        },
                    },
                },
            },
        },
        dataIdFromObject(responseObject) {
            // as PagesJaunesPro doesn't have id, we customize how apollo client store it in the cache (and to be sure we use the merchantId for PagesJaunesProSearchResult)
            // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
            switch (responseObject.__typename) {
                case 'PagesJaunesProSearchResult':
                    return `PagesJaunesProSearchResult:${responseObject.merchantId}`;
                case 'PagesJaunesPro':
                    return `PagesJaunesPro:${responseObject.proId}`;
                default:
                    return defaultDataIdFromObject(responseObject);
            }
        },
    });

/**
 * Used in SSR getInitialProps and in React
 */
const createApolloClient = (
    initialState: NormalizedCacheObject | null = null,
    auth: IAuthContext['auth'] = undefined,
) =>
    new ApolloClient({
        ssrMode: typeof window === 'undefined',
        link: ApolloLink.from(createLink(auth) as Array<ApolloLink | RequestHandler>),
        cache: createCache().restore(initialState ?? {}),
    });

export default createApolloClient;
