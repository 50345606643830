/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { getApps, initializeApp } from 'firebase/app';
import type { User, ActionCodeSettings, AuthProvider, PopupRedirectResolver, AuthCredential } from 'firebase/auth';
import {
    getAuth,
    createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
    sendEmailVerification as firebaseSendEmailVerification,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    signInWithPopup as firebaseSignInWithPopup,
    sendPasswordResetEmail as firebaseSendPasswordResetEmail,
    reauthenticateWithCredential as firebaseReauthenticateWithCredential,
    updatePassword as firebaseUpdatePassword,
} from 'firebase/auth';
import type { AnalyticsCallOptions } from 'firebase/analytics';
import {
    getAnalytics as firebaseGetAnalytics,
    logEvent as firebaseLogEvent,
    setUserId as firebaseSetUserId,
    setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import type { RemoteConfig } from 'firebase/remote-config';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';

import type { AnalyticsEvent } from 'src/utils/analytics';

const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if (!getApps().length) {
    initializeApp(config);
}

export const auth = getAuth();

const getAnalytics = () => {
    const analytics = firebaseGetAnalytics();

    if ((window as any).gtag) {
        (window as any).gtag('config', config.measurementId, {
            cookie_expires: 31540000, // 1 year in seconds
        });
    }

    return analytics;
};

export const logEvent = (eventName: AnalyticsEvent | string, eventParams?: Record<string, any> | undefined) => {
    if (typeof window !== 'undefined' && process.env.JEST_WORKER_ID === undefined) {
        const analytics = getAnalytics();
        firebaseLogEvent(analytics, eventName, eventParams);
    }
};

export const setUserId = (id: string, options?: AnalyticsCallOptions | undefined) => {
    if (typeof window !== 'undefined' && process.env.JEST_WORKER_ID === undefined) {
        const analytics = getAnalytics();
        firebaseSetUserId(analytics, id, options);
    }
};

export const disableAnalyticsCollection = () => {
    if (typeof window !== 'undefined' && process.env.JEST_WORKER_ID === undefined) {
        const analytics = getAnalytics();
        setAnalyticsCollectionEnabled(analytics, false);
    }
};

export const createUserWithEmailAndPassword = (email: string, password: string) =>
    firebaseCreateUserWithEmailAndPassword(auth, email, password);

export const sendEmailVerification = (user: User, actionCodeSettings?: ActionCodeSettings | null | undefined) =>
    firebaseSendEmailVerification(user, actionCodeSettings);

export const signInWithEmailAndPassword = (email: string, password: string) =>
    firebaseSignInWithEmailAndPassword(auth, email, password);

export const signInWithPopup = (provider: AuthProvider, resolver?: PopupRedirectResolver | undefined) =>
    firebaseSignInWithPopup(auth, provider, resolver);

export const sendPasswordResetEmail = (email: string, actionCodeSettings?: ActionCodeSettings | undefined) =>
    firebaseSendPasswordResetEmail(auth, email, actionCodeSettings);

export const reauthenticateWithCredential = (user: User, credential: AuthCredential) =>
    firebaseReauthenticateWithCredential(user, credential);

export const updatePassword = (user: User, newPassword: string) => firebaseUpdatePassword(user, newPassword);

export const newGeoServiceKey = 'new_geo_service';
export const showPagesJaunesOwnershipSectionKey = 'show_pagesjaunes_ownership_section';
export const defaultRemoteConfig = {
    [newGeoServiceKey]: false,
    [showPagesJaunesOwnershipSectionKey]: false,
};
class WoopenRemoteConfig {
    remoteConfig: RemoteConfig | null;

    isSetup = false;

    hasFetchedAndActivated = false;

    constructor({ remoteConfig }: { remoteConfig: RemoteConfig | null }) {
        this.remoteConfig = remoteConfig;
        this.setup();
    }

    setup() {
        if (this.remoteConfig) {
            // These are the default values but put them here to be explicit
            this.remoteConfig.settings.fetchTimeoutMillis = 60000; // 1 minute
            this.remoteConfig.settings.minimumFetchIntervalMillis = 43200000; // 12 hours
            this.remoteConfig.defaultConfig = { ...defaultRemoteConfig };
            this.isSetup = true;
            return true;
        } else {
            return false;
        }
    }

    async fetchAndActivate() {
        if (this.remoteConfig) {
            await fetchAndActivate(this.remoteConfig);
            this.hasFetchedAndActivated = true;
            return true;
        } else {
            return false;
        }
    }

    getBoolean(key: keyof typeof defaultRemoteConfig) {
        // Firebase remote config is not supported server side so we fallback to the default values
        // See: https://github.com/firebase/firebase-js-sdk/issues/2242
        if (this.isSetup && this.remoteConfig) {
            return getValue(this.remoteConfig, key).asBoolean();
        }

        return Boolean(defaultRemoteConfig[key]);
    }
}

const getRemoteConfigIfSupported = () =>
    typeof window !== 'undefined' && process.env.NODE_ENV !== 'test' ? getRemoteConfig(getApps()[0]) : null;

export const woopenRemoteConfig = new WoopenRemoteConfig({
    remoteConfig: getRemoteConfigIfSupported(),
});
