import { DialogContent } from '@mui/material';
import React from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from 'next-i18next';

import Dialog from 'src/components/ui/Dialog';
import DialogTitle from 'src/components/ui/DialogTitle';
import Typography from 'src/components/ui/Typography';
import Button from 'src/components/ui/Button';
import useIsCurrentVersionSupported from 'src/hooks/useIsCurrentVersionsSupported';
import getCurrentVersion from 'src/utils/version';
import { styledComponent } from 'src/utils/styled';

const WoopenLogo = dynamic(() => import('src/assets/logos/vertical-woopen-logo.svg'), {
    ssr: false,
});

const StyledWoopenLogo = styledComponent(WoopenLogo)`
    width: 12rem;
    height: 9rem;
    margin-bottom: 2rem;
`;

const StyledDialog = styledComponent(Dialog)`
    .MuiDialogContent-root {
        padding: 0 1.5rem;
        padding-bottom: 2.5rem;
    }
    .MuiDialogActions-root {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .MuiDialogTitle-root {
        padding: 1.5rem 1.5rem 0 1.5rem;
        display: flex;
        flex-direction: column;
    }

    text-align: center;
`;

const StyledParagraph = styledComponent(Typography)`
    padding-top: 1em;
    padding-bottom: 2em;
`;

const Footer = styledComponent(Typography)`
    font-size: 0.5em;
    padding-top: 1em;
    padding-bottom: 1em;
`;

StyledDialog.defaultProps = {
    maxWidth: 'xs',
    fullWidth: true,
};

interface Props {}

const UpgradeModal: React.FC<Props> = () => {
    const { t } = useTranslation(['common']);
    const currentVersion = getCurrentVersion();
    const isCurrentVersionSupported = useIsCurrentVersionSupported();

    const showModal =
        isCurrentVersionSupported === false && !process.env.NEXT_PUBLIC_SITE_URL?.includes('http://localhost');

    return (
        <StyledDialog open={showModal}>
            <DialogTitle>
                <StyledWoopenLogo />
                <Typography variant="h2">{t('common:upgrade.title')}</Typography>
            </DialogTitle>

            <DialogContent>
                <StyledParagraph>{t('common:upgrade.body')}</StyledParagraph>

                <Button
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    {t('common:upgrade.refresh')}
                </Button>
            </DialogContent>
            <Footer>{currentVersion}</Footer>
        </StyledDialog>
    );
};

export default UpgradeModal;
