export const websiteRegEx =
    // eslint-disable-next-line no-useless-escape -- need escape for regex
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const notBlankSpaceRegEx = new RegExp(/.*[^ \n].*/g);
export const numberOfDecimalsRegex = (numberOfDecimals: number) =>
    new RegExp(`^[0-9]*(.[0-9]{0,${numberOfDecimals}})?$`);
export const maxLengthRegex = (maxLength: number) => new RegExp(`^s*-?[0-9]{0,${maxLength}}s*$`);

export const mentionRegEx = new RegExp(/@@id@(.*?)@name@(.*?)@@/g);
export const mentionRegExWithoutGroups = new RegExp(/(@@id@.*?@name@.*?@@)/g);

export const emailRFC5322RegEx =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
