/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from 'react';
import { useField } from 'formik';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

import type { FilledTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import MuiTextField from '@mui/material/TextField';
import type { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import Box from '@mui/material/Box';

import { colours } from 'src/constants/colours';
import { maxLengthRegex, numberOfDecimalsRegex } from 'src/constants/regexes';
import theme from 'src/utils/theme';
import { styledComponent, styledTag } from 'src/utils/styled';
import Typography from 'src/components/ui/Typography';

export const Container = styledTag('div')`
    margin: 1rem 0;
    width: 100%;
    position: relative;
`;

export const ErrorSpan = styledTag('span')`
    color: ${colours.redError};
    margin-top: 0.25rem;
    font-size: 0.875rem;
`;

export const Error = ({ children }: { children: React.ReactNode }) => <ErrorSpan>{children}</ErrorSpan>;
export interface Props extends Omit<FilledTextFieldProps, 'variant' | 'error'> {
    name: string; // Required
    inverted?: boolean;
    dark?: boolean;
    hideErrors?: boolean;
    error?: string;
    showCountDown?: boolean;
    formatNumbers?: boolean;
    minAndMaxBudgetButton?: boolean;
    numberOfDecimals?: number;
    maxLength?: number;
}

interface StyledTextFieldProps {
    $inverted?: boolean;
    $dark?: boolean;
}

export const StyledTextField = styledComponent(MuiTextField)<StyledTextFieldProps>`
    width: 100%;
    margin: 1rem 0;

    .MuiInputBase-root {
        position: relative;
        border-radius: 0.25rem;
        background-color: ${colours.primary.O08};
        border: 0.0625rem solid ${colours.primary.O08};
        color: ${theme.palette.primary.main};
        display: flex;
        &.Mui-focused {
            border-color: ${colours.primary.O60};
        }
        &.Mui-error {
            border-color: ${theme.palette.error.main};
        }
    }
    .MuiInputBase-input {
        padding-top: 1rem;
        padding-bottom: 1rem;
        min-height: 1rem;
        font-size: 0.875rem;
        flex: 1;
        &.Mui-disabled {
            -webkit-text-fill-color: unset;
        }
    }
    .MuiInputLabel-root {
        color: ${colours.primary.O60};
        transform: translate(0.75rem, 1rem) scale(1);
        max-width: calc(100% - 1.5rem);
        &.Mui-error {
            color: ${theme.palette.error.main};
        }
        &:not(.MuiInputLabel-shrink) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.MuiInputLabel-shrink,
        &.Mui-focused {
            transform: translate(0.75rem, 0.5rem) scale(0.75);
            max-width: calc(100% + 3rem);
            width: max-content;
        }
        ${(props) =>
            props.InputProps?.endAdornment &&
            `
                max-width: calc(100% - 3.5rem);
        `}
    }

    ${({ $inverted }) =>
        $inverted &&
        `
        .MuiInputBase-root {
            background-color: ${colours.white.main};
            border-color: ${colours.grey.G200};
        }
    `}
    ${({ $dark }) =>
        $dark &&
        `
        .MuiInputBase-root {
            color: ${colours.white.main};
            background-color: ${colours.primary.main};
            border-color: ${colours.primary.main};
        }
        .MuiInputLabel-root {
            color: ${colours.white.O72};
        }
    `};

    ${(props) =>
        props.label &&
        `
        .MuiInputBase-input {
            padding-top: 1.4rem;
            padding-bottom: 0.6rem;
        }
    `}

    ${(props) =>
        props.InputProps?.startAdornment &&
        `
        .MuiInputLabel-root {
            left: 1.75rem;
        }
    `}

    ${(props) =>
        props.multiline &&
        props.label &&
        `
                .MuiInputLabel-root.Mui-focused {
                    top: 0rem;
                }
            `}
    .MuiInputBase-multiline {
        padding-top: 0;
    }
    .MuiInputBase-inputAdornedStart {
        padding-left: 0.4rem;
    }
    .MuiInputBase-inputAdornedEnd {
        padding-right: 0.5rem;
    }

    .MuiFormHelperText-root:not(.Mui-error) {
        color: ${colours.primaryOpacity.O60};
    }
    .MuiFormHelperText-root {
        margin-left: 0;
    }
`;

const StyledEndAdornment = styledComponent(Typography)`
    font-weight: 400;
    font-size: 0.563rem;
    line-height: 0.875rem;
    color: ${colours.primaryOpacity.O72};
`;

const CustomNumberFormat = (props: NumericFormatProps & { inputRef: React.Ref<unknown> }) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange?.({
                    target: {
                        name: props.name as string,
                        value: values.value,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
            }}
        />
    );
};

StyledTextField.defaultProps = {
    type: 'text',
    variant: 'filled',
};

const TextField: React.FC<Props> = ({
    name,
    label,
    type,
    onChange,
    error,
    hideErrors,
    showCountDown,
    formatNumbers,
    inverted,
    numberOfDecimals,
    minAndMaxBudgetButton,
    maxLength,
    dark,
    ...rest
}) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
        const newValue = event.target.value;
        let valueIsOnGoodRange = true;
        if (type === 'number') {
            const { InputProps } = rest;
            const min = InputProps?.inputProps?.min;
            const max = InputProps?.inputProps?.max;

            if (min && newValue && Number(newValue) < min) {
                valueIsOnGoodRange = false;
            }
            if (max && newValue && Number(newValue) > max) {
                valueIsOnGoodRange = false;
            }
            if (numberOfDecimals) {
                if (!numberOfDecimalsRegex(numberOfDecimals).test(newValue)) {
                    valueIsOnGoodRange = false;
                }
            }
            if (maxLength) {
                if (!maxLengthRegex(maxLength).test(newValue)) {
                    valueIsOnGoodRange = false;
                }
            }
        }
        if (valueIsOnGoodRange) {
            setValue(newValue);
        }
    };

    const handleOnKeyDown = (event: React.KeyboardEvent) => {
        if (type === 'number' && ['e', '-'].includes(event.key)) {
            event.preventDefault();
        }
        if (type === 'number' && minAndMaxBudgetButton && ['e', '-', '.', ','].includes(event.key)) {
            event.preventDefault();
        }
    };

    const hasError = Boolean(!hideErrors && (error ?? (meta.touched && meta.error)));

    const errorText = hasError ? error ?? (meta.touched && meta.error) : undefined;

    let endAdornment;
    if (showCountDown && rest.InputProps?.inputProps?.maxLength) {
        endAdornment = (
            <Box alignSelf="start" mt="0.5rem">
                <StyledEndAdornment>
                    {`${String(field.value).length} / ${rest.InputProps.inputProps.maxLength}`}
                </StyledEndAdornment>
            </Box>
        );
    }

    const shownText = errorText ?? rest.helperText;

    const hasValue = !['', null, undefined].includes(field.value as string);

    const extraProps: TextFieldProps = {};

    if (type === 'number' && formatNumbers) {
        extraProps.InputProps = {
            disableUnderline: true,
            inputComponent: CustomNumberFormat as React.ElementType<InputBaseComponentProps>,
            ...rest.InputProps,
            inputProps: {
                thousandSeparator: ' ',
                allowedDecimalSeparators: [',', '.'],
                ...rest.InputProps?.inputProps,
            },
        };
        extraProps.type = 'text';
    }

    return (
        <StyledTextField
            {...rest}
            {...field}
            id={name}
            type={type}
            name={name}
            label={label}
            helperText={shownText}
            error={hasError}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            InputProps={{
                disableUnderline: true,
                endAdornment: rest.InputProps?.endAdornment ?? endAdornment,
                ...rest.InputProps,
            }}
            InputLabelProps={{
                shrink: hasValue,
                ...rest.InputLabelProps,
            }}
            $inverted={inverted}
            $dark={dark}
            {...extraProps}
            FormHelperTextProps={{
                ...rest.FormHelperTextProps,
            }}
        />
    );
};

export default TextField;
