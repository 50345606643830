import dynamic from 'next/dynamic';

import { Plus } from '@emotion-icons/bootstrap/Plus';
import { QuestionCircle } from '@emotion-icons/bootstrap/QuestionCircle';
import { ArrowBack } from '@emotion-icons/boxicons-regular/ArrowBack';
import { ChevronDown } from '@emotion-icons/boxicons-regular/ChevronDown';
import { DotsHorizontalRounded } from '@emotion-icons/boxicons-regular/DotsHorizontalRounded';
import { InfoCircle } from '@emotion-icons/boxicons-regular/InfoCircle';
import { Close } from '@emotion-icons/evil/Close';
import { Globe } from '@emotion-icons/feather/Globe';
import { Visibility } from '@emotion-icons/material-rounded/Visibility';
import { VisibilityOff } from '@emotion-icons/material-rounded/VisibilityOff';
import { Star } from '@emotion-icons/material/Star';
import { ArrowRight } from '@emotion-icons/zondicons/ArrowRight';
import type { CSSProperties } from 'react';
import React from 'react';

const Accessibility = dynamic(() => import('src/assets/icons/accessibility.svg'), { ssr: false });
const AllCommunity = dynamic(() => import('src/assets/icons/all-community.svg'), { ssr: false });
const Activity = dynamic(() => import('src/assets/icons/activity.svg'), { ssr: false });
const AdIcon = dynamic(() => import('src/assets/icons/ad-icon.svg'), { ssr: false });
const AddPicture = dynamic(() => import('src/assets/icons/add-picture.svg'), { ssr: false });
const AddUser = dynamic(() => import('src/assets/icons/add-user.svg'), { ssr: false });
const AddUserWhite = dynamic(() => import('src/assets/icons/add-user-white.svg'), { ssr: false });
const Add = dynamic(() => import('src/assets/icons/add.svg'), { ssr: false });
const AirConditioner = dynamic(() => import('src/assets/icons/air-conditioner.svg'), {
    ssr: false,
});
const Alarm = dynamic(() => import('src/assets/icons/alarm.svg'), { ssr: false });
const Alert = dynamic(() => import('src/assets/icons/alert.svg'), { ssr: false });
const Area = dynamic(() => import('src/assets/icons/area.svg'), { ssr: false });
const Apple = dynamic(() => import('src/assets/icons/apple.svg'), { ssr: false });
const Balcony = dynamic(() => import('src/assets/icons/balcon.svg'), { ssr: false });
const BedRoom = dynamic(() => import('src/assets/icons/bed-room.svg'), { ssr: false });
const BigThumbsUp = dynamic(() => import('src/assets/icons/big-thumbs-up.svg'), { ssr: false });
const BookmarkGrey = dynamic(() => import('src/assets/icons/bookmark-grey.svg'), { ssr: false });
const BookmarkPrimaryActive = dynamic(() => import('src/assets/icons/bookmark-primary-active.svg'), { ssr: false });
const BookmarkPrimaryInactive = dynamic(() => import('src/assets/icons/bookmark-primary-inactive.svg'), { ssr: false });
const BookmarkWhiteActive = dynamic(() => import('src/assets/icons/bookmark-white-active.svg'), {
    ssr: false,
});
const BookmarkWhiteInactive = dynamic(() => import('src/assets/icons/bookmark-white-inactive.svg'), { ssr: false });
const Bookmark = dynamic(() => import('src/assets/icons/bookmark.svg'), { ssr: false });
const ButtonChevronRightCircle = dynamic(() => import('src/assets/icons/button-chevron-right-circle.svg'), {
    ssr: false,
});
const ButtonChevronLeftCircle = dynamic(() => import('src/assets/icons/button-chevron-left-circle.svg'), {
    ssr: false,
});
const Calculator = dynamic(() => import('src/assets/icons/calculator.svg'), { ssr: false });
const Call = dynamic(() => import('src/assets/icons/call.svg'), { ssr: false });
const CallWhite = dynamic(() => import('src/assets/icons/call-white.svg'), { ssr: false });
const CallCenter = dynamic(() => import('src/assets/icons/call-center.svg'), { ssr: false });
const Camera = dynamic(() => import('src/assets/icons/camera.svg'), { ssr: false });
const Caret = dynamic(() => import('src/assets/icons/caret.svg'), { ssr: false });
const Cave = dynamic(() => import('src/assets/icons/cave.svg'), { ssr: false });
const Certification = dynamic(() => import('src/assets/icons/certification.svg'), { ssr: false });
const ChatPrimaryActive = dynamic(() => import('src/assets/icons/chat-primary-active.svg'), {
    ssr: false,
});
const ChatPrimaryInactive = dynamic(() => import('src/assets/icons/chat-primary-inactive.svg'), {
    ssr: false,
});
const ChatWhiteActive = dynamic(() => import('src/assets/icons/chat-white-active.svg'), {
    ssr: false,
});
const ChatWhiteInactive = dynamic(() => import('src/assets/icons/chat-white-inactive.svg'), {
    ssr: false,
});
const Chat = dynamic(() => import('src/assets/icons/chat.svg'), { ssr: false });
const CheckEmailIcon = dynamic(() => import('src/assets/icons/check-email.svg'), { ssr: false });
const CheckMarkBlack = dynamic(() => import('src/assets/icons/check-mark-black.svg'), {
    ssr: false,
});
const CheckMarkBlue = dynamic(() => import('src/assets/icons/check-mark-blue.svg'), { ssr: false });
const CheckMarkCircleThin = dynamic(() => import('src/assets/icons/check-mark-circle-thin.svg'), {
    ssr: false,
});
const CheckMarkWhite = dynamic(() => import('src/assets/icons/check-mark-white.svg'), {
    ssr: false,
});
const CheckMarkGreenOnGrey = dynamic(() => import('src/assets/icons/check-mark-green-on-grey.svg'), { ssr: false });
const ChevronLeft = dynamic(() => import('src/assets/icons/chevron-left.svg'), { ssr: false });
const ChevronRight = dynamic(() => import('src/assets/icons/chevron-right.svg'), { ssr: false });
const ChevronRightCircle = dynamic(() => import('src/assets/icons/chevron-right-circle.svg'), {
    ssr: false,
});
const CloseCircle = dynamic(() => import('src/assets/icons/close-circle.svg'), { ssr: false });
const Collaborators = dynamic(() => import('src/assets/icons/collaborators.svg'), { ssr: false });
const Comments = dynamic(() => import('src/assets/icons/comments.svg'), { ssr: false });
const Company = dynamic(() => import('src/assets/icons/company.svg'), { ssr: false });
const CompanyIcon = dynamic(() => import('src/assets/icons/company-icon.svg'), { ssr: false });
const CompanyAvatarEmpty = dynamic(() => import('src/assets/icons/company-avatar-empty.svg'), {
    ssr: false,
});
const Conception = dynamic(() => import('src/assets/icons/conception.svg'), { ssr: false });
const Connected = dynamic(() => import('src/assets/icons/connected.svg'), { ssr: false });
const Crafts = dynamic(() => import('src/assets/icons/crafts.svg'), { ssr: false });
const CtaEstimate = dynamic(() => import('src/assets/icons/cta-estimate.svg'), { ssr: false });
const CtaFindAPro = dynamic(() => import('src/assets/icons/cta-find-a-pro.svg'), { ssr: false });
const CtaPlaceAnAd = dynamic(() => import('src/assets/icons/cta-place-an-ad.svg'), { ssr: false });
const CtaSearch = dynamic(() => import('src/assets/icons/cta-search.svg'), { ssr: false });
const Date = dynamic(() => import('src/assets/icons/date.svg'), { ssr: false });
const DecreasingPrice = dynamic(() => import('src/assets/icons/decreasingPrice.svg'), {
    ssr: false,
});
const Delete = dynamic(() => import('src/assets/icons/delete.svg'), { ssr: false });
const Design = dynamic(() => import('src/assets/icons/design.svg'), { ssr: false });
const DisplayOn = dynamic(() => import('src/assets/icons/display-on.svg'), { ssr: false });
const DisplayOff = dynamic(() => import('src/assets/icons/display-off.svg'), { ssr: false });
const Distance = dynamic(() => import('src/assets/icons/distance.svg'), { ssr: false });
const Draw = dynamic(() => import('src/assets/icons/draw.svg'), { ssr: false });
const Dressing = dynamic(() => import('src/assets/icons/dressing.svg'), { ssr: false });
const Edit = dynamic(() => import('src/assets/icons/edit.svg'), { ssr: false });
const Elevator = dynamic(() => import('src/assets/icons/elevator.svg'), { ssr: false });
const Ellipse = dynamic(() => import('src/assets/icons/ellipse.svg'), { ssr: false });
const ExternalLink = dynamic(() => import('src/assets/icons/external-link.svg'), { ssr: false });
const Eye = dynamic(() => import('src/assets/icons/eye.svg'), { ssr: false });
const Facebook = dynamic(() => import('src/assets/icons/facebook.svg'), { ssr: false });
const SavedSearches = dynamic(() => import('src/assets/icons/saved-searches.svg'), { ssr: false });
const FileBox = dynamic(() => import('src/assets/icons/file-box.svg'), { ssr: false });
const File = dynamic(() => import('src/assets/icons/file.svg'), { ssr: false });
const FileWhite = dynamic(() => import('src/assets/icons/file-white.svg'), { ssr: false });
const Filter = dynamic(() => import('src/assets/icons/filter.svg'), { ssr: false });
const FiltersResponsive = dynamic(() => import('src/assets/icons/filters-responsive.svg'), {
    ssr: false,
});
const Financial = dynamic(() => import('src/assets/icons/financial.svg'), { ssr: false });
const Fireplace = dynamic(() => import('src/assets/icons/fireplace.svg'), { ssr: false });
const FlagFrance = dynamic(() => import('src/assets/icons/flag-france.svg'), { ssr: false });
const FlagUnitedKingdom = dynamic(() => import('src/assets/icons/flag-united-kingdom.svg'), {
    ssr: false,
});
const Followers = dynamic(() => import('src/assets/icons/followers.svg'), { ssr: false });
const FollowersGradient = dynamic(() => import('src/assets/icons/followers-gradient.svg'), {
    ssr: false,
});
const Following = dynamic(() => import('src/assets/icons/following.svg'), { ssr: false });
const FollowingGradient = dynamic(() => import('src/assets/icons/following-gradient.svg'), {
    ssr: false,
});
const ForSaleDark = dynamic(() => import('src/assets/icons/for-sale-dark.svg'), { ssr: false });
const ForSaleEn = dynamic(() => import('src/assets/icons/for-sale-en.svg'), { ssr: false });
const ForSaleOutlined = dynamic(() => import('src/assets/icons/for-sale-outlined.svg'), {
    ssr: false,
});
const ForSale = dynamic(() => import('src/assets/icons/for-sale.svg'), { ssr: false });
const Garage = dynamic(() => import('src/assets/icons/garage.svg'), { ssr: false });
const Garden = dynamic(() => import('src/assets/icons/garden.svg'), { ssr: false });
const Google = dynamic(() => import('src/assets/icons/google.svg'), { ssr: false });
const GridFilled = dynamic(() => import('src/assets/icons/grid-filled.svg'), { ssr: false });
const GridOutlined = dynamic(() => import('src/assets/icons/grid-outlined.svg'), { ssr: false });
const HeartEmpty = dynamic(() => import('src/assets/icons/heart-empty.svg'), { ssr: false });
const HeartFillGrey = dynamic(() => import('src/assets/icons/heart-fill-grey.svg'), { ssr: false });
const HeartFillRed = dynamic(() => import('src/assets/icons/heart-fill-red.svg'), { ssr: false });
const Help = dynamic(() => import('src/assets/icons/help.svg'), { ssr: false });
const Home = dynamic(() => import('src/assets/icons/home.svg'), { ssr: false });
const HomeArea = dynamic(() => import('src/assets/icons/home-area.svg'), { ssr: false });
const HomeLocation = dynamic(() => import('src/assets/icons/home-location.svg'), { ssr: false });
const HouseGarden = dynamic(() => import('src/assets/icons/house-garden.svg'), { ssr: false });
const HouseKey = dynamic(() => import('src/assets/icons/house-key.svg'), { ssr: false });
const HouseKeyOutlined = dynamic(() => import('src/assets/icons/house-key-outlined.svg'), {
    ssr: false,
});
const HouseSearchEmpty = dynamic(() => import('src/assets/icons/house-search-empty.svg'), {
    ssr: false,
});
const Inactive = dynamic(() => import('src/assets/icons/inactive.svg'), { ssr: false });
const Influencer = dynamic(() => import('src/assets/icons/influencer.svg'), { ssr: false });
const Info = dynamic(() => import('src/assets/icons/info.svg'), { ssr: false });
const InterestGuy = dynamic(() => import('src/assets/icons/interest-guy.svg'), { ssr: false });
const Insurance = dynamic(() => import('src/assets/icons/insurance.svg'), { ssr: false });
const Land = dynamic(() => import('src/assets/icons/land.svg'), { ssr: false });
const Legal = dynamic(() => import('src/assets/icons/legal.svg'), { ssr: false });
const Like = dynamic(() => import('src/assets/icons/like.svg'), { ssr: false });
const Link = dynamic(() => import('src/assets/icons/link.svg'), { ssr: false });
const LinkIcon = dynamic(() => import('src/assets/icons/link-icon.svg'), { ssr: false });
const ListOn = dynamic(() => import('src/assets/icons/list-on.svg'), { ssr: false });
const ListOff = dynamic(() => import('src/assets/icons/list-off.svg'), { ssr: false });
const Location = dynamic(() => import('src/assets/icons/location.svg'), { ssr: false });
const Linkedin = dynamic(() => import('src/assets/icons/linkedIn.svg'), { ssr: false });
const Lock = dynamic(() => import('src/assets/icons/lock.svg'), { ssr: false });
const LockCircle = dynamic(() => import('src/assets/icons/lockCircle.svg'), { ssr: false });
const Loft = dynamic(() => import('src/assets/icons/loft.svg'), { ssr: false });
const Logout = dynamic(() => import('src/assets/icons/logout.svg'), { ssr: false });
const Mail = dynamic(() => import('src/assets/icons/mail.svg'), { ssr: false });
const Map = dynamic(() => import('src/assets/icons/map.svg'), { ssr: false });
const MapFilled = dynamic(() => import('src/assets/icons/map-filled.svg'), { ssr: false });
const MapOutlined = dynamic(() => import('src/assets/icons/map-outlined.svg'), { ssr: false });
const Messenger = dynamic(() => import('src/assets/icons/messenger.svg'), { ssr: false });
const MissedVideoCall = dynamic(() => import('src/assets/icons/missed-video-call.svg'), {
    ssr: false,
});
const Minus = dynamic(() => import('src/assets/icons/minus.svg'), { ssr: false });
const Network = dynamic(() => import('src/assets/icons/network.svg'), { ssr: false });
const NetworkFilled = dynamic(() => import('src/assets/icons/network-filled.svg'), { ssr: false });
const News = dynamic(() => import('src/assets/icons/news.svg'), { ssr: false });
const NewsIcon = dynamic(() => import('src/assets/icons/news-icon.svg'), { ssr: false });
const NoCamera = dynamic(() => import('src/assets/icons/no-camera.svg'), { ssr: false });
const NoCommonRelations = dynamic(() => import('src/assets/icons/no-common-relations.svg'), {
    ssr: false,
});
const NoFavorites = dynamic(() => import('src/assets/icons/no-favorites.svg'), { ssr: false });
const NoNotifications = dynamic(() => import('src/assets/icons/no-notifications.svg'), {
    ssr: false,
});
const NoSavedSearch = dynamic(() => import('src/assets/icons/no-saved-search.svg'), { ssr: false });
const NoteAdEmpty = dynamic(() => import('src/assets/icons/note-ad-empty.svg'), { ssr: false });
const NoteAd = dynamic(() => import('src/assets/icons/note-ad.svg'), { ssr: false });
const NotificationBlackInactive = dynamic(() => import('src/assets/icons/notification-black-inactive.svg'), {
    ssr: false,
});
const NotificationPrimaryActive = dynamic(() => import('src/assets/icons/notification-primary-active.svg'), {
    ssr: false,
});
const NotificationPrimaryInactive = dynamic(() => import('src/assets/icons/notification-primary-inactive.svg'), {
    ssr: false,
});
const NotificationWhiteActive = dynamic(() => import('src/assets/icons/notification-white-active.svg'), { ssr: false });
const NotificationWhiteInactive = dynamic(() => import('src/assets/icons/notification-white-inactive.svg'), {
    ssr: false,
});
const Office = dynamic(() => import('src/assets/icons/office.svg'), { ssr: false });
const OfficeEmptyState = dynamic(() => import('src/assets/icons/office-empty-state.svg'), {
    ssr: false,
});
const OfficeWithoutGradient = dynamic(() => import('src/assets/icons/office-without-gradient.svg'), { ssr: false });
const OrderBy = dynamic(() => import('src/assets/icons/order-by.svg'), { ssr: false });
const PaperClip = dynamic(() => import('src/assets/icons/paperclip.svg'), { ssr: false });
const Parameters = dynamic(() => import('src/assets/icons/parameters.svg'), { ssr: false });
const Parking = dynamic(() => import('src/assets/icons/parking.svg'), { ssr: false });
const SearchPerson = dynamic(() => import('src/assets/icons/person-search.svg'), { ssr: false });
const SearchPeople = dynamic(() => import('src/assets/icons/people-search.svg'), { ssr: false });
const PagesJaunes = dynamic(() => import('src/assets/icons/pages-jaunes.svg'), { ssr: false });
const PictureBlack = dynamic(() => import('src/assets/icons/picture-black.svg'), { ssr: false });
const PictureWhite = dynamic(() => import('src/assets/icons/picture-white.svg'), { ssr: false });
const PinHouse = dynamic(() => import('src/assets/icons/pin-house.svg'), { ssr: false });
const Play = dynamic(() => import('src/assets/icons/play.svg'), { ssr: false });
const PlusSign = dynamic(() => import('src/assets/icons/plus-sign.svg'), { ssr: false });
const PlusThin = dynamic(() => import('src/assets/icons/plus.svg'), { ssr: false });
const PointsOfInterest = dynamic(() => import('src/assets/icons/points-of-interest.svg'), {
    ssr: false,
});
const Policeman = dynamic(() => import('src/assets/icons/policeman.svg'), { ssr: false });
const Pool = dynamic(() => import('src/assets/icons/pool.svg'), { ssr: false });
const Prohibited = dynamic(() => import('src/assets/icons/prohibited.svg'), { ssr: false });
const PostIcon = dynamic(() => import('src/assets/icons/post-icon.svg'), { ssr: false });
const PostIconCircle = dynamic(() => import('src/assets/icons/post-icon-circle.svg'), {
    ssr: false,
});
const Projects = dynamic(() => import('src/assets/icons/projects.svg'), { ssr: false });
const ProjectIcon = dynamic(() => import('src/assets/icons/project-icon.svg'), { ssr: false });
const RadioEmpty = dynamic(() => import('src/assets/icons/radio-empty.svg'), { ssr: false });
const RadioSelected = dynamic(() => import('src/assets/icons/radio-selected.svg'), { ssr: false });
const Reaction = dynamic(() => import('src/assets/icons/reaction.svg'), { ssr: false });
const RealEstateBuilding = dynamic(() => import('src/assets/icons/realestate-building.svg'), {
    ssr: false,
});
const RealEstate = dynamic(() => import('src/assets/icons/real_estate.svg'), { ssr: false });
const RemoveGroup = dynamic(() => import('src/assets/icons/remove-group.svg'), { ssr: false });
const RemoveUser = dynamic(() => import('src/assets/icons/remove-user.svg'), { ssr: false });
const Rent = dynamic(() => import('src/assets/icons/rent.svg'), { ssr: false });
const Room = dynamic(() => import('src/assets/icons/room.svg'), { ssr: false });
const Rotate = dynamic(() => import('src/assets/icons/rotate.svg'), { ssr: false });
const Search = dynamic(() => import('src/assets/icons/search.svg'), { ssr: false });
const SearchRealEstate = dynamic(() => import('src/assets/icons/search-realestate.svg'), {
    ssr: false,
});
const SearchWhite = dynamic(() => import('src/assets/icons/search-white.svg'), { ssr: false });
const Sell = dynamic(() => import('src/assets/icons/sell.svg'), { ssr: false });
const Send = dynamic(() => import('src/assets/icons/send.svg'), { ssr: false });
const Services = dynamic(() => import('src/assets/icons/services.svg'), { ssr: false });
const Settings = dynamic(() => import('src/assets/icons/settings.svg'), { ssr: false });
const SettingCompany = dynamic(() => import('src/assets/icons/setting-company.svg'), {
    ssr: false,
});
const Share = dynamic(() => import('src/assets/icons/share.svg'), { ssr: false });
const Shed = dynamic(() => import('src/assets/icons/shed.svg'), { ssr: false });
const Show = dynamic(() => import('src/assets/icons/show.svg'), { ssr: false });
const ShowcaseProject = dynamic(() => import('src/assets/icons/showcase-project.svg'), {
    ssr: false,
});
const Showcase = dynamic(() => import('src/assets/icons/showcase.svg'), { ssr: false });
const Smile = dynamic(() => import('src/assets/icons/smile.svg'), { ssr: false });
const Sofa = dynamic(() => import('src/assets/icons/sofa.svg'), { ssr: false });
const Spa = dynamic(() => import('src/assets/icons/spa.svg'), { ssr: false });
const Sport = dynamic(() => import('src/assets/icons/sport.svg'), { ssr: false });
const StarOrange = dynamic(() => import('src/assets/icons/star-orange.svg'), { ssr: false });
const StarBlank = dynamic(() => import('src/assets/icons/star.svg'), { ssr: false });
const Statistic = dynamic(() => import('src/assets/icons/statistic.svg'), { ssr: false });
const StoriesRealEstateTriangle = dynamic(() => import('src/assets/icons/stories-real-estate-triangle.svg'), {
    ssr: false,
});
const Subtract = dynamic(() => import('src/assets/icons/subtract.svg'), { ssr: false });
const Success = dynamic(() => import('src/assets/icons/success.svg'), { ssr: false });
const SuccessShadow = dynamic(() => import('src/assets/icons/success-shadow.svg'), { ssr: false });
const Suitcase = dynamic(() => import('src/assets/icons/suitcase.svg'), { ssr: false });
const SyncInProgress = dynamic(() => import('src/assets/icons/sync-in-progress.svg'), {
    ssr: false,
});
const Tendancy = dynamic(() => import('src/assets/icons/tendancy.svg'), { ssr: false });
const Terrace = dynamic(() => import('src/assets/icons/terrace.svg'), { ssr: false });
const Text = dynamic(() => import('src/assets/icons/text.svg'), { ssr: false });
const Time = dynamic(() => import('src/assets/icons/time.svg'), { ssr: false });
const ThumbsUpOutlined = dynamic(() => import('src/assets/icons/thumbs-up-outlined.svg'), {
    ssr: false,
});
const ThumbsUpFilled = dynamic(() => import('src/assets/icons/thumbs-up-filled.svg'), {
    ssr: false,
});
const ThumbsUpCrossed = dynamic(() => import('src/assets/icons/thumbs-up-crossed.svg'), {
    ssr: false,
});
const Union = dynamic(() => import('src/assets/icons/union.svg'), { ssr: false });
const Upstairs = dynamic(() => import('src/assets/icons/upstairs.svg'), { ssr: false });
const User = dynamic(() => import('src/assets/icons/user.svg'), { ssr: false });
const UserGroup = dynamic(() => import('src/assets/icons/user-group.svg'), { ssr: false });
const Veranda = dynamic(() => import('src/assets/icons/veranda.svg'), { ssr: false });
const Video = dynamic(() => import('src/assets/icons/video.svg'), { ssr: false });
const VideoBlue = dynamic(() => import('src/assets/icons/video-blue.svg'), { ssr: false });
const View = dynamic(() => import('src/assets/icons/view.svg'), { ssr: false });
const Warning = dynamic(() => import('src/assets/icons/warning.svg'), { ssr: false });
const Whatsapp = dynamic(() => import('src/assets/icons/whatsapp.svg'), { ssr: false });
const Write = dynamic(() => import('src/assets/icons/write.svg'), { ssr: false });
const Yard = dynamic(() => import('src/assets/icons/yard.svg'), { ssr: false });

const Abriculteurs = dynamic(() => import('public/assets/images/melo/logo-melo-abriculteurs.svg'), {
    ssr: false,
});
const AVendreALouer = dynamic(() => import('public/assets/images/melo/logo-melo-avendre-alouer.svg'), {
    ssr: false,
});
const Axo = dynamic(() => import('public/assets/images/melo/logo-melo-axo.svg'), { ssr: false });
const BellesDemeures = dynamic(() => import('public/assets/images/melo/logo-melo-belles-demeures.svg'), {
    ssr: false,
});
const BienIci = dynamic(() => import('public/assets/images/melo/logo-melo-bien-ici.svg'), {
    ssr: false,
});
const Century21 = dynamic(() => import('public/assets/images/melo/logo-melo-century-21.svg'), {
    ssr: false,
});
const ConnexionImmobilier = dynamic(() => import('public/assets/images/melo/logo-melo-connexion-immobilier.svg'), {
    ssr: false,
});
const ConsultantsImmobilier = dynamic(() => import('public/assets/images/melo/logo-melo-consultants-immobilier.svg'), {
    ssr: false,
});
const DrHouseImmo = dynamic(() => import('public/assets/images/melo/logo-melo-dr-house-immo.svg'), {
    ssr: false,
});
const EraImmobilier = dynamic(() => import('public/assets/images/melo/logo-melo-era-immobilier.svg'), {
    ssr: false,
});
const EtreProprio = dynamic(() => import('public/assets/images/melo/logo-melo-etreproprio.svg'), {
    ssr: false,
});
const FigaroImmobilier = dynamic(() => import('public/assets/images/melo/logo-melo-figaro-immobilier.svg'), {
    ssr: false,
});
const Foncia = dynamic(() => import('public/assets/images/melo/logo-melo-foncia.svg'), {
    ssr: false,
});
const GensDeConfiance = dynamic(() => import('public/assets/images/melo/logo-melo-gens-de-confiance.svg'), {
    ssr: false,
});
const Hosman = dynamic(() => import('public/assets/images/melo/logo-melo-hosman.svg'), {
    ssr: false,
});
const IAD = dynamic(() => import('public/assets/images/melo/logo-melo-iad.svg'), { ssr: false });
const ImmoRegion = dynamic(() => import('public/assets/images/melo/logo-melo-immoregion.svg'), {
    ssr: false,
});
const KellerWilliamsFrance = dynamic(() => import('public/assets/images/melo/logo-melo-keller-williams-france.svg'), {
    ssr: false,
});
const LAdresseImmobilier = dynamic(() => import('public/assets/images/melo/logo-melo-l-adresse-immobilier.svg'), {
    ssr: false,
});
const LaForet = dynamic(() => import('public/assets/images/melo/logo-melo-laforet.svg'), {
    ssr: false,
});
const LeBonCoin = dynamic(() => import('public/assets/images/melo/logo-melo-leboncoin.svg'), {
    ssr: false,
});
const LogicImmo = dynamic(() => import('public/assets/images/melo/logo-melo-logic-immo.svg'), {
    ssr: false,
});
const LuxResidence = dynamic(() => import('public/assets/images/melo/logo-melo-lux-residence.svg'), {
    ssr: false,
});
const OrpiLogo = dynamic(() => import('public/assets/images/melo/logo-melo-orpi.svg'), {
    ssr: false,
});
const ParuVendu = dynamic(() => import('public/assets/images/melo/logo-melo-paruvendu.svg'), {
    ssr: false,
});
const Safti = dynamic(() => import('public/assets/images/melo/logo-melo-safti.svg'), {
    ssr: false,
});
const SeLoger = dynamic(() => import('public/assets/images/melo/logo-melo-seloger.svg'), {
    ssr: false,
});
const StephanePlazaImmobilier = dynamic(
    () => import('public/assets/images/melo/logo-melo-stephane-plaza-immobilier.svg'),
    { ssr: false },
);
const Superimmo = dynamic(() => import('public/assets/images/melo/logo-melo-superimmo.svg'), {
    ssr: false,
});
const Sotheby = dynamic(() => import('public/assets/images/melo/logo-melo-sothebys.svg'), {
    ssr: false,
});
const Pap = dynamic(() => import('public/assets/images/melo/logo-melo-pap.svg'), {
    ssr: false,
});
const GreenAcres = dynamic(() => import('public/assets/images/melo/logo-melo-green-acres.svg'), {
    ssr: false,
});
const ProprieteLeFigaro = dynamic(() => import('public/assets/images/melo/logo-melo-le-figaro-properties.svg'), {
    ssr: false,
});
const Licitor = dynamic(() => import('public/assets/images/melo/logo-melo-licitor.svg'), {
    ssr: false,
});

/**
 * Add Icons to this object to make them available
 */

export const icons = {
    Accessibility,
    AllCommunity,
    Activity,
    Add,
    AddPicture,
    AddUser,
    AddUserWhite,
    AirConditioner,
    Alarm,
    Alert,
    Area,
    ArrowBack,
    ArrowRight,
    Apple,
    Balcony,
    BedRoom,
    BigThumbsUp,
    Bookmark,
    BookmarkGrey,
    BookmarkPrimaryActive,
    BookmarkPrimaryInactive,
    BookmarkWhiteActive,
    BookmarkWhiteInactive,
    ButtonChevronRightCircle,
    ButtonChevronLeftCircle,
    Calculator,
    Call,
    CallWhite,
    CallCenter,
    Camera,
    Caret,
    Cave,
    Certification,
    Chat,
    ChatPrimaryActive,
    ChatPrimaryInactive,
    ChatWhiteActive,
    ChatWhiteInactive,
    CheckEmailIcon,
    CheckMarkBlack,
    CheckMarkBlue,
    CheckMarkCircleThin,
    CheckMarkWhite,
    CheckMarkGreenOnGrey,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronRightCircle,
    Close,
    CloseCircle,
    Collaborators,
    Comments,
    Company,
    CompanyIcon,
    CompanyAvatarEmpty,
    Conception,
    Connected,
    Crafts,
    CtaEstimate,
    CtaFindAPro,
    CtaPlaceAnAd,
    CtaSearch,
    Date,
    DecreasingPrice,
    Delete,
    Design,
    DisplayOn,
    DisplayOff,
    Distance,
    DotsHorizontalRounded,
    Draw,
    Dressing,
    Edit,
    Elevator,
    Ellipse,
    ExternalLink,
    Eye,
    Facebook,
    SavedSearches,
    File,
    FileWhite,
    FileBox,
    Filter,
    FiltersResponsive,
    Financial,
    Fireplace,
    FlagFrance,
    FlagUnitedKingdom,
    Followers,
    FollowersGradient,
    Following,
    FollowingGradient,
    ForSale,
    ForSaleDark,
    ForSaleEn,
    ForSaleOutlined,
    Garage,
    Garden,
    Globe,
    Google,
    GridFilled,
    GridOutlined,
    HeartEmpty,
    HeartFillGrey,
    HeartFillRed,
    Help,
    Home,
    AdIcon,
    HomeArea,
    HomeLocation,
    HouseGarden,
    HouseKey,
    HouseKeyOutlined,
    HouseSearchEmpty,
    Inactive,
    Influencer,
    Info,
    InfoCircle,
    InterestGuy,
    Insurance,
    Land,
    Legal,
    Like,
    Link,
    LinkIcon,
    Linkedin,
    ListOn,
    ListOff,
    Location,
    Lock,
    LockCircle,
    Loft,
    Logout,
    Mail,
    Map,
    MapFilled,
    MapOutlined,
    Messenger,
    MissedVideoCall,
    Minus,
    Network,
    NetworkFilled,
    News,
    NewsIcon,
    NoCamera,
    NoCommonRelations,
    NoFavorites,
    NoNotifications,
    NoSavedSearch,
    NoteAd,
    NoteAdEmpty,
    NotificationBlackInactive,
    NotificationPrimaryActive,
    NotificationPrimaryInactive,
    NotificationWhiteActive,
    NotificationWhiteInactive,
    Office,
    OfficeEmptyState,
    OfficeWithoutGradient,
    OrderBy,
    PagesJaunes,
    PaperClip,
    Parameters,
    Parking,
    PictureBlack,
    PictureWhite,
    PinHouse,
    Play,
    Plus,
    PlusSign,
    PlusThin,
    PointsOfInterest,
    Policeman,
    Pool,
    Prohibited,
    PostIcon,
    PostIconCircle,
    Projects,
    ProjectIcon,
    QuestionCircle,
    RadioEmpty,
    RadioSelected,
    Reaction,
    RealEstate,
    RealEstateBuilding,
    RemoveGroup,
    RemoveUser,
    Rent,
    Room,
    Rotate,
    Search,
    SearchPerson,
    SearchPeople,
    SearchRealEstate,
    SearchWhite,
    Send,
    Services,
    Settings,
    SettingCompany,
    Share,
    Shed,
    Show,
    ShowcaseProject,
    Showcase,
    Sell,
    Smile,
    Sofa,
    Spa,
    Sport,
    Star,
    StarBlank,
    StarOrange,
    Statistic,
    StoriesRealEstateTriangle,
    Subtract,
    Success,
    SuccessShadow,
    Suitcase,
    SyncInProgress,
    Tendancy,
    Terrace,
    Text,
    ThumbsUpFilled,
    ThumbsUpOutlined,
    ThumbsUpCrossed,
    Time,
    Union,
    Upstairs,
    User,
    UserGroup,
    Veranda,
    Video,
    VideoBlue,
    View,
    Visibility,
    VisibilityOff,
    Warning,
    Whatsapp,
    Write,
    Yard,
    Abriculteurs,
    AVendreALouer,
    Axo,
    BellesDemeures,
    BienIci,
    Century21,
    ConnexionImmobilier,
    ConsultantsImmobilier,
    DrHouseImmo,
    EraImmobilier,
    EtreProprio,
    FigaroImmobilier,
    Foncia,
    GensDeConfiance,
    Hosman,
    IAD,
    ImmoRegion,
    KellerWilliamsFrance,
    LAdresseImmobilier,
    LaForet,
    LeBonCoin,
    LogicImmo,
    LuxResidence,
    OrpiLogo,
    ParuVendu,
    Safti,
    SeLoger,
    StephanePlazaImmobilier,
    Superimmo,
    Sotheby,
    Pap,
    GreenAcres,
    ProprieteLeFigaro,
    Licitor,
};

export type Props = {
    color?: string;
    name: keyof typeof icons;
    onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
    size?: string;
    containerSize?: string;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
};
const Icon: React.FC<Props & React.SVGAttributes<SVGElement>> = ({
    color,
    name,
    onClick,
    size,
    containerSize,
    style,
    containerStyle,
    ...rest
}) => {
    const customStyle: CSSProperties = {
        color,
        width: size ?? '1.5rem',
        height: size ?? '1.5rem',
        cursor: onClick ? 'pointer' : undefined,
        ...style,
    };

    const defaultContainerStyle: CSSProperties = {
        display: 'flex',
        width: containerSize ?? size ?? '1.5rem',
        height: containerSize ?? size ?? '1.5rem',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const CustomIcon = icons[name];

    return (
        <span data-target-id={`icon-${name}`} style={{ ...defaultContainerStyle, ...containerStyle }}>
            {/* span is a little hack since dynamic import is mocked in jest, need to upgrade @testing-library/react to allow tests with dynamic imports  */}
            <CustomIcon onClick={onClick} color={color} style={customStyle} {...rest} />
        </span>
    );
};

export default Icon;
