import React, { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import addSeconds from 'date-fns/addSeconds';
import { useApolloClient } from '@apollo/client';

import { auth as firebaseAuth } from 'src/utils/firebase';
import { removeAuthCookie, setAuthCookie } from 'src/utils/authCookie';
import { authCookieExpirationDate } from 'src/constants/signup';
import BaseSEO from 'src/components/other/BaseSEO';

export interface AuthData {
    token: string | null;
}
export interface IAuthContext {
    auth: AuthData | undefined;
    setAuth: (auth: AuthData | undefined, tokenDuration: number) => void;
    removeAuth: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

type AuthContextProviderParams = SEOProps & { children: React.ReactNode };

export const AuthContextProvider = ({ children, ...SEOProps }: AuthContextProviderParams) => {
    const [auth, setAuthState] = useState<AuthData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const client = useApolloClient();

    const setAuth = (authData: AuthData | undefined, tokenDuration?: number) => {
        if (authData?.token) {
            let expirationDate;
            if (!tokenDuration) {
                // if not specified, we use the one in local storage
                const localStorageExpirationDate = localStorage.getItem(authCookieExpirationDate);
                if (localStorageExpirationDate) {
                    expirationDate = new Date(localStorageExpirationDate);
                }
            } else {
                // tokenDuration is specified on signIn / signUp
                expirationDate = addSeconds(new Date(), tokenDuration);
                localStorage.setItem(authCookieExpirationDate, expirationDate.toISOString());
            }
            setAuthState({ token: authData.token });
            setAuthCookie({ token: authData.token }, expirationDate); // to get the last token in getInitialProps before the render (in _app)
        }
    };

    const removeAuth = useCallback(async () => {
        setAuthState(undefined);
        localStorage.removeItem(authCookieExpirationDate);
        removeAuthCookie();
        client.stop();
        await client.resetStore();
    }, [client]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- needed
        const subscriber = firebaseAuth.onIdTokenChanged(async (firebaseUser) => {
            // this function is triggered each time the refresh token is updated by firebase
            if (process.env.NODE_ENV !== 'test') {
                if (!firebaseUser) {
                    await removeAuth();
                } else {
                    const idToken = await firebaseUser.getIdToken();
                    setAuth({ token: idToken });
                }
            }
            setLoading(false);
        });
        return () => subscriber();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value: IAuthContext = useMemo(
        () => ({
            auth,
            setAuth,
            removeAuth,
        }),
        [auth, removeAuth],
    );

    return (
        <AuthContext.Provider value={value}>
            <>
                <BaseSEO {...SEOProps} />
                {loading && process.env.NODE_ENV !== 'test' ? null : children}
            </>
        </AuthContext.Provider>
    );
};
