import { DialogContent } from '@mui/material';
import React from 'react';
import dynamic from 'next/dynamic';

import Box from '@mui/material/Box';

import { useTranslation } from 'next-i18next';

import Dialog from 'src/components/ui/Dialog';
import DialogTitle from 'src/components/ui/DialogTitle';
import Typography from 'src/components/ui/Typography';
import Button from 'src/components/ui/Button';
import Icon from 'src/components/ui/Icon';

import useAppRouter from 'src/hooks/useAppRouter';
import { routePathProfileInterests } from 'src/constants/router';
import { styledComponent } from 'src/utils/styled';

const WoopenLogo = dynamic(() => import('src/assets/logos/vertical-woopen-logo.svg'), {
    ssr: false,
});

const StyledWoopenLogo = styledComponent(WoopenLogo)`
    width: 12rem;
    height: 9rem;
    margin-bottom: 2rem;
`;

const StyledDialog = styledComponent(Dialog)`
    .MuiDialogContent-root {
        padding: 0 1.5rem;
        padding-bottom: 2.5rem;
    }
    .MuiDialogActions-root {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .MuiDialogTitle-root {
        padding: 1.5rem 1.5rem 0 1.5rem;
        display: flex;
        flex-direction: column;
    }

    text-align: center;
`;

StyledDialog.defaultProps = {
    maxWidth: 'xs',
    fullWidth: true,
};

interface Props {}

const InterestModal: React.FC<Props> = () => {
    const { t } = useTranslation(['common']);
    const router = useAppRouter();
    return (
        <StyledDialog open={Boolean(router.query.interestsModal)}>
            <DialogTitle>
                <StyledWoopenLogo />
                <Typography variant="h2">{t('common:interest.myPointsOfInterest')}</Typography>
            </DialogTitle>

            <DialogContent>
                <Box mt={3}>
                    <Typography dangerouslySetInnerHTML={{ __html: t('common:interest.description') }} />
                </Box>
                <Icon name="InterestGuy" size="10rem" />
                <Box>
                    <Button onClick={() => router.push(routePathProfileInterests)}>
                        {t('common:interest.letsGo')}
                    </Button>
                </Box>
            </DialogContent>
        </StyledDialog>
    );
};

export default InterestModal;
