import React from 'react';
import dynamic from 'next/dynamic';

import Box from '@mui/material/Box';

import { useTranslation } from 'next-i18next';

import Icon from 'src/components/ui/Icon';
import Button from 'src/components/ui/Button';
import type { Props as ButtonProps } from 'src/components/ui/Button';
import Typography from 'src/components/ui/Typography';

import { colours } from 'src/constants/colours';
import useAppRouter from 'src/hooks/useAppRouter';
import useLoginModal from 'src/hooks/useLoginModal';
import { breakpoints } from 'src/constants/breakpoints';
import { routePathSelectProfileType } from 'src/constants/router';
import { styledComponent, styledTag } from 'src/utils/styled';

const VerticalWoopenLogo = dynamic(() => import('src/assets/logos/vertical-woopen-logo.svg'), {
    ssr: false,
});

const StyledTypographyTitle = styledComponent(Typography)`
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        width: 14rem;
        text-align: center;
        font-size: 1.25rem;
    }
`;

const StyledSubtitle = styledComponent(Typography)`
    color: ${colours.primary.O72};
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        text-align: center;
        font-size: 1rem;
    }
`;

const StyledButton = styledComponent((props: ButtonProps) => <Button {...props} />)`
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        width: 20.5rem;
        margin-top: 0.5rem;
    }
`;

const BulletContainer = styledTag('div')`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
`;

const BottomActions = styledTag('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    > *:not(:last-child) {
        margin-right: 1rem;
    }
    margin-top: 1rem;
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > *:not(:last-child) {
            margin-right: 0rem;
        }
    }
`;

const StyledBox = styledComponent(Box)`
    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        width: 22rem;
    }

    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        width: 17rem;
    }
`;

interface Props {
    isPJClaimPrompt?: boolean;
}

const JoinWoopenPrompt: React.FC<Props> = ({ isPJClaimPrompt }) => {
    const { t } = useTranslation(['news', 'common']);
    const { setSelectedView } = useLoginModal();
    const router = useAppRouter();

    const handleLogin = () => {
        setSelectedView('login');
    };

    const handleSignUp = async () => {
        await router.push(routePathSelectProfileType);
    };

    const localePrefix = isPJClaimPrompt ? 'pagesJaunesClaimLoginModal' : 'loginModal';
    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center">
                <VerticalWoopenLogo width="9.25rem" height="6.94rem" />
                <Box my={-1} mb={3}>
                    <StyledTypographyTitle data-target-id="loginModal-title" variant="h3">
                        {t(`common:${localePrefix}.title`)}
                    </StyledTypographyTitle>
                </Box>
                <StyledSubtitle data-target-id="loginModal-subtitle" weight="bold">
                    {t(`common:${localePrefix}.subtitle`)}
                </StyledSubtitle>
                <StyledBox textAlign="left">
                    <BulletContainer>
                        <Icon name="CheckMarkBlack" />
                        <Typography data-target-id="loginModal-bullet1" variant="body2">
                            {t(`common:${localePrefix}.bullet1`)}
                        </Typography>
                    </BulletContainer>
                    <BulletContainer>
                        <Icon name="CheckMarkBlack" />
                        <Typography data-target-id="loginModal-bullet2" variant="body2">
                            {t(`common:${localePrefix}.bullet2`)}
                        </Typography>
                    </BulletContainer>
                    <BulletContainer>
                        <Icon name="CheckMarkBlack" />
                        <Typography data-target-id="loginModal-bullet3" variant="body2">
                            {t(`common:${localePrefix}.bullet3`)}
                        </Typography>
                    </BulletContainer>
                </StyledBox>
            </Box>
            <BottomActions>
                <StyledButton data-target-id="loginModal-button-login" variant="outlined" onClick={handleLogin}>
                    {t('common:header.login')}
                </StyledButton>
                <StyledButton data-target-id="loginModal-button-signUp" color="primary" onClick={handleSignUp}>
                    {t('common:header.signUp')}
                </StyledButton>
            </BottomActions>
        </>
    );
};

export default JoinWoopenPrompt;
