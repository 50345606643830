import axios from 'axios';

export interface IpAddressInfo {
    ipVersion: number;
    ipAddress: string;
    latitude: number;
    longitude: number;
    countryName: string;
    countryCode: string;
    timeZone: string;
    zipCode: string;
    cityName: string;
    regionName: string;
    isProxy: boolean;
    continent: string;
    continentCode: string;
}

export const getCurrentUserIpAddressInfo = async (): Promise<IpAddressInfo | undefined> => {
    const url = `https://freeipapi.com/api/json`;
    return axios
        .get(url)
        .then((response) => (response.data ? (response.data as IpAddressInfo) : undefined))
        .catch((error) => {
            console.error('Error while fetching IP address from FreeIpApi', error);
            return undefined;
        });
};
