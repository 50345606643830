import { useContext } from 'react';

import { AlertsContext } from 'src/contexts/AlertsProvider';

const useAlerts = () => {
    const { alerts, addAlert } = useContext(AlertsContext);
    return { alerts, addAlert };
};

export default useAlerts;
