import { audioExts, videoMimes } from 'src/constants/fileuploads';
import { MediumType } from 'src/types/__generated__/graphql';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

const getMediaInfo = (filename: string): MediaInfo => {
    const ext = String(filename.split('.').pop());
    let type: MediaTypes;

    if (audioExts.find((audioExt) => audioExt === ext)) type = MediumType.Audio;
    else if (videoMimes.find((mime) => mime.includes(ext))) type = MediumType.Video;
    else type = 'image';

    const mime = `${type}/${ext}`;
    return {
        ext,
        mime,
        type,
    };
};

export default getMediaInfo;
