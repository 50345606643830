/* eslint-disable no-console */
import { useVersionsSupportedQuery } from 'src/hooks/__generated__/queries';
import getCurrentVersion from 'src/utils/version';
import { disableErrorIfJest } from 'src/utils/disableErrorIfJest';

export default function useIsCurrentVersionSupported(): boolean | undefined {
    const { data, error } = useVersionsSupportedQuery({
        fetchPolicy: 'network-only',
    });
    const currentVersion = getCurrentVersion();

    if (!data || !data.versionsSupported || error) {
        if (error) {
            if (error.message !== 'fetch is not defined') {
                console.error(error.message);
            } else {
                disableErrorIfJest(error);
            }
        }
        return undefined;
    }

    const isSupported = data.versionsSupported.length > 0 && data.versionsSupported.includes(currentVersion);

    return isSupported;
}
