import type React from 'react';

import useSignupRedirections from 'src/hooks/useSignupRedirections';

const GlobalHooks: React.FC = () => {
    useSignupRedirections();

    return null;
};

export default GlobalHooks;
