export const realEstatePros = [
    'agent',
    'real_estate_consultant', // what ????,
    'agent_madatory',
    'negociator',
    'commercial_agent',
    'developer',
    'wealth_manager',
    'notary',
    'hunter',
];

export const tokensDuration = {
    short: 60 * 60 * 24, // 24h in seconds
    long: 60 * 60 * 24 * 30.4167 * 3, // 3 months in seconds
};

export const authCookieExpirationDate = 'authCookieExpirationDate';

export enum ProfileTypes {
    individual = 'individual',
    professional = 'professional',
}
