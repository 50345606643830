import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import React from 'react';

import { useManagedRouter } from 'src/hooks/useAppRouter';

export type LinkProps = NextLinkProps &
    Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'href' | 'ref'> & {
        children?: React.ReactElement | string | object | Array<Array<React.ReactElement | string | object>>;
        legacyLinkColor?: boolean;
        openInNewTab?: boolean;
    };

/**
 * Wrap NextJS's link.
 * Adds "aria-current" attribute for adding active styles
 * Supports styled-components unlike the native next/link
 */

const Link: React.FC<LinkProps> = ({ children, href, as, style, legacyLinkColor, openInNewTab, ...props }) => {
    const { router } = useManagedRouter();
    const path = router.isReady ? router.asPath : '';
    const active = path === href || path === as;

    const linkStyle = {
        ...(legacyLinkColor && { color: '-webkit-link' }),
        ...style,
    };

    return (
        <NextLink
            href={href}
            as={as}
            style={linkStyle}
            target={openInNewTab ? '_blank' : undefined}
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
            passHref
            {...props}
            aria-current={active ? 'page' : undefined}
        >
            {children}
        </NextLink>
    );
};

export default Link;
