import packageJSON from 'package.json';

const getCurrentVersion = (): string => {
    let version = packageJSON.version;

    if (!version) {
        version = '?.?.?';
    }

    return version;
};

export default getCurrentVersion;
