import React, { useState } from 'react';

import type { Props as TextFieldProps } from 'src/components/ui/TextField';
import TextField from 'src/components/ui/TextField';

import Icon from 'src/components/ui/Icon';

import { colours } from 'src/constants/colours';

interface Props extends TextFieldProps {}

const PasswordInput: React.FC<Props> = (props) => {
    const [formType, setFormType] = useState<string>('password');
    return (
        <TextField
            {...props}
            type={formType}
            InputProps={{
                endAdornment: (
                    <Icon
                        onClick={() => (formType === 'password' ? setFormType('text') : setFormType('password'))}
                        name={formType === 'password' ? 'Visibility' : 'VisibilityOff'}
                        color={colours.primary.O72}
                    />
                ),
                ...props.InputProps,
            }}
        />
    );
};

export default PasswordInput;
