import React from 'react';

import type { CircularProgressProps } from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';

import { styledTag } from 'src/utils/styled';

const StyledLoader = styledTag('div')<{
    $padding: string | 0;
    $absolute: true | undefined;
}>`
    ${({ $padding }) =>
        $padding !== 0 &&
        `
        width: 100%;
        height: 100%;
    `};
    ${({ $absolute }) =>
        $absolute &&
        `
        position: absolute;
         top: 50%;
         left: 50%;
         transform: translate3d(-50%, -50%, 0);
    `};

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${({ $padding }) => $padding !== 0 && $padding};
    .MuiCircularProgress-circle {
        stroke-linecap: round;
    }
`;

export interface Props extends CircularProgressProps {
    padding?: string | 0; // 0 is used principally for endArdonament in inputs
    absolute?: true;
}

const Loader: React.FC<Props> = ({ absolute, padding = '2rem', color = 'primary', thickness = 4, ...rest }) => (
    <StyledLoader $absolute={absolute} $padding={padding}>
        <CircularProgress {...rest} color={color} thickness={thickness} />
    </StyledLoader>
);

export default Loader;
