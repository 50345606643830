import React, { createContext } from 'react';
import type { Router } from 'next/router';
import type { NextComponentType, NextPageContext } from 'next';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, CssBaseline } from '@mui/material';

import AlertsProvider from 'src/contexts/AlertsProvider';
import UniversalApolloProvider from 'src/contexts/UniversalApolloProvider';
import GlobalHooks from 'src/components/other/GlobalHooks';
import InterestModal from 'src/components/other/InterestModal';
import ConnectionEvent from 'src/components/other/ConnectionEvent';
import GlobalModals from 'src/components/other/GlobalModals';

import theme from 'src/utils/theme';
import UpgradeModal from 'src/components/other/UpgradeModal';
import type { RouteHistoryType } from 'src/hooks/useRouteHistory';
import useRouteHistory from 'src/hooks/useRouteHistory';
import EventsProvider from 'src/contexts/EventsProvider';
import createEmotionCache from 'src/utils/createEmotionCache';
import RemoteConfigProvider from 'src/contexts/RemoteConfigProvider';
import { AuthContextProvider } from 'src/contexts/AuthProvider';

export const AppRouterContext = createContext({} as Router);
export const PreviousRouteContext = createContext<RouteHistoryType>({
    previousRoute: null,
    previousQuery: null,
    previousPathname: null,
    history: [],
    canGoBack: false,
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface Props {
    router: Router;
    pageProps: PageProps;
    Component: NextComponentType<NextPageContext, unknown, unknown> & { Layout?: React.FC };
    initialApolloState?: NormalizedCacheObject;
    ssrApolloClient?: ApolloClient<NormalizedCacheObject>;
    emotionCache?: EmotionCache;
    mockApolloProvider?: boolean;
}

/**
 * Universal App (getDataFromTree, SSR and Client-side)
 */
const App: React.FC<Props> = ({
    router,
    pageProps,
    Component,
    initialApolloState,
    ssrApolloClient,
    emotionCache = clientSideEmotionCache,
    mockApolloProvider,
}) => {
    const Layout = Component.Layout ? Component.Layout : React.Fragment;
    const previousRoute = useRouteHistory(router);
    const isPathForMeloImage = router.pathname === '/melo-long-image' || router.pathname === '/melo-short-image';
    return (
        <AppRouterContext.Provider value={router}>
            <PreviousRouteContext.Provider value={previousRoute}>
                <CacheProvider value={emotionCache}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <AlertsProvider>
                            <UniversalApolloProvider
                                mock={mockApolloProvider}
                                initialApolloState={initialApolloState}
                                ssrApolloClient={ssrApolloClient}
                            >
                                <AuthContextProvider {...pageProps.SEOProps}>
                                    <ConnectionEvent>
                                        <EventsProvider>
                                            <RemoteConfigProvider>
                                                    <GlobalModals>
                                                        <Layout>
                                                            <Component
                                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                {...(pageProps as any)}
                                                            />
                                                        </Layout>
                                                        <GlobalHooks />
                                                        {isPathForMeloImage ? null : (
                                                            <>
                                                                <InterestModal />
                                                                <UpgradeModal />
                                                            </>
                                                        )}
                                                    </GlobalModals>
                                            </RemoteConfigProvider>
                                        </EventsProvider>
                                    </ConnectionEvent>
                                </AuthContextProvider>
                            </UniversalApolloProvider>
                        </AlertsProvider>
                    </ThemeProvider>
                </CacheProvider>
            </PreviousRouteContext.Provider>
        </AppRouterContext.Provider>
    );
};

export default App;
