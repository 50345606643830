import React from 'react';

import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert, { type AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

import Icon, { type icons } from 'src/components/ui/Icon';

import { styledComponent } from 'src/utils/styled';
import { colours } from 'src/constants/colours';

const StyledAlert = styledComponent(Alert)`
    &.MuiAlert-filledSuccess {
        background-color: ${colours.greenSuccess};
        color: ${colours.white.main};
        font-weight: bold;
        align-items: center;
    }
    &.MuiAlert-filledError {
        background-color: ${colours.redError};
        color: ${colours.white.main};
        font-weight: bold;
    }
    &.MuiAlert-filledWarning {
        color: ${colours.white.main};
        font-weight: bold;
    }
    &.MuiAlert-filledInfo {
        background-color: ${colours.lightGray};
        color: ${colours.black.main};
        font-weight: bold;
    }
`;

type Props = {
    uid: string;
    onClose: (uid: string) => (event: unknown, reason?: SnackbarCloseReason) => void;
    autoHideDuration: number;
    open: boolean;
    iconName?: keyof typeof icons;
    iconSize?: string;
    severity?: AlertColor;
    title?: string;
    message?: string;
};

const AlertSnackBar: React.FC<Props> = ({
    uid,
    onClose,
    autoHideDuration,
    open,
    iconName,
    iconSize,
    severity,
    title,
    message,
}) => (
    <Snackbar
        key={uid}
        data-target="alert-element"
        data-target-id={`alert-element-${uid}`}
        onClose={onClose(uid)}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        autoHideDuration={autoHideDuration}
        open={open}
        ContentProps={{
            color: 'purple',
        }}
    >
        <StyledAlert
            iconMapping={{
                info: <Icon name={iconName ?? 'CheckMarkBlack'} color={colours.white.main} />,
                success: <Icon size={iconSize} name={iconName ?? 'CheckMarkWhite'} color={colours.white.main} />,
                error: <Icon name={iconName ?? 'Warning'} color={colours.white.main} />,
                warning: <Icon name={iconName ?? 'Warning'} color={colours.white.main} />,
            }}
            severity={severity}
            variant="filled"
            onClose={onClose(uid)}
        >
            {title && <AlertTitle>{title}</AlertTitle>}

            {message && (
                <Box>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                </Box>
            )}
        </StyledAlert>
    </Snackbar>
);

export default AlertSnackBar;
